import React, { useState } from 'react';
import { View } from 'react-native';
import { Container, Header, Item, Input, Text, Button } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AppHeader from '../AppHeader';
import { useNavigation } from '@react-navigation/native';
import { useStore } from '../../store/index';
import UserListForNewUsers from '../UserListForNewUsers';
import * as mutations from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import SearchList from '../SearchList';

const AddNewUsersToGroup = () => {
	const [searched, setSearched] = useState(false);
	const [searchText, setSearchText] = useState('');
	const navigation = useNavigation();
	const currentConversationGroup = useStore(
		(state) => state.currentConversationGroup
	);
	const setCurrentConversationGroup = useStore(
		(state) => state.setCurrentConversationGroup
	);
	const createGroupSelectedUsers = useStore(
		(state) => state.createGroupSelectedUsers
	);
	const getChatGroupQuery = `query listChatGroups {
	  listChatGroups(filter: {id: {eq: "${currentConversationGroup.id}"}}) {
    items {
      group_title
      id
      created_by
      vip_user_id
      chat_group_users {
        items {
          user {
            id
            first_name
            last_name
            imageUri
          }
        }
      }
    }
  }
    }`;

	const createNewGroupMember = async () => {
		for (const user of createGroupSelectedUsers) {
			try {
				await API.graphql({
					query: mutations.createChatGroupUser,
					variables: {
						input: {
							chat_group_id: currentConversationGroup.id,
							user_id: user.id,
						},
					},
				}).then((res) => {
					try {
						API.graphql(graphqlOperation(getChatGroupQuery)).then((res) => {
							setCurrentConversationGroup({
								...res.data.listChatGroups.items[0],
							});
						});
					} catch (error) {
						console.log(
							'Error inside adding new members file while calling api',
							error
						);
					}
					navigation.reset({
						index: 0,
						routes: [{ name: 'newConversation' }],
					});
					// console.log('chat user Saved...', res);
				});
			} catch (err) {
				console.log('error creating chat user:', err);
			}
		}
	};
	return (
		<Container>
			<AppHeader />
			<View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
				<Button
					transparent
					light
					onPress={() => {
						navigation.goBack();
					}}>
					<Text>Cancel</Text>
				</Button>
				<Button
					transparent
					dark
					style={{ backgroundColor: 'transparent' }}
					disabled={createGroupSelectedUsers.length ? false : true}
					onPress={createNewGroupMember}>
					<Text>Done</Text>
				</Button>
			</View>
			<Header
				searchBar
				androidStatusBarColor={'#fff'}
				style={{ backgroundColor: '#fff', borderBottomWidth: 0 }}>
				<Item
					style={{
						borderRadius: 10,
						padding: 5,
						height: 40,
					}}>
					<MaterialCommunityIcons name='magnify' size={25} color='#8e8e93' />
					<Input
						placeholder='Search/Invite Via email'
						onChangeText={(text) => setSearchText(text)}
					/>
				</Item>
			</Header>
			<UserListForNewUsers
				group={currentConversationGroup}
				searchText={searchText}
			/>
		</Container>
	);
};

export default AddNewUsersToGroup;
