import React from 'react';
import { View, Text } from 'react-native';
import { useStore } from '../../store/index';
import GroupContainerNew from '../GroupCircleContainerNew';

const GroupAvatar = () => {
	const currentUserId = useStore((state) => state.currentUserId);
	const currentConversationGroup = useStore(
		(state) => state.currentConversationGroup
	);

	return <GroupContainerNew group={currentConversationGroup} />;
};

export default GroupAvatar;
