import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import {
	Container,
	Content,
	Form,
	Item,
	Input,
	Label,
	Picker,
	Icon,
	Button,
	Text,
} from 'native-base';

import SubHeader from '../SubHeader';
import containerStyles from './containerStyle';
import AccountSettingsHeader from './AccountSettingsHeader';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { useStore } from '../../store';
import { useNavigation } from '@react-navigation/native';
const PhoneNumber = () => {
	const [selected, setSelected] = useState('0');
	const [phoneNumber, setPhoneNumber] = useState(null);
	const navigation = useNavigation();
	const currentUser = useStore((state) => state.currentUser);
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const currentUserId = useStore((state) => state.currentUserId);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (currentUser.phone_number) {
			setSelected(currentUser.phone_number.split(/[ ]+/)[0]);
			setPhoneNumber(currentUser.phone_number.split(/[ ]+/)[1]);

			// setSelected(currentUser.phone_number.slice(0, -10));
			// setPhoneNumber(currentUser.phone_number.slice(-10));
		}
	}, []);

	const updatePhoneNumber = async () => {
		console.log(typeof phoneNumber);
		if (
			(phoneNumber.length === 10 &&
				typeof parseInt(phoneNumber, 10) === 'number') ||
			phoneNumber.length === 0
		) {
			setCurrentUser(
				{ ...currentUser },
				(currentUser.phone_number = phoneNumber
					? `${selected} ${phoneNumber}`
					: null)
			);
			try {
				let res = await API.graphql({
					query: mutations.updateUser,
					variables: {
						input: {
							id: currentUserId,
							phone_number: phoneNumber ? `${selected} ${phoneNumber}` : null,
						},
					},
				});
				setCurrentUser(
					{ ...currentUser },
					(currentUser.phone_number = phoneNumber
						? `${selected} ${phoneNumber}`
						: null)
				);

				navigation.navigate('AccountScreen');
			} catch (error) {
				console.log('Could not update phone number..', error);
			}
		} else {
			setError(true);
		}
	};

	return (
		<>
			<AccountSettingsHeader screenName={'Phone Number'} />
			<Container style={containerStyles.container}>
				<SubHeader text={'Enter Phone Number'} />
				<Text style={containerStyles.text}>
				Select your country code and type your phone number in. Your Circle members will not be able to see your phone number.
				</Text>
				{error && (
					<Text style={{ color: '#ff4040' }}>
						Please enter a proper phone number
					</Text>
				)}
				<View style={{ flexDirection: 'row', borderBottomWidth: 1 }}>
					<Item picker>
						<Picker
							mode='dropdown'
							iosIcon={<Icon name='arrow-down' />}
							selectedValue={selected}
							style={{ width: 90 }}
							onValueChange={(itemValue, itemIndex) => setSelected(itemValue)}>
							<Picker.Item label='Country Code' value='0' />
							<Picker.Item label='US/CA +1' value='+1' />
							<Picker.Item label='IN +91' value='+91' />
							<Picker.Item label='MX +52' value='+52' />
						</Picker>
					</Item>
					<View style={{ flex: 1, marginLeft: 35 }}>
						<Item floatingLabel>
							<Input
								placeHolder='Enter your Phone Number'
								keyboardType='phone-pad'
								value={phoneNumber ? phoneNumber : ''}
								onChangeText={(text) => {
									setPhoneNumber(text);
									setError(false);
								}}
								maxLength={10}
							/>
						</Item>
					</View>
				</View>
				<Button
					rounded
					dark
					style={{ marginVertical: 20, alignSelf: 'center' }}
					onPress={() => updatePhoneNumber()}>
					<Text style={{ fontFamily: 'OPEN-SANS-SEMIBOLD' }}>Save</Text>
				</Button>
			</Container>
		</>
	);
};

export default PhoneNumber;
