import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Header, Left, Body, Right, Title } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';

const AccountSettingsHeader = ({ screenName }) => {
	const navigation = useNavigation();
	return (
		<Header androidStatusBarColor='#fff' style={styles.header}>
			<Left>
				<TouchableOpacity
					style={{
						height: 40,
						width: 40,
						justifyContent: 'center',
						alignItems: 'center',
					}}
					onPress={() => {
						navigation.goBack();
					}}>
					<FontAwesome5 name='chevron-left' size={30} />
				</TouchableOpacity>
			</Left>
			<Body style={{ flex: 4 }}>
				<Title style={styles.body}>{screenName}</Title>
			</Body>
			<Right></Right>
		</Header>
	);
};

const styles = StyleSheet.create({
	header: { backgroundColor: '#fff' },
	body: { color: '#000', alignSelf: 'center' },
});
export default AccountSettingsHeader;
