import React from 'react';
import { View, StyleSheet } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import SubHeader from './SubHeader';

export default function CirclesHeader() {
	return (
		<View style={styles.circlesHeading}>
			<MaterialCommunityIcons
				name="record-circle"
				size={25}
				style={{ marginRight: 5 }}
			/>
			<SubHeader text={'My Circles'} />
		</View>
	);
};

const styles = StyleSheet.create({
	circlesHeading: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		// marginVertical: 10,
	},
});
