import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { Container } from 'native-base';
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';
import containerStyles from '../AccountSettingComponents/containerStyle';
import { useNavigation } from '@react-navigation/native';

const CreateCustomResponse = () => {
	const navigation = useNavigation();
	return (
		<TouchableOpacity
			onPress={() => {
				navigation.navigate('customMessage');
			}}>
			<View style={styles.itemView}>
				<MaterialCommunityIcons
					name={'rename-box'}
					size={25}
					style={{ alignSelf: 'center', marginRight: 10 }}
				/>
				<View style={styles.itemNameAndIconView}>
					<Text style={[containerStyles.text, { alignSelf: 'center' }]}>
						{'Create Custom Response'}
					</Text>
					<FontAwesome5
						name='chevron-right'
						size={20}
						style={{ alignSelf: 'center' }}
					/>
				</View>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	itemView: {
		display: 'flex',
		flexDirection: 'row',
		paddingVertical: 5,
	},
	itemNameAndIconView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomWidth: 1,
		borderBottomColor: '#D7D7D8',
		paddingVertical: 10,
	},
});

export default CreateCustomResponse;
