const cannedMessages = {
  MessageArray: [
    'Good Morning',
    'How are you doing?',
    'Good Evening',
    'Just checking in!',
  ],
  VipMessageArray: [
    'I am very good, thank you!',
    'I am good, thank you.',
    'I am ok, but would love to talk.',
    'I feel bad, please call me.',
  ],
};

export default cannedMessages;
