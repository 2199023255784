import React, { useState, useEffect } from 'react';
import { TouchableWithoutFeedback, Keyboard, Pressable } from 'react-native';
import { Button, Container, Form, Item, Input, Text, View, Label } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import containerStyles from '../AccountSettingComponents/containerStyle';
import SubHeader from '../SubHeader';
import { useStore } from '../../store';

const CustomMessage = () => {
  const navigation = useNavigation();
  const autoMessage = useStore((state) => state.autoMessage);
  const setAutoMessage = useStore((state) => state.setAutoMessage);
  const customAutoMessageBool = useStore(
    (state) => state.customAutoMessageBool
  );
  const setCustomAutoMessageBool = useStore(
    (state) => state.setCustomAutoMessageBool
  );
  const customAutoMessageText = useStore(
    (state) => state.customAutoMessageText
  );
  const setCustomAutoMessageText = useStore(
    (state) => state.setCustomAutoMessageText
  );
  const customAutoMessageTextEdited = useStore(
    (state) => state.customAutoMessageTextEdited
  );
  const setCustomAutoMessageTextEdited = useStore(
    (state) => state.setCustomAutoMessageTextEdited
  );
  const [customMessage, setCustomMessage] = useState(
    !customAutoMessageTextEdited ? customAutoMessageText : autoMessage
  );
  const [newCustomMessage, setNewCustomMessage] = useState('');
  // 	setAutoMessage("How are you doing today?");

  useEffect(() => {
    newCustomMessage == ''
      ? setCustomAutoMessageBool(false)
      : setCustomAutoMessageBool(true);
  }, [newCustomMessage]);
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <Container style={containerStyles.container}>
        <SubHeader text={'Custom Message'} />
        <Form>
          <Item style={{ borderBottomColor: '#1e1e1e' }}>
            <Input
              placeholder="Custom Response goes here."
              value={customMessage}
              style={{ fontFamily: 'CRIMSON-TEXT' }}
              maxLength={250}
              onChangeText={(text) => (
                setCustomMessage(text),
                setNewCustomMessage(text),
                setCustomAutoMessageTextEdited(true)
              )}
            //   onBlur={() => setAutoMessage(customMessage)}
            ></Input>
          </Item>
          <View
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              fontFamily: 'CRIMSON-TEXT',
            }}
          >
            <Text style={{ fontFamily: 'CRIMSON-TEXT' }}>
              {customMessage?.length || 0}/250
            </Text>
            <Text style={{ fontFamily: 'CRIMSON-TEXT' }}>Character Limit</Text>
          </View>
        </Form>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 30,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pressable
            style={{ marginHorizontal: 10 }}
            onPress={() => {
              navigation.navigate('schudleAndAutoMessages');
            }}
          >
            <Text>Cancel</Text>
          </Pressable>
          <Button
            rounded
            dark
            style={{ marginHorizontal: 10 }}
            onPress={() => setAutoMessage(customMessage)}
          >
            <Text style={[{ fontFamily: 'OPEN-SANS-BOLD', color: '#fff' }]}>
              Save
            </Text>
          </Button>
        </View>
      </Container>
    </TouchableWithoutFeedback>
  );
};

export default CustomMessage;
