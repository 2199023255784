import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Platform,
  Dimensions,
  Modal,
  ScrollView,
  BackHandler,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import Amplify, { Auth, API, graphqlOperation, Hub } from 'aws-amplify';
import { Authenticator, withOAuth, SignIn } from 'aws-amplify-react-native';
import { Dialog, Portal, Paragraph } from 'react-native-paper';
import { Item, Input, Text, Button, Container } from 'native-base';
import { MaterialCommunityIcons, Ionicons, Entypo } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { updateUser } from '../../../graphql/mutations';

import { useStore } from '../../store/index';
import Logo from '../../components/Logo';
import HeaderText from '../../components/LoginAndSignupHeader';
import awsmobile from '../../../aws-exports';
import styles from './Styles';
import { getUser } from '../../../graphql/queries';
import { useNavigation, useLinkTo } from '@react-navigation/native';

const ErrorText = ({ text }) => {
  return <Text style={{ color: '#ff4040' }}>{text}</Text>;
};
export const VersionNumber = () => {
  return <Text style={{ textAlign: 'center' }}>Version 0.8.6</Text>
};
const LoginComponent = (props, { navigation }) => {
  const {
    oAuthUser,
    oAuthError,
    hostedUISignIn,
    facebookSignIn,
    googleSignIn,
    amazonSignIn,
    customProviderSignIn,
    signOut,
  } = props;

  // const navigation = useNavigation();
  const linkTo = useLinkTo();

  const [user, setUser] = useState(null);
  const currentUser = useStore((state) => state.currentUser);
  const currentUserId = useStore((state) => state.currentUserId);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const setCurrentUserId = useStore((state) => state.setCurrentUserId);
  const setLoggedInStatus = useStore((state) => state.setLoggedInStatus);
  const [loginToggle, setLoginToggle] = useState(true);
  const [forgotPasswordToggle, setForgotPasswordToggle] = useState(false);
  const [signupToggle, setSignupToggle] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [confirmAccount, setConfirmAccount] = useState(false);
  const [code, setCode] = useState('');
  const [loginError, setLoginError] = useState('');
  const [signupError, setSignupError] = useState('');
  const [error, setError] = useState('');
  const [emptyErorr, setEmptyError] = useState(false);
  const [changePasswordToggle, setChangePasswordToggle] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [changePasswordUser, setChangePasswordUser] = useState({});
  const [confirmSignUpCode, setConfirmSignUpCode] = useState(false);
  const [loggedInWith, setLoggedInWith] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmUserAccountToggle, setConfirmUserAccountToggle] =
    useState(false);

  /** REFS */
  /** SignIn Refs */
  const EmailRef = useRef(null);
  const PasswordRef = useRef(null);

  /** SignUp Refs */
  const SignUpEmailRef = useRef(null);
  const SignUpPasswordRef = useRef(null);
  const SignUpFirstNameRef = useRef(null);
  const SignUpLastNameRef = useRef(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((res) => {
      console.log('Called');
      if (res.attributes.sub) {
        userUpdate();
      } else {
        setModalVisible(false);
        setLoginToggle(true);
      }
    });
  }, []);

  useEffect(() => {
    setEmail('');
    setPassword('');
    setCode('');
    setError('');
    setEmptyError(false);
    setNewPassword('');
  }, [loginToggle, forgotPasswordToggle, signupToggle, changePasswordToggle]);

  useEffect(() => {
    setError('');
  }, [forgotPasswordToggle, signupToggle, changePasswordToggle]);

  const userUpdate = (oAuthUser) => {
    setLoginToggle(false);
    setModalVisible(true);

    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((res) => {
        console.log(res);
        API.graphql(
          graphqlOperation(getUser, {
            id: res.attributes.sub,
          })
        ).then((userData) => {
          const user = {
            id: userData.data.getUser.id,
            first_name: userData.data.getUser.first_name,
            last_name: userData.data.getUser.last_name,
            phone_number: userData.data.getUser.phone_number,
            email: userData.data.getUser.email,
            qotd: userData.data.getUser.qotd,
            bible: userData.data.getUser.bible,
            french: userData.data.getUser.french,
            spanish: userData.data.getUser.spanish,
            active_account: !userData.data.getUser.active_account
              ? 1
              : userData.data.getUser.active_account,
            pause_notifications: userData.data.getUser.pause_notifications,
            user_search: userData.data.getUser.user_search,
          };
          setCurrentUser(user);
          setCurrentUserId(userData.data.getUser.id);
          setModalVisible(false);
        });
      })
      .catch((error) => console.log('no user...', error));
  };
  useEffect(() => {
    const backAction = () => {
      if (forgotPasswordToggle || signupToggle) {
        setForgotPasswordToggle(false);
        setSignupToggle(false);
        setLoginToggle(true);
        return true;
      }
    };

    const backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      backAction
    );
    return () => {
      backHandler.remove();
    };
  }, []);
  async function signIn() {
    if (!email || !password) {
      setEmptyError(true);
    } else {
      try {
        setLoginToggle(false);
        setModalVisible(true);
        const user = await Auth.signIn(email, password);
        console.log({ user });
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setModalVisible(false);
          setChangePasswordUser(user);
          setLoginToggle(false);
          setChangePasswordToggle(true);
        } else {
          setCurrentUser(user);
          setCurrentUserId(user.attributes.sub);
          setLoggedInStatus(true);
          setLoggedInWith(email);
          saveData(email, user);
          setModalVisible(false);
        }

        // setLoginToggle(false);
        // setModalVisible(true);
        // await Auth.signIn(email, password).then((signedUser) => {
        //   if (signedUser.hasOwnProperty('challengeName')) {
        //     if (signedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        //       setModalVisible(false);
        //       setChangePasswordUser(signedUser);
        //       setLoginToggle(false);
        //       setChangePasswordToggle(true);
        //     }
        //   } else {
        //     setCurrentUser(signedUser);
        //     setCurrentUserId(signedUser.attributes.sub);
        //     setLoggedInWith(email);
        //     saveData(email, signedUser);
        //     setModalVisible(false);
        //   }
        // });
      } catch (error) {
        if (error.code === 'UserNotConfirmedException') {
          setModalVisible(false);
          setLoginToggle(false);
          console.log({ email });
          setConfirmUserAccountToggle(true);
        } else {
          setModalVisible(false);
          setLoginToggle(true);
          setLoginError(error.message);
          setEmail('');
          setPassword('');
          console.log('error signing in', error);
        }
      }
    }
  }
  const signUp = async () => {
    if (!email || !password || !firstName || !lastName) {
      setEmptyError(true);
    } else {
      try {
        await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email,
            'custom:first_name': firstName,
            'custom:last_name': lastName,
          },
        });
        console.log('Sign-up Confirmed');
        setConfirmAccount(true);
      } catch (error) {
        setSignupError(error.message);
        console.log('error while signup', error);
      }
    }
  };

  async function signInWithFacebook() {
    try {
      setLoginToggle(false);
      setModalVisible(true);
      await Auth.federatedSignIn({provider: 'Facebook'})
      let user = Auth.currentAuthenticatedUser()
      console.log({ user });
      // setCurrentUser(user);
      // setCurrentUserId(user.attributes.sub);
      // setLoggedInStatus(true);
      // setLoggedInWith(email);
      // saveData(email, user);
      // setModalVisible(false);
    } catch (error) {
      setModalVisible(false);
      setLoginToggle(true);
      setLoginError(error.message);
      setEmail('');
      setPassword('');
      console.log('error signing in', error);
    }
  }

  const confirmSignUp = async () => {
    if (email && code) {
      try {
        await Auth.confirmSignUp(email, code);
        setSignupToggle(false);
        setConfirmUserAccountToggle(false);
        setLoginToggle(true);
      } catch (error) {
        setError(error.message);
        console.log('error wnile confirm sigup', error);
      }
    } else {
      setEmptyError(true);
    }
  };
  const sendCode = async (username) => {
    if (username) {
      try {
        await Auth.forgotPassword(username);
        setCodeSent(!codeSent);
      } catch (error) {
        setError(error.message);
        console.log('forgot password error', error);
      }
    } else {
      setEmptyError(true);
    }
  };

  const confirmPassword = async () => {
    if (email && code && password) {
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
        setForgotPasswordToggle(false);
        setCodeSent(false);
        setLoginToggle(true);
      } catch (error) {
        setError(error.message);
        console.log('error while confirm password', error);
      }
    } else {
      setEmptyError(true);
    }
  };
  const confirmNewPassword = async () => {
    try {
      await Auth.completeNewPassword(changePasswordUser, newPassword).then(
        (user) => {
          console.log('user password changed');
          try {
            API.graphql(
              graphqlOperation(updateUser, {
                input: {
                  id: user.signInUserSession.accessToken.payload.username,
                  active_account: 1,
                },
              })
            );
          } catch (error) {
            console.log('updating user accout status error', error);
          }
        }
      );
      setModalVisible(true);
      setChangePasswordToggle(false);
      loginNewPassword();
    } catch (error) {
      console.log('error while changing password', error);
    }
  };
  const loginNewPassword = async () => {
    try {
      console.log('getting current user');
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log({ user });
          console.log('setting current user');
          setCurrentUser(user);
          setCurrentUserId(user.attributes.sub);
          setLoggedInWith(user.attributes.email);
          saveData(user.attributes.email, user);
        })
        .catch((err) => console.log({ err }));
      setModalVisible(false);
      setLoggedInStatus(true);
      setChangePasswordToggle(false);
      setLoginToggle(false);
      setConfirmUserAccountToggle(false);
      setConfirmSignUpCode(false);
      setSignupToggle(false);
      setForgotPasswordToggle(false);
    } catch (error) {
      console.log('login err after changing password', error);
    }
  };
  const confirmSignUpCode2 = async () => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
    } catch (error) {
      console.log("Couldn't verify code", error);
    }
  };

  const resendConfirmationCode = async () => {
    if (email) {
      try {
        await Auth.resendSignUp(email);
        console.log('code resent successfully');
      } catch (err) {
        console.log('error resending code: ', err);
      }
    } else {
      setEmptyError(true);
    }
  };

  const saveData = async (data, userData) => {
    try {
      // await AsyncStorage.setItem('@loggedInWith', loggedInWith);
      await AsyncStorage.setItem('@loggedInWith', data);
      const jsonValue = JSON.stringify(userData);
      await AsyncStorage.setItem('@userInfo', jsonValue);
      console.log('Data successfully saved', jsonValue);
    } catch (e) {
      console.log('Failed to save the data to the storage');
    }
  };
  return (
    <>
      <Container>
        {/* <Portal> */}
        <Dialog
          visible={modalVisible}
          style={{ width: 300, alignSelf: 'center' }}
        >
          <Dialog.Content style={{ flexDirection: 'row' }}>
            <Paragraph>Gathering Your Data</Paragraph>
          </Dialog.Content>
        </Dialog>
        {/* </Portal> */}
        {loginToggle && (
          <View style={styles.container}>
            <Logo />
            <HeaderText text={'Login to your account'} />
            {loginError.length > 1 && <ErrorText text={loginError} />}
            {emptyErorr && <ErrorText text={'All fields are required!'} />}
            <View>
              <Item
                regular
                style={styles.input}
                onFocus={() => {
                  EmailRef.current._root.focus();
                }}
              >
                <Input
                  ref={EmailRef}
                  placeholder="Email"
                  placeholderTextColor="#000"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  autoCapitalize="none"
                  onChangeText={(text) => {
                    setEmail(text.toLowerCase());
                    setLoginError('');
                    setEmptyError(false);
                  }}
                />
              </Item>

              <Item
                regular
                style={styles.input}
                onFocus={() => {
                  PasswordRef.current._root.focus();
                }}
              >
                <Input
                  ref={PasswordRef}
                  placeholder="Password"
                  placeholderTextColor="#000"
                  textContentType="password"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  secureTextEntry
                  onChangeText={(text) => {
                    setPassword(text);
                    setLoginError('');
                    setEmptyError(false);
                  }}
                />
              </Item>
              <Button rounded dark style={styles.button} onPress={signIn}>
                <Text style={styles.buttonText}>Sign In</Text>
              </Button>
              <TouchableOpacity
                onPress={() => {
                  setLoginToggle(false);
                  setForgotPasswordToggle(true);
                }}
              >
                <Text
                  style={[styles.text, { textDecorationLine: 'underline' }]}
                >
                  Forgot Password?
                </Text>
              </TouchableOpacity>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginVertical: 15,
                }}
              >
                <View style={styles.sitationNameLeftDash}></View>
                <Text style={styles.sitationPersonName}>Or sign up with</Text>
                <View style={styles.sitationNameRightDash}></View>
              </View>

              <Button
                block
                rounded
                style={{
                  marginVertical: 5,
                  backgroundColor: '#DB4437',
                  width: 275,
                  alignSelf: 'center',
                }}
              >
                <Ionicons name="logo-google" size={24} color="#fff" />
                <Text>Sign in with Google</Text>
              </Button>

              <Button
                block
                rounded
                style={{
                  marginVertical: 5,
                  backgroundColor: '#3B5998',
                  width: 275,
                  alignSelf: 'center',
                }}
                onPress={() => {signInWithFacebook()}}
              >
                <MaterialCommunityIcons
                  name="facebook"
                  size={24}
                  color="#fff"
                />
                <Text>Sign in with Facebook</Text>
              </Button>
              {Platform.OS === 'ios' && (
                <Button
                  block
                  rounded
                  dark
                  style={{
                    marginVertical: 5,
                    width: 275,
                    alignSelf: 'center',
                  }}
                >
                  <MaterialCommunityIcons name="apple" size={24} color="#fff" />
                  <Text>Sign in with Apple</Text>
                </Button>
              )}

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 80,
                }}
              >
                <Text style={{ textAlign: 'center' }}>
                  Don't have an account?
                </Text>
                <Text
                  style={{ color: '#c10644', marginHorizontal: 3 }}
                  onPress={() => {
                    setLoginToggle(false), setSignupToggle(true);
                  }}
                >
                  Sign up
                </Text>
              </View>
              <VersionNumber />
              {/* <TouchableOpacity
								onPress={() => {
									linkTo('/Privacy');
								}}>
								<Text>Privacy</Text>
							</TouchableOpacity> */}
            </View>
          </View>
        )}
        {confirmUserAccountToggle && (
          <View style={styles.container}>
            <TouchableOpacity
              onPress={() => {
                setConfirmUserAccountToggle(false);
                setCodeSent(false);
                setLoginToggle(true);
              }}
            >
              <Entypo name="chevron-left" size={30} color="black" />
            </TouchableOpacity>
            <Logo />
            <HeaderText text={'Confirm your account'} />
            <Text>Verification code will be sent to your email</Text>

            {codeSent ? (
              <>
                <ErrorText text={error} />
                {emptyErorr && <ErrorText text={'Email required'} />}
                <Item regular style={styles.input}>
                  <Input
                    // value={code}
                    placeholder="Code"
                    placeholderTextColor="#000"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    autoCapitalize="none"
                    onChangeText={(text) => {
                      setCode(text);
                      setError('');
                    }}
                  />
                </Item>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 80,
                  }}
                >
                  <Text style={{ textAlign: 'center' }}>
                    Didn't get the code?
                  </Text>
                  <Text
                    style={{ color: '#c10644', marginHorizontal: 3 }}
                    onPress={() => {
                      resendConfirmationCode();
                    }}
                  >
                    Resend
                  </Text>
                </View>
                <Button
                  rounded
                  dark
                  style={[styles.button, { marginBottom: 5 }]}
                  onPress={() => {
                    confirmSignUp();
                  }}
                >
                  <Text style={styles.buttonText}> Confirm Code</Text>
                </Button>
              </>
            ) : (
              <>
                <ErrorText text={error} />
                {emptyErorr && <ErrorText text={'Email required'} />}
                <Item regular style={styles.input}>
                  <Input
                    // value={email}
                    placeholder="Email"
                    placeholderTextColor="#000"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    autoCapitalize="none"
                    onChangeText={(text) => {
                      setEmail(text.toLowerCase());
                      setError('');
                      setEmptyError(false);
                    }}
                  />
                </Item>
                <Button
                  rounded
                  dark
                  onPress={async () => {
                    await Auth.resendSignUp(email).catch((err) => {
                      console.log('errror sending email', err);
                    });
                    setCodeSent(!codeSent);
                  }}
                  style={{ alignSelf: 'center' }}
                >
                  <Text
                    style={{
                      // fontFamily: 'OPEN-SANS-BOLD',
                      fontWeight: '500',
                    }}
                  >
                    Send Code
                  </Text>
                </Button>
              </>
            )}
          </View>
        )}
        {changePasswordToggle && (
          <View style={styles.container}>
            <TouchableOpacity
              onPress={() => {
                setChangePasswordToggle(false);
                setCodeSent(false);
                setLoginToggle(true);
              }}
            >
              <Entypo name="chevron-left" size={30} color="black" />
            </TouchableOpacity>
            <Logo />
            <HeaderText text={'Change your password'} />
            {!codeSent ? (
              <>
                {emptyErorr && <ErrorText text={'New password required.'} />}
                <Item regular style={styles.input}>
                  <Input
                    // value={newPassword}
                    placeholder="New Password"
                    placeholderTextColor="#000"
                    textContentType="password"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    secureTextEntry
                    onChangeText={(text) => setNewPassword(text)}
                  />
                </Item>
                <Button
                  rounded
                  dark
                  style={[styles.button, { marginBottom: 5 }]}
                  onPress={() => confirmNewPassword()}
                >
                  <Text style={styles.buttonText}> Confirm Password</Text>
                </Button>
              </>
            ) : (
              <>
                {emptyErorr && (
                  <ErrorText text={'Verification code is required.'} />
                )}
                <Text>Verification code will be sent to your email</Text>
                <Item regular style={styles.input}>
                  <Input
                    // value={code}
                    placeholder="Code"
                    placeholderTextColor="#000"
                    textContentType="telephoneNumber"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    onChangeText={(text) => setCode(text)}
                  />
                </Item>
                <Button
                  rounded
                  dark
                  style={[styles.button, { marginBottom: 5 }]}
                  onPress={() => confirmSignUpCode2()}
                >
                  <Text style={styles.buttonText}> Submit</Text>
                </Button>
              </>
            )}
          </View>
        )}
        {/* {confirmSignUpCode && (
          <>
            <View style={styles.container}>
              <TouchableOpacity
                onPress={() => {
                  setChangePasswordToggle(false);
                  setLoginToggle(true);
                }}
              >
                <Entypo name="chevron-left" size={30} color="black" />
              </TouchableOpacity>
              <Logo />
              <HeaderText text={'Change your password'} />
              <Item regular style={styles.input}>
                <Input
                  value={code}
                  placeholder="Code"
                  placeholderTextColor="#000"
                  textContentType="telephoneNumber"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  onChangeText={(text) => setCode(text)}
                />
              </Item>
              <Button
                rounded
                dark
                style={[styles.button, { marginBottom: 5 }]}
                onPress={() => confirmSignUpCode2()}
              >
                <Text style={styles.buttonText}> Submit</Text>
              </Button>
            </View>
          </>
        )} */}
        {signupToggle && (
          <>
            <View style={[styles.container, { paddingTop: 40 }]}>
              <TouchableOpacity
                onPress={() => {
                  setSignupToggle(false);
                  setLoginToggle(true);
                }}
              >
                <Entypo name="chevron-left" size={30} color="black" />
              </TouchableOpacity>
              <Logo />
              <HeaderText text={'Create your account'} />
              {signupError.length > 1 && <ErrorText text={signupError} />}
              {emptyErorr && <ErrorText text={'All fields are required'} />}
              <Item
                regular
                style={styles.input}
                onFocus={() => {
                  SignUpFirstNameRef.current._root.focus();
                }}
              >
                <Input
                  ref={SignUpFirstNameRef}
                  // value={firstName}
                  placeholder="First Name"
                  placeholderTextColor="#000"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  autoCapitalize="none"
                  onChangeText={(text) => {
                    setFirstName(text);
                    setSignupError('');
                    setEmptyError(false);
                  }}
                />
              </Item>
              <Item
                regular
                style={styles.input}
                onFocus={() => {
                  SignUpLastNameRef.current._root.focus();
                }}
              >
                <Input
                  ref={SignUpLastNameRef}
                  // value={lastName}
                  placeholder="Last Name"
                  placeholderTextColor="#000"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  autoCapitalize="none"
                  onChangeText={(text) => {
                    setLastName(text);
                    setSignupError('');
                    setEmptyError(false);
                  }}
                />
              </Item>
              <Item
                regular
                style={styles.input}
                onFocus={() => {
                  SignUpEmailRef.current._root.focus();
                }}
              >
                <Input
                  ref={SignUpEmailRef}
                  // value={email}
                  placeholder="Email"
                  placeholderTextColor="#000"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  autoCapitalize="none"
                  onChangeText={(text) => {
                    setEmail(text.toLowerCase());
                    setSignupError('');
                    setEmptyError(false);
                  }}
                />
              </Item>
              <Item
                regular
                style={styles.input}
                onFocus={() => {
                  SignUpPasswordRef.current._root.focus();
                }}
              >
                <Input
                  ref={SignUpPasswordRef}
                  // value={password}
                  placeholder="Password"
                  placeholderTextColor="#000"
                  textContentType="password"
                  style={{ fontSize: 15, fontWeight: '500' }}
                  secureTextEntry
                  onChangeText={(text) => {
                    setPassword(text);
                    setSignupError('');
                    setEmptyError(false);
                  }}
                />
              </Item>

              <Button
                rounded
                dark
                style={[styles.button, { marginBottom: 5 }]}
                onPress={() => signUp()}
              >
                <Text style={styles.buttonText}>Sign Up</Text>
              </Button>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginVertical: 15,
                }}
              >
                <View style={styles.sitationNameLeftDash}></View>
                <Text style={styles.sitationPersonName}>Or sign up with</Text>
                <View style={styles.sitationNameRightDash}></View>
              </View>

              <Button
                block
                rounded
                style={{
                  marginVertical: 5,
                  backgroundColor: '#DB4437',
                  width: 275,
                  alignSelf: 'center',
                }}
              >
                <Ionicons name="logo-google" size={24} color="#fff" />
                <Text>Sign up with Google</Text>
              </Button>

              <Button
                block
                rounded
                style={{
                  marginVertical: 5,
                  backgroundColor: '#3B5998',
                  width: 275,
                  alignSelf: 'center',
                }}
              >
                <MaterialCommunityIcons
                  name="facebook"
                  size={24}
                  color="#fff"
                />
                <Text>Sign up with Facebook</Text>
              </Button>

              {Platform.OS === 'ios' && (
                <Button
                  block
                  rounded
                  dark
                  style={{
                    marginVertical: 5,
                    width: 275,
                    alignSelf: 'center',
                  }}
                >
                  <MaterialCommunityIcons name="apple" size={24} color="#fff" />
                  <Text>Sign up with Apple</Text>
                </Button>
              )}
              <VersionNumber />
              <Portal>
                <Dialog
                  visible={confirmAccount}
                  onDismiss={() => {
                    setConfirmAccount(false);
                  }}
                  style={{
                    width: 350,
                    backgroundColor: '#fff',
                    alignSelf: 'center',
                  }}
                >
                  <View>
                    <Dialog.Title
                      style={{ alignSelf: 'center', color: '#000' }}
                    >
                      Confirm Your Account
                    </Dialog.Title>
                  </View>
                  <Text style={{ alignSelf: 'center' }}>
                    Confirmation has been sent to your account
                  </Text>
                  <ErrorText text={error} />
                  <Dialog.Content>
                    <Item regular style={styles.input}>
                      <Input
                        // value={email}
                        placeholder="Email"
                        placeholderTextColor="#000"
                        style={{ fontSize: 15, fontWeight: '500' }}
                        autoCapitalize="none"
                        onChangeText={(text) => {
                          setEmail(text.toLowerCase());
                          setError('');
                        }}
                      />
                    </Item>
                    <Item regular style={styles.input}>
                      <Input
                        // value={code}
                        placeholder="Code"
                        placeholderTextColor="#000"
                        style={{ fontSize: 15, fontWeight: '500' }}
                        autoCapitalize="none"
                        onChangeText={(text) => {
                          setCode(text);
                          setError('');
                        }}
                      />
                    </Item>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 80,
                      }}
                    >
                      <Text style={{ textAlign: 'center' }}>
                        Didn't get the code?
                      </Text>
                      <Text
                        style={{ color: '#c10644', marginHorizontal: 3 }}
                        onPress={() => {
                          resendConfirmationCode();
                        }}
                      >
                        Resend
                      </Text>
                    </View>
                  </Dialog.Content>
                  <Dialog.Actions style={{ justifyContent: 'center' }}>
                    <View style={styles.modalButtonsView}>
                      <TouchableOpacity
                        onPress={() => setConfirmAccount(!confirmAccount)}
                      >
                        <Text>Cancel</Text>
                      </TouchableOpacity>
                      <Button rounded dark onPress={confirmSignUp}>
                        <Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>
                          Confirm
                        </Text>
                      </Button>
                    </View>
                  </Dialog.Actions>
                </Dialog>
              </Portal>
            </View>
          </>
        )}
        {forgotPasswordToggle && (
          <View style={styles.container}>
            <TouchableOpacity
              onPress={() => {
                setCodeSent(false);
                setForgotPasswordToggle(false);
                setLoginToggle(true);
              }}
            >
              <Entypo name="chevron-left" size={30} color="black" />
            </TouchableOpacity>
            <Logo />
            <HeaderText text={'Reset your password'} />
            {codeSent ? (
              <>
                <ErrorText text={error} />
                <Text>Code is sent to your email address</Text>
                <Item regular style={styles.input}>
                  <Input
                    // value={code}
                    placeholder="Code"
                    placeholderTextColor="#000"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    autoCapitalize="none"
                    onChangeText={(text) => {
                      setCode(text);
                      setError('');
                      setEmptyError(false);
                    }}
                  />
                </Item>
                <Item regular style={styles.input}>
                  <Input
                    // value={password}
                    placeholder="Password"
                    placeholderTextColor="#000"
                    textContentType="password"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    secureTextEntry
                    onChangeText={(text) => {
                      setPassword(text);
                      setError('');
                      setEmptyError(false);
                    }}
                  />
                </Item>
                <Button
                  rounded
                  dark
                  onPress={() => {
                    confirmPassword();
                  }}
                  style={{ alignSelf: 'center' }}
                >
                  <Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>
                    Confirm Passowrd
                  </Text>
                </Button>
              </>
            ) : (
              <>
                <ErrorText text={error} />
                <Item regular style={styles.input}>
                  <Input
                    // value={email}
                    placeholder="Email"
                    placeholderTextColor="#000"
                    style={{ fontSize: 15, fontWeight: '500' }}
                    autoCapitalize="none"
                    onChangeText={(text) => {
                      setEmail(text.toLowerCase());
                      setError('');
                      setEmptyError(false);
                    }}
                  />
                </Item>
                <Button
                  rounded
                  dark
                  onPress={() => {
                    sendCode(email);
                  }}
                  style={{ alignSelf: 'center' }}
                >
                  <Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>
                    Send Code
                  </Text>
                </Button>
              </>
            )}
          </View>
        )}
      </Container>
    </>
  );
};

export default LoginComponent;
