import create from 'zustand';
import moment from 'moment';

let hr = moment('10:45', 'h:mm').format('HH');
let min = moment('10:45', 'h:mm').format('mm');
let month = moment().format('M');
let day = moment().format('D');
let year = moment().format('YYYY');

export const useStore = create((set, get) => ({
  currentUser: {},
  setCurrentUser: (currentUser) => set({ currentUser }),
  currentUserId: '',
  setCurrentUserId: (currentUserId) => set({ currentUserId }),
  userSignedInWith: '',
  setUserSignedInWith: (userSignedInWith) => set({ userSignedInWith }),
  selectedUser: [],
  setSelectedUser: (selectedUser) => set({ selectedUser }),
  createGroupSelectedUsers: [],
  setCreateGroupSelectedUsers: (createGroupSelectedUsers) =>
    set({ createGroupSelectedUsers }),
  currentConversationGroupId: '',
  setCurrentConversationGroupId: (currentConversationGroupId) =>
    set({ currentConversationGroupId }),
  currentConversationGroup: {},
  setCurrentConversationGroup: (currentConversationGroup) =>
    set({ currentConversationGroup }),
  expoToken: '',
  setExpoToken: (expoToken) => set({ expoToken }),
  forgotPasswordToggale: false,
  setForgotPasswordToggale: (forgotPasswordToggale) =>
    set({ forgotPasswordToggale }),
  autoMessage: 'Good Morning!',
  setAutoMessage: (autoMessage) => set({ autoMessage }),
  followMessage: 'Hello???',
  setFollowMessage: (followMessage) => set({ followMessage }),
  followMessageTime: 0,
  setFollowMessageTime: (followMessageTime) => set({ followMessageTime }),
  messageScheduleTime: hr,
  setMessageScheduleTime: (messageScheduleTime) => set({ messageScheduleTime }),
  messageScheduleTimeMin: min,
  setMessageScheduleTimeMin: (messageScheduleTimeMin) =>
    set({ messageScheduleTimeMin }),
  messageScheduleTimeMon: month,
  setMessageScheduleTimeMon: (messageScheduleTimeMon) =>
    set({ messageScheduleTimeMon }),
  messageScheduleTimeDay: day,
  setMessageScheduleTimeDay: (messageScheduleTimeDay) =>
    set({ messageScheduleTimeDay }),
  messageScheduleTimeDayWK: '?',
  setMessageScheduleTimeDayWK: (messageScheduleTimeDayWK) =>
    set({ messageScheduleTimeDayWK }),
  messageScheduleTimeYr: year,
  setMessageScheduleTimeYr: (messageScheduleTimeYr) =>
    set({ messageScheduleTimeYr }),
  messageStream: '1',
  setMessageStream: (messageStream) => set({ messageStream }),
  customAutoMessageBool: false,
  setCustomAutoMessageBool: (customAutoMessageBool) =>
    set({ customAutoMessageBool }),
  customAutoFollowMessageBool: false,
  setCustomAutoFollowMessageBool: (customAutoFollowMessageBool) =>
    set({ customAutoFollowMessageBool }),
  customAutoMessageText: '',
  setCustomAutoMessageText: (customAutoMessageText) =>
    set({ customAutoMessageText }),
  customAutoFollowMessageText: '',
  setCustomAutoFollowMessageText: (customAutoFollowMessageText) =>
    set({ customAutoFollowMessageText }),
  customAutoMessageTextEdited: false,
  setCustomAutoMessageTextEdited: (customAutoMessageTextEdited) =>
    set({ customAutoMessageTextEdited }),
  customAutoFollowMessageTextEdited: false,
  setCustomAutoFollowMessageTextEdited: (customAutoFollowMessageTextEdited) =>
    set({ customAutoFollowMessageTextEdited }),
  loggedInStatus: false,
  setLoggedInStatus: (loggedInStatus) => set({ loggedInStatus }),
  screen: '',
  setScreen: (screen) => set({ screen }),
  vipTempleteMessageInput: '',
  setVipTempleteMessageInput: (vipTempleteMessageInput) =>
    set({ vipTempleteMessageInput }),
  globalCurrentUserGroups: [],
  setGlobalCurrentUserGroups: (globalCurrentUserGroups) =>
    set({ globalCurrentUserGroups }),
}));
