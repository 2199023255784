import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
	container: {
		paddingHorizontal: 14,
		maxWidth: 1160,
		marginLeft: 'auto',
		marginRight: 'auto',
		minWidth: '50vw',
	},
	text: {
		fontFamily: 'CRIMSON-TEXT',
		fontSize: 16,
		fontWeight: '400',
		paddingVertical: 5,
		marginVertical: 10,
	},
});

export default styles;
