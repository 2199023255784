import React, { useState, useEffect } from 'react';
import {
  Container,
  Content,
  Form,
  Item,
  Input,
  Label,
  Text,
  Button,
} from 'native-base';
import containerStyles from './containerStyle';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import SubHeader from '../SubHeader';
import AccountSettingsButton from '../Button';
import { useStore } from '../../store';
import { useNavigation } from '@react-navigation/native';
import AccountSettingsHeader from './AccountSettingsHeader';

const Email = () => {
  const navigation = useNavigation();
  const currentUserId = useStore((state) => state.currentUserId);
  const currentUser = useStore((state) => state.currentUser);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);

  useEffect(() => {
    setEmail(currentUser.email);
  }, []);

  const sendCode = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: email });
      setCodeSent(true);
    } catch (error) {
      console.log('Error while sendign code', error);
    }
  };
  const resendConfirmationCode = async () => {
    try {
      await Auth.verifyCurrentUserAttribute('email');
      console.log('code resent successfully');
    } catch (err) {
      console.log('error resending code: ', err);
    }
  };
  const updateAtCognitoUserPool = async () => {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
      updateEmail();
    } catch (error) {
      console.log('Error while updating user pool', error);
    }
  };
  const updateEmail = async () => {
    setCurrentUser({ ...currentUser }, (currentUser.email = email));
    // console.log('After changing global state of currentUser', currentUser);
    try {
      let res = await API.graphql({
        query: mutations.updateUser,
        variables: {
          input: {
            id: currentUserId,
            email: email,
          },
        },
      });

      setCurrentUser(
        { ...currentUser },
        (currentUser.email = res.data.updateUser.email)
      );
      await AsyncStorage.setItem('@loggedInWith', email).catch((error) => {
        console.log('Eroor....', error);
      });
      navigation.navigate('AccountScreen');
      // console.log('after changing the emial into db.....', res);
    } catch (error) {
      console.log('error while writing email to db...', error);
    }
  };

  return (
    <>
      <AccountSettingsHeader screenName={'Email'} />
      <Container style={containerStyles.container}>
        <SubHeader text={'Enter Email Address'} />
        <Text style={containerStyles.text}>
          You will receive a verification email shortly after updating.
        </Text>
        {!codeSent ? (
          <Form>
            <Item
              style={{ borderBottomWidth: 1, borderBottomColor: '#1e1e1e' }}
            >
              <Input
                value={email}
                placeholder="Email"
                onChangeText={(text) => setEmail(text)}
              />
            </Item>
            <Button
              rounded
              dark
              style={{ alignSelf: 'center', marginVertical: 15 }}
              onPress={() => sendCode()}
            >
              <Text>Save</Text>
            </Button>
            {/* <AccountSettingsButton text={"Save Changes"} /> */}
          </Form>
        ) : (
          <>
            <Item
              style={{ borderBottomWidth: 1, borderBottomColor: '#1e1e1e' }}
            >
              <Input
                value={code}
                placeholder="Code"
                onChangeText={(text) => setCode(text)}
              />
            </Item>
            <Button
              rounded
              dark
              style={{ alignSelf: 'center', marginVertical: 15 }}
              onPress={() => updateAtCognitoUserPool()}
            >
              <Text>Submit</Text>
            </Button>
            <Text
              onPress={resendConfirmationCode}
              style={[
                { textAlign: 'center', marginVertical: 15 },
                containerStyles.text,
              ]}
            >
              Resend
            </Text>
          </>
        )}
      </Container>
    </>
  );
};

export default Email;
