import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Alert } from 'react-native';
import { MaterialCommunityIcons, FontAwesome5 } from '@expo/vector-icons';

const GroupSettingItem = ({ itemIcon, itemName, screenName, navigation }) => {
	return (
		<TouchableOpacity
			onPress={() => {
				navigation.navigate(screenName);
			}}>
			<View style={styles.itemView}>
				<MaterialCommunityIcons
					name={itemIcon}
					size={30}
					style={{ alignSelf: 'center', marginRight: 10 }}
				/>
				<View style={styles.itemNameAndIconView}>
					<Text style={{ alignSelf: 'center' }}>{itemName}</Text>
					<FontAwesome5 name="chevron-right" size={20} />
				</View>
			</View>
		</TouchableOpacity>
	);
};

const styles = StyleSheet.create({
	itemView: {
		display: 'flex',
		flexDirection: 'row',
		paddingVertical: 5,
	},
	itemNameAndIconView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomWidth: 1,
		borderBottomColor: '#D7D7D8',
		paddingVertical: 10,
	},
});

export default GroupSettingItem;
