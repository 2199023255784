import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Alert } from 'react-native';
import { Container, CheckBox } from 'native-base';
import AccountSettingsHeader from './AccountSettingsHeader';
import containerStyles from './containerStyle';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useStore } from '../../store/index';
import { useNavigation } from '@react-navigation/native';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
const MessageOfTheDay = () => {
	const navigation = useNavigation();
	const currentUser = useStore((state) => state.currentUser);
	const currentUserId = useStore((state) => state.currentUserId);
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const [qodChecked, setQodChecked] = useState();
	const [bvofChecked, setBvofChecked] = useState();
	const [fvodChecked, setFvodChecked] = useState();
	const [svodChecked, setSvodChecked] = useState();

	useEffect(() => {
		currentUser.qotd === 1 ? setQodChecked(true) : setQodChecked(false);
		currentUser.bible === 1 ? setBvofChecked(true) : setBvofChecked(false);
		currentUser.french === 1 ? setFvodChecked(true) : setFvodChecked(false);
		currentUser.spanish === 1 ? setSvodChecked(true) : setSvodChecked(false);
	}, [currentUser]);

	useEffect(() => {
		navigation.addListener('blur', () => {
			setCurrentUser({ ...currentUser });
			try {
				API.graphql({
					query: mutations.updateUser,
					variables: {
						input: {
							id: currentUserId,
							qotd: currentUser.qotd,
							bible: currentUser.bible,
							spanish: currentUser.spanish,
							french: currentUser.french,
						},
					},
				}).then((res) => {
					console.log('User daily messages updated,', res);
				});
			} catch (error) {
				console.log('could not update user daily messages', error);
			}
		});
	}, []);

	const handleCheckBoxSelection = (messageType) => {
		currentUser[messageType] = currentUser[messageType] === 1 ? 0 : 1;
	};
	return (
		<>
			<AccountSettingsHeader screenName={'Message of the Day'} />
			<Container style={containerStyles.container}>
				<View style={styles.itemView}>
					<MaterialCommunityIcons
						name={'rename-box'}
						size={25}
						style={{ alignSelf: 'center', marginRight: 10 }}
					/>
					<View style={styles.itemNameAndIconView}>
						<Text style={containerStyles.text}>Quote of the Day</Text>
						<MaterialCommunityIcons
							name={
								!qodChecked ? 'checkbox-blank-circle-outline' : 'circle-slice-8'
							}
							size={19}
							onPress={() => {
								handleCheckBoxSelection('qotd');
								setQodChecked((checked) => !checked);
							}}
							style={{ alignSelf: 'center' }}
						/>
						{/* <CheckBox
							checked={qodChecked}
							color='#1e1e1e'
							style={{ marginRight: 15 }}
							onPress={() => {
								handleCheckBoxSelection('qotd');
								// setCurrentUser(
								// 	{ ...currentUser },
								// 	(currentUser.qotd = currentUser.qotd === 1 ? 0 : 1)
								// );

								setQodChecked((checked) => !checked);
							}}
						/> */}
					</View>
				</View>

				<View style={styles.itemView}>
					<MaterialCommunityIcons
						name={'rename-box'}
						size={25}
						style={{ alignSelf: 'center', marginRight: 10 }}
					/>
					<View style={styles.itemNameAndIconView}>
						<Text style={containerStyles.text}>Bible Verse of the Day</Text>
						<MaterialCommunityIcons
							name={
								!bvofChecked
									? 'checkbox-blank-circle-outline'
									: 'circle-slice-8'
							}
							size={19}
							onPress={() => {
								handleCheckBoxSelection('bible');
								setBvofChecked((checked) => !checked);
							}}
							style={{ alignSelf: 'center' }}
						/>
						{/* <CheckBox
							checked={bvofChecked}
							color='#1e1e1e'
							style={{ marginRight: 15 }}
							onPress={() => {
								handleCheckBoxSelection('bible');

								// setCurrentUser(
								// 	{ ...currentUser },
								// 	(currentUser.bible = currentUser.bible === 1 ? 0 : 1)
								// );

								setBvofChecked((checked) => !checked);
							}}
						/> */}
					</View>
				</View>

				<View style={styles.itemView}>
					<MaterialCommunityIcons
						name={'rename-box'}
						size={25}
						style={{ alignSelf: 'center', marginRight: 10 }}
					/>
					<View style={styles.itemNameAndIconView}>
						<Text style={containerStyles.text}>French Word of the Day</Text>
						<MaterialCommunityIcons
							name={
								!fvodChecked
									? 'checkbox-blank-circle-outline'
									: 'circle-slice-8'
							}
							size={19}
							onPress={() => {
								handleCheckBoxSelection('french');
								setFvodChecked((checked) => !checked);
							}}
							style={{ alignSelf: 'center' }}
						/>
						{/* <CheckBox
							checked={fvodChecked}
							color='#1e1e1e'
							style={{ marginRight: 15 }}
							onPress={() => {
								handleCheckBoxSelection('french');

								// setCurrentUser(
								// 	{ ...currentUser },
								// 	(currentUser.french = currentUser.french === 1 ? 0 : 1)
								// );

								setFvodChecked((checked) => !checked);
							}}
						/> */}
					</View>
				</View>

				<View style={styles.itemView}>
					<MaterialCommunityIcons
						name={'rename-box'}
						size={25}
						style={{ alignSelf: 'center', marginRight: 10 }}
					/>
					<View style={styles.itemNameAndIconView}>
						<Text style={containerStyles.text}>Spanish Word of the Day</Text>
						<MaterialCommunityIcons
							name={
								!svodChecked
									? 'checkbox-blank-circle-outline'
									: 'circle-slice-8'
							}
							size={19}
							onPress={() => {
								handleCheckBoxSelection('spanish');
								setSvodChecked((checked) => !checked);
							}}
							style={{ alignSelf: 'center' }}
						/>
						{/* <CheckBox
							checked={svodChecked}
							color='#1e1e1e'
							style={{ marginRight: 15 }}
							onPress={() => {
								handleCheckBoxSelection('spanish');

								// setCurrentUser(
								// 	{ ...currentUser },
								// 	(currentUser.spanish = currentUser.spanish === 1 ? 0 : 1)
								// );
								setSvodChecked((checked) => !checked);
							}}
						/> */}
					</View>
				</View>
			</Container>
		</>
	);
};

const styles = StyleSheet.create({
	itemView: {
		display: 'flex',
		flexDirection: 'row',
		paddingVertical: 5,
	},
	itemNameAndIconView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomWidth: 1,
		borderBottomColor: '#D7D7D8',
		paddingVertical: 10,
	},
});

export default MessageOfTheDay;
