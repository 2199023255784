import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  Authenticator,
  Auth,
  withOAuth,
  SignIn,
} from "aws-amplify-react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import LoginStackNavigator from "../LoginStackNavigator/LoginStackNavigator";
import BootmTabNavigator from "../BottomTabNavigator/BootmTabNavigator";
import LoginComponent from "../../screens/LoginScreen";
import { useStore } from "../../store/index";

const Routes = () => {
  const loggedInStatus = useStore((state) => state.loggedInStatus);
  const [loggedInState, setLoggedInState] = useState(loggedInStatus);
  // let loggedInState = loggedInStatus;
  useEffect(() => {
    isLoggedIn();
  }, [loggedInStatus]);
  useEffect(() => {
    console.log({ loggedInStatus });
    console.log({ loggedInState });
  }, [loggedInState, loggedInStatus]);

  const isLoggedIn = async () => {
    try {
      // await Auth.currentAuthenticatedUser()
      // .then(() => {
      //   this.setState({ loaded: true, isAuthenticated: true })
      // })

      // await Auth.currentAuthenticatedUser()
      //   .then((res) => {
      //     console.log("auth current: ", { res });
      //     setLoggedInState(true);
      //   })
      //   .catch((err) => setLoggedInState(false));
      if (loggedInStatus || loggedInState) {
        console.log("yes we are logged in");
        setLoggedInState(true);
        return;
      } else {
        console.log("no we are not logged in");
        await AsyncStorage.getItem("@LoggedIn").then((res) => {
          console.log("response....=", res);
          if (res == "signedin") {
            setLoggedInState(true);
            console.log("setting true");
            return;
          } else {
            setLoggedInState(false);
            console.log("setting false");
            checkUser();
          }
        });
        // if (!loggedInStatus && !loggedInState) {
        //   await Auth.currentAuthenticatedUser({
        //     bypassCache: true,
        //   })
        //     .then((res) => {
        //       console.log("auth current: ", { res });
        //       setLoggedInState(true);
        //     })
        //     .catch(
        //       (err) => (setLoggedInState(false), console.log("no user...", err))
        //     );
        // }
      }
    } catch (error) {
      setLoggedInState(false);
      console.log("Error", error);
    }
  };
  const checkUser = async () => {
    try {
      await Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
        .then((res) => {
          console.log("auth current: ", { res });
          setLoggedInState(true);
        })
        .catch(
          (err) => (setLoggedInState(false), console.log("no user...", err))
        );
    } catch (error) {
      console.log({error})
    }

  };
  // isLoggedIn();
  return <>{loggedInState ? <BootmTabNavigator /> : <LoginStackNavigator />}</>;
};

export default Routes;

const styles = StyleSheet.create({});
