import React from 'react';
import { View, Text } from 'react-native';
import { Container } from 'native-base';
import SubHeader from '../SubHeader';
import containerStyle from './containerStyle';
import Logout from './Logout';
import AccountSettingsHeader from './AccountSettingsHeader';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useStore } from '../../store';

const SignedInWith = () => {
	const userSignedInWith = useStore((state) => state.userSignedInWith);
	const currentUser = useStore((state) => state.currentUser);
	const STORAGE_KEY = 'loggedInWith';
	const [loggedInWith, setLoggedInWith] = React.useState('');

	React.useEffect(() => {
		console.log({ currentUser });
	}, []);
	React.useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		try {
			const value = await AsyncStorage.getItem('@loggedInWith');
			if (value !== null) {
				setLoggedInWith(value);
				return value;
				console.log('Data successfully loaded', value);
			}
		} catch (e) {
			console.log('Failed to load the data from the storage');
		}
	};
	return (
		<>
			<AccountSettingsHeader screenName={'Signed in With'} />
			<Container style={containerStyle.container}>
				<SubHeader text={'Signed in With'} />
				<View style={{ borderBottomWidth: 1, paddingBottom: 10, marginTop: 5 }}>
					<Text style={containerStyle.text}>{loggedInWith}</Text>
				</View>
				<Logout />
			</Container>
		</>
	);
};

export default SignedInWith;
