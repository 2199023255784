import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import {
	Container,
	Content,
	Input,
	Item,
	Textarea,
	Button,
	Text,
} from 'native-base';
import AccountSettingsHeader from './AccountSettingsHeader';
import SubHeader from '../SubHeader';
import containerStyles from './containerStyle';
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { useStore } from '../../store';

const Support = () => {
	const navigation = useNavigation();
	const currentUserId = useStore((state) => state.currentUserId);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState(false);

	const createSupportTicket = async () => {
		const supportDetails = {
			author_id: currentUserId,
			email: email,
			message: message,
			name: name,
			status: 0,
		};
		try {
			await API.graphql({
				query: mutations.createSupport,
				variables: { input: supportDetails },
			}).then((res) => {
				console.log(res.data.createSupport.id, res.data.createSupport);
			});
		} catch (err) {
			console.log('err creating support Ticket...', err);
		}
	};

	const openTicket = async () => {
		await createSupportTicket();
		navigation.goBack();
	};

	return (
		<>
			<AccountSettingsHeader screenName={'Support'} />
			<Container style={containerStyles.container}>
				<SubHeader text={'Support Central'} />
				<Text style={containerStyles.text}>
				Have a question? Issue? Feedback? Please submit your name, email, and short message, and we'll do our best to help. Please allow a few business days for reply.
				</Text>
				<Content style={styles.formContainer}>
					<Item regular style={styles.formInput}>
						<Input
							placeholder='Name'
							onChangeText={(text) => {
								setName(text);
								setError(false);
								console.log(text);
							}}
						/>
					</Item>
					<Item regular style={styles.formInput}>
						<Input
							placeholder='Email'
							onChangeText={(text) => {
								setEmail(text);
								setError(false);
							}}
						/>
					</Item>
					<Textarea
						style={styles.formInput}
						rowSpan={10}
						bordered
						placeholder='Enter message here...'
						onChangeText={(text) => {
							setMessage(text);
							setError(false);
						}}
					/>
					<Button
						rounded
						dark
						onPress={() => {
							openTicket();
						}}
						style={{ alignSelf: 'center', marginVertical: 15 }}>
						<Text style={{ fontFamily: 'OPEN-SANS-SEMIBOLD' }}>Submit</Text>
					</Button>
				</Content>
			</Container>
		</>
	);
};

const styles = StyleSheet.create({
	formContainer: {
		marginVertical: 20,
	},
	formInput: {
		marginVertical: 10,
		shadowColor: '#000',
		borderRadius: 5,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.23,
		shadowRadius: 2.62,

		elevation: 4,
	},
});

export default Support;
