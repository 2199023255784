import React from 'react';
import { View, Text, Alert } from 'react-native';
import { Button } from 'native-base';
import { createStackNavigator } from '@react-navigation/stack';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import HomeScreen from '../../screens/HomeScreen/index';
import CircleScreen from '../../screens/CirclesScreen/index';
import CirclesCreation from '../../components/CirclesCreation';
// import GroupCircleConversation from '../../screens/GroupCircleConversation/index';
import GroupSettings from '../../screens/GroupSettings/index';
import SchedulingAndAutoMessages from '../../components/GroupSettingComponents/SchedulingAndAutoMessages';
import GroupMembers from '../../components/GroupSettingComponents/GroupMembers';
import GroupName from '../../components/GroupSettingComponents/GroupName';
import GroupNotificationSettings from '../../components/GroupSettingComponents/GroupNotificationSettings';
import CustomMessage from '../../components/GroupSettingComponents/CustomMessage';
import CustomFollowUp from '../../components/GroupSettingComponents/CustomFollowUp';
import Repeat from '../../components/GroupSettingComponents/Repeat';
import FollowUpMessage from '../../components/GroupSettingComponents/FollowUpMessage';

import NewIndex from '../../screens/GroupCircleConversation/newIndex';
import CircleCreationBuffer from '../../components/CircleCreationBuffer';

const sampleStack = createStackNavigator();
const ScampleHomeScreen = () => {
	return (
		<sampleStack.Navigator
			screenOptions={{
				headerShown: false,
			}}>
			<sampleStack.Screen name='HomeScreen' component={HomeScreen} />
			<sampleStack.Screen name='circleScreen' component={CircleScreen} />
			<sampleStack.Screen name='createGroup' component={CirclesCreation} />
			{/* <sampleStack.Screen
				name='groupCircle'
				component={GroupCircleConversation}
			/> */}
			<sampleStack.Screen
				name='circleCreationBuffer'
				component={CircleCreationBuffer}
			/>
			<sampleStack.Screen name='newConversation' component={NewIndex} />
			<sampleStack.Screen name='groupSettings' component={GroupSettings} />
			<sampleStack.Screen
				name='schudleAndAutoMessages'
				component={SchedulingAndAutoMessages}
				options={{
					headerShown: true,
					title: 'Schedule & Auto Generated Message',
				}}
			/>
			<sampleStack.Screen
				name='groupMembers'
				component={GroupMembers}
				options={{
					headerShown: true,
					title: 'Group Members',
					headerRight: () => (
						<Button
							transparent
							style={{ paddingRight: 20 }}
							onPress={() => {
								Alert.alert('Add Members');
							}}>
							<MaterialCommunityIcons name='plus' size={30} color='#1e1e1e' />
						</Button>
					),
				}}
			/>
			<sampleStack.Screen
				name='groupName'
				component={GroupName}
				options={{ headerShown: true, title: 'Group Name' }}
			/>
			<sampleStack.Screen
				name='groupNotificationSettings'
				component={GroupNotificationSettings}
				options={{ headerShown: true, title: 'Group Notification Settings' }}
			/>
			<sampleStack.Screen
				name='customMessage'
				component={CustomMessage}
				options={{ headerShown: true, title: 'Custom Message' }}
			/>
			<sampleStack.Screen
				name='customFollowUp'
				component={CustomFollowUp}
				options={{ headerShown: true, title: 'Custom Message' }}
			/>
			<sampleStack.Screen
				name='repeat'
				component={Repeat}
				options={{ headerShown: true, title: 'Repeat' }}
			/>
			<sampleStack.Screen
				name='followUpMessage'
				component={FollowUpMessage}
				options={{ headerShown: true, title: 'Follow-Up Message' }}
			/>
		</sampleStack.Navigator>
	);
};

export default ScampleHomeScreen;
