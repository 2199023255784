import React from 'react';
import { View } from 'react-native';
import { Button, Text } from 'native-base';
import { Auth } from 'aws-amplify';
import { useStore } from '../../store/index';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
const Logout = () => {
  const linkTo = useLinkTo();
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const setCurrentUserId = useStore((state) => state.setCurrentUserId);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const setExpoToken = useStore((state) => state.setExpoToken);
  const setCurrentConversationGroupId = useStore(
    (state) => state.setCurrentConversationGroupId
  );
  const setCreateGroupSelectedUsers = useStore(
    (state) => state.setCreateGroupSelectedUsers
  );
  const setSelectedUser = useStore((state) => state.setSelectedUser);
  const handleLogout = async () => {
    setCurrentConversationGroup({});
    setCurrentUserId('');
    setCurrentUser({});
    setSelectedUser([]);
    setCreateGroupSelectedUsers([]);
    setCurrentConversationGroupId('');
    setExpoToken('');
    linkTo('/HomeScreen');

    try {
      await Auth.signOut();
      await AsyncStorage.setItem('@LoggedIn', 'signedout');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };
  return (
    <View style={{ alignSelf: 'center', marginVertical: 30 }}>
      <Button rounded dark onPress={() => handleLogout()}>
        <Text style={{ fontFamily: 'OPEN-SANS-SEMIBOLD' }}>Logout</Text>
      </Button>
    </View>
  );
};

export default Logout;
