/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kkgjouxhnbfflbpjvq3okx53zq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7ebt5ccf7jbltd2ijfq55ghfam",
    "aws_cloud_logic_custom": [
        {
            "name": "newUserInvitationApi",
            "endpoint": "https://j2ja8jk2e1.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e620c0b1-24c7-406a-b3bb-5082667dd536",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_4DRY84Ygv",
    "aws_user_pools_web_client_id": "7lun65vlpmmq2ul1g3t96bvd44",
    "oauth": {
        "domain": "howyoubeapp-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://127.0.0.1:19000/--/",
        "redirectSignOut": "exp://127.0.0.1:19000/--/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "howyoube-mobile22815-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
