import React from 'react';
import { View, Image, StyleSheet } from 'react-native';
const Logo = () => {
	return (
		<View style={styles.imageView}>
			<Image
				source={require('../../assets/logo.png')}
				style={{ width: 187, height: 46 }}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	imageView: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 80,
	},
});

export default Logo;
