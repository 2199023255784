import React from 'react';
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

const PlusButton = ({ screen }) => {
	const navigation = useNavigation();
	return (
		<View style={{ flexBasis: '25%', marginVertical: 10 }}>
			<TouchableOpacity
				onPress={() => {
					navigation.navigate('CirclesScreen', {
						screen: 'createGroup',
						params: { from: 'PlusButton', screen: screen },
					});
				}}>
				<View>
					<View style={styles.plus}>
						<MaterialCommunityIcons name='plus' color='#fff' size={20} />
					</View>
				</View>
			</TouchableOpacity>
			<Text style={styles.userName}>Create Circle</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	plus: {
		backgroundColor: '#1e1e1e',
		alignItems: 'center',
		justifyContent: 'center',
		width: 80,
		borderRadius: 100,
		height: 80,
		marginRight: 6,
		alignSelf: 'center',
	},
	userName: {
		marginVertical: 5,
		textAlign: 'center',
		textTransform: 'capitalize',
	},
});

export default PlusButton;
