import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
	container: {
		minWidth:"50vw",
		maxWidth:1160,
		marginLeft:'auto',
		marginRight:'auto',
		backgroundColor: '#FFF',
	},
	flexContainer1: {
		display: 'flex',
	},
	flexContainer2: {
		display: 'flex',
		flex: 1,
	},
	flexContainer3: {
		display: 'flex',
	},
});
