import React from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
// import { AdMobBanner, setTestDeviceIDAsync } from 'expo-ads-admob';



export default function Advertisment() {
	return (
		<View>
			{/* <Text>Ad banner goes here.</Text> */}
			{/* <AdMobBanner
				bannerSize='fullBanner'
				// adUnitID={adUnitID}
				adUnitID={
					Platform.OS === 'web'
						&& 'ca-pub-7605431649620089'
					
				}
				servePersonalizedAds={true}
				onDidFailToReceiveAdWithError={(e) => console.log(e)}
			/> */}
			{/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<ins className="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-7605431649620089"
     data-ad-slot="6463027847"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script> */}
		</View>
	);
};

const styles = StyleSheet.create({
	advertisment: {
		flex: 1,
		height: 0
	},
});
