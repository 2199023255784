import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { Item, Input } from 'native-base';
import { Ionicons } from '@expo/vector-icons';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { useStore } from '../../store/index';

const MessageInput = () => {
  const [inputMessage, setInputMessage] = useState(
    vipTempleteMessageInput ? vipTempleteMessageInput : ''
  );

  const currentUserId = useStore((state) => state.currentUserId);
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const vipTempleteMessageInput = useStore(
    (state) => state.vipTempleteMessageInput
  );

  useEffect(() => {
    if (vipTempleteMessageInput.length > 0) {
      setInputMessage(vipTempleteMessageInput);
    }
  }, [vipTempleteMessageInput]);

  const handelEnterKeyPressed = ({ nativeEvent: { key: keyValue } }) => {
    if (keyValue === 'Enter') {
      handleInputMessage();
    }
  };
  const handleInputMessage = async () => {
    try {
      let res = await API.graphql({
        query: mutations.createMessage,
        variables: {
          input: {
            chat_group_id: currentConversationGroup.id,
            content: inputMessage,
            user_id: currentUserId,
          },
        },
      });
      setInputMessage('');
      let res2 = await API.graphql({
        query: mutations.updateChatGroup,
        variables: {
          input: {
            id: currentConversationGroup.id,
            last_message_id: res.data.createMessage.id,
          },
        },
      });
      // console.log('after writing into db.....', res);
    } catch (error) {
      console.log('Error while writing input message to db.....', error);
    }
  };

  return (
    <View>
      <Item rounded style={{ height: 40, backgroundColor: '#fff' }}>
        <Input
          placeholder="Message ...."
          autoCapitalize="none"
          autoCorrect={false}
          value={inputMessage}
          enablesReturnKeyAutomatically={true}
          multiline={true}
          onChangeText={(text) => setInputMessage(text)}
          onKeyPress={handelEnterKeyPressed}
        />
        <Pressable
          style={styles.arrowIconView}
          onPress={() => handleInputMessage()}
        >
          <Ionicons name="arrow-up" size={20} color={'#fff'} />
        </Pressable>
      </Item>
    </View>
  );
};
export default MessageInput;

const styles = StyleSheet.create({
  arrowIconView: {
    backgroundColor: '#FBB03B',
    marginHorizontal: 10,
    borderRadius: 100,
  },
});
