import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Item, Input, Text, View } from 'native-base';
import SubHeader from '../SubHeader';
import containerStyles from '../AccountSettingComponents/containerStyle';
import {
  Alert,
  Keyboard,
  Pressable,
  TouchableWithoutFeedback,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useStore } from './../../store/index';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';

const GroupName = () => {
  const navigation = useNavigation();
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const [groupName, setGroupName] = useState('');
  const [newGroupName, setNewGroupName] = useState('');

  useEffect(() => {
    setGroupName(currentConversationGroup.group_title);
    setNewGroupName(currentConversationGroup.group_title);
  }, []);

  useEffect(() => {
    setCurrentConversationGroup(
      { ...currentConversationGroup },
      (currentConversationGroup.group_title = newGroupName)
    );
  }, [newGroupName]);

  const updateGroupName = async () => {
    try {
      let res = await API.graphql({
        query: mutations.updateChatGroup,
        variables: {
          input: {
            id: currentConversationGroup.id,
            group_title: groupName,
          },
        },
      });
      setNewGroupName(() => res.data.updateChatGroup.group_title);
      navigation.navigate('groupSettings');
    } catch (error) {
      console.log('Error while writing data to db group Name....', error);
    }
  };

  // const updateGroupName = async () => {
  // 	// setCurrentConversationGroup(
  // 	// 	{ ...currentConversationGroup },
  // 	// 	(currentConversationGroup.group_title = groupName)
  // 	// );
  // 	try {
  // 		let res = await API.graphql({
  // 			query: mutations.updateChatGroup,
  // 			variables: {
  // 				input: {
  // 					id: currentConversationGroup.id,
  // 					group_title: groupName,
  // 				},
  // 			},
  // 		});
  // 		setNewGroupName(() => res.data.updateChatGroup.group_title);
  // 		// setCurrentConversationGroup(
  // 		// 	{ ...currentConversationGroup },
  // 		// 	(currentConversationGroup.group_title =
  // 		// 		res.data.updateChatGroup.group_title)
  // 		// );
  // 	} catch (error) {
  // 		console.log('Error while writing data to db group Name....', error);
  // 	}
  // };
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <Container
        style={[containerStyles.container, { backgroundColor: '#fff' }]}
      >
        <SubHeader text={'Circle Name'} />
        <Form>
          <Item style={{ borderBottomColor: '#1e1e1e' }}>
            <Input
              value={groupName}
              style={{ fontFamily: 'CRIMSON-TEXT' }}
              maxLength={50}
              onChangeText={(text) => setGroupName(text)}
            ></Input>
          </Item>
        </Form>
        <View style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Text style={{ fontFamily: 'CRIMSON-TEXT' }}>
            {groupName.length}/50
          </Text>
          <Text style={{ fontFamily: 'CRIMSON-TEXT' }}>Character Limit</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 30,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Pressable
            style={{ marginHorizontal: 10 }}
            onPress={() => {
              navigation.navigate('groupSettings');
            }}
          >
            <Text>Cancel</Text>
          </Pressable>
          <Button
            rounded
            dark
            style={{ marginHorizontal: 10 }}
            onPress={() => updateGroupName()}
          >
            <Text style={[{ fontFamily: 'OPEN-SANS-BOLD', color: '#fff' }]}>
              Save
            </Text>
          </Button>
        </View>
      </Container>
    </TouchableWithoutFeedback>
  );
};

export default GroupName;
