import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, FlatList } from 'react-native';
import { Thumbnail } from 'native-base';
import useForceUpdate from 'use-force-update';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { groupBy } from 'lodash';
import { onCreateMessage } from '../../../graphql/subscriptions';
import { useStore } from '../../store';

const Messages = ({ updateView }) => {
  const currentUserId = useStore((state) => state.currentUserId);
  const forceUpdate = useForceUpdate();
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupedResults, setGroupedResults] = useState({});

  useEffect(() => {
    getGroupMessages();
    return () => {
      setMessages([]);
    };
  }, [currentConversationGroup.id]);

  useEffect(() => {
    forceUpdate();
  }, [isLoading]);

  const getGroupMessagesQuery = `query Messages {
    messagesByChatGroup(chat_group_id: "${currentConversationGroup.id}", sortDirection: ASC, limit: 99999) {
		items {
			content
			createdAt
			id
			user {
				id
				first_name
				imageUri
			}
		}
	}
	}`;

  const getGroupMessages = async () => {
    try {
      let res = await API.graphql(graphqlOperation(getGroupMessagesQuery));
      // console.log('Messages.....', res);
      setMessages(res.data.messagesByChatGroup.items);
      let data = res.data.messagesByChatGroup.items;
      setIsLoading(false);
      setGroupedResults(
        groupBy(data, (data) => moment(data['createdAt']).startOf('day'))
      );
      // console.log(groupedResults);

      // console.log('Messages from message state.....', messages);
    } catch (error) {
      console.log('Erroor in getting groupMessges.....', error);
    } finally {
      updateView();
    }
  };

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateMessage)
    ).subscribe({
      next: (data) => {
        const newMessage = data.value.data.onCreateMessage;

        if (newMessage.chat_group_id !== currentConversationGroup.id) {
          console.log('Message is in another room!');
          return;
        }

        getGroupMessages();
      },
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <>
      {isLoading ? (
        <Text>Loading.......</Text>
      ) : (
        Object.keys(groupedResults).map((key, i) => {
          return (
            <View key={i}>
              <Text
                style={{
                  textAlign: 'center',
                  marginVertical: 20,
                  fontWeight: '700',
                }}
              >
                {moment(key).calendar(null, {
                  sameDay: '[Today]',
                  lastDay: '[Yesterday]',
                  lastWeek: 'ddd, MMM D',
                  sameElse: 'MMM D, YYYY',
                })}
              </Text>
              <FlatList
                // inverted={true}
                keyExtractor={(item) => item.id}
                data={groupedResults[key]}
                renderItem={({ item }) => {
                  if (item.user?.id === currentUserId) {
                    return (
                      <View
                        style={[
                          styles.currentUserView,
                          { justifyContent: 'flex-end' },
                        ]}
                      >
                        <View
                          style={[
                            styles.currentUserMessageView,
                            { backgroundColor: '#C10644' },
                          ]}
                        >
                          <Text
                            style={[styles.currentUserText, { color: '#fff' }]}
                          >
                            {item.content}
                          </Text>
                        </View>
                        <Thumbnail
                          small
                          source={{
                            uri: item.user?.imageUri
                              ? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.user?.imageUri}`
                              : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
                          }}
                        />
                      </View>
                    );
                  } else {
                    return (
                      <View
                        style={[
                          styles.currentUserView,
                          { justifyContent: 'flex-start' },
                        ]}
                      >
                        <Thumbnail
                          small
                          source={{
                            uri: item.user?.imageUri
                              ? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.user?.imageUri}`
                              : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
                          }}
                        />
                        <View>
                          <Text style={styles.nameText}>
                            {item.user?.first_name || 'Deleted User'}
                          </Text>
                          <View
                            style={[
                              styles.currentUserMessageView,
                              { backgroundColor: '#EBEBEB' },
                            ]}
                          >
                            <Text style={styles.currentUserText}>
                              {item.content}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            alignSelf: 'flex-end',
                            paddingHorizontal: 1,
                          }}
                        >
                          <Text style={{ fontSize: 10, fontWeight: '400' }}>
                            {moment(item.createdAt).format('hh:mm A')}
                          </Text>
                        </View>
                      </View>
                    );
                  }
                }}
              />
            </View>
          );
        })
      )}
    </>
  );
};

export default Messages;

const styles = StyleSheet.create({
  currentUserView: {
    flexDirection: 'row',
    // alignItems: 'center',
    marginVertical: 5,
  },
  currentUserMessageView: {
    marginVertical: 10,
    padding: 5,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#EBEBEB',
    marginHorizontal: 5,
  },
  currentUserText: {
    fontFamily: 'CRIMSON-TEXT',
    fontSize: 18,
  },
  nameText: {
    paddingLeft: 10,
    color: '#C10644',
  },
});
