import React, { useEffect, useState } from 'react';
import { View, ScrollView } from 'react-native';
import { Card, CardItem, Text, Body, Container } from 'native-base';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import MessageOfTheDay from '../../components/MessageOfTheDay';
import Advertisment from '../../components/Advertisment';
import Header from '../../components/AppHeader';
import ShareButton from '../../components/ShareButton';
import MessageData from '../../Resources/MessagesData';
import moment from 'moment';
import { API, graphqlOperation } from 'aws-amplify';
import { useStore } from '../../store/index';
import styles from './styels';
const MessageCard = ({ date, message, author }) => {
	return (
		<Card style={{ paddingHorizontal: 15 }}>
			<CardItem header>
				<Ionicons
					name='md-sunny'
					color={'#1e1e1e'}
					size={30}
					style={styles.cardHeaderIcon}
				/>
				<Text style={styles.cardHeaderText}>
					{moment.utc().diff(moment(parseInt(date)), 'days') === 1
						? 'Yesterday'
						: moment(parseInt(date)).format('dddd')}
				</Text>
				<Text style={styles.cardHeaderDate}>
					{moment(parseInt(date)).format('MMMM DD, YYYY')}
				</Text>
				<View style={styles.cardHeaderShareIcon}>
					<ShareButton color={'#000'} content={message} />
				</View>
			</CardItem>
			<CardItem>
				<Body>
					<Text style={styles.cardBodyText}>{message}</Text>
				</Body>
			</CardItem>
			<CardItem footer>
				<View style={styles.cardFooterView}>
					<View style={styles.sitationNameLeftDash}></View>
					<Text style={styles.sitationPersonName}>{author}</Text>
					<View style={styles.sitationNameRightDash}></View>
				</View>
			</CardItem>
		</Card>
	);
};
const TodayScreen = () => {
	const currentUser = useStore((state) => state.currentUser);
	let messageTypeOfUser = [];
	if (currentUser.qotd === 1) {
		messageTypeOfUser.push('Message_Of_The_Day');
	}
	if (currentUser.bible === 1) {
		messageTypeOfUser.push('Bible_Verse_Of_The_Day');
	}
	if (currentUser.french === 1) {
		messageTypeOfUser.push('French_Word_Of_The_Day');
	}
	if (currentUser.spanish === 1) {
		messageTypeOfUser.push('Spanish_Word_Of_The_Day');
	}
	const [dailyMessages, setDailyMessages] = useState([]);
	console.log('current user in today screen', currentUser);

	useEffect(() => {
		listDailyMessageFunction();
		return () => {
			console.log('cleanup');
		};
	}, []);

	const currentTime = moment.utc();

	const listDailyMessagesQuerry = `query listDailyMessages {
	listDailyMessages(filter: {scheduled_date: {lt: "${currentTime}"}}) {
    items {
      content
      author
      author_id
      message_type
      scheduled_date
      user {
		first_name
		last_name
      }
    }
  }
}`;

	const listDailyMessageFunction = async () => {
		try {
			let res = await API.graphql(graphqlOperation(listDailyMessagesQuerry));
			res.data.listDailyMessages.items.sort(
				(a, b) => b.scheduled_date - a.scheduled_date
			);
			setDailyMessages(res.data.listDailyMessages.items.slice(1));
		} catch (error) {
			console.log('error while getting daily messages,.....', error);
		}
	};
	console.log(messageTypeOfUser);
	return (
		<Container>
			<Header />
			<ScrollView
				style={{
					backgroundColor: '#fff',
					minWidth: '50vw',
					maxWidth: 1160,
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
				contentContainerStyle={{ flexGrow: 1 }}>
				{/* <View style={{}}>
					<Advertisment />
				</View> */}
				<View>
					<MessageOfTheDay />
				</View>
				<View style={{ flex: 1 }}>
					{dailyMessages.map((message, i) => {
						if (messageTypeOfUser.includes(message.message_type)) {
							return (
								<MessageCard
									key={i}
									date={message.scheduled_date}
									message={message.content}
									author={`${message.author}`}
								/>
							);
						}
					})}
				</View>
			</ScrollView>
		</Container>
	);
};

export default TodayScreen;
