import React, { useState, useEffect } from 'react';
import {
	View,
	Text,
	Alert,
	TouchableOpacity,
	StyleSheet,
	ScrollView,
	BackHandler,
} from 'react-native';
import { API, graphqlOperation } from 'aws-amplify';

import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { Button } from 'native-base';

import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import containerStyles from '../AccountSettingComponents/containerStyle';
import SubHeader from '../SubHeader';
import CreateCustomResponse from '../GroupSettingComponents/CreateCutomResponse';
import AutoGeneratedMessageScreenItem from '../GroupSettingComponents/AutoGeneratedMessageScreenItem';
import TimeMeridiemSettings from './TimeMeridiemSettings';
import { useStore } from '../../store';

const SchedulingAndAutoMessage = () => {
	const navigation = useNavigation();
	const tzOffset = new Date().getTimezoneOffset() / 60;
	const [selected, setSelected] = useState({
		goodmorning: false,
		howareyou: false,
		justchecking: false,
		goodevening: false,
	});
	const currentConversationGroup = useStore(
		(state) => state.currentConversationGroup
	);
	const autoMessage = useStore((state) => state.autoMessage);
	const currentUser = useStore((state) => state.currentUser);
	const currentUserId = currentUser.id;
	const messageScheduleTime = useStore((state) => state.messageScheduleTime);
	const messageScheduleTimeMin = useStore(
		(state) => state.messageScheduleTimeMin
	);
	const messageScheduleTimeMon = useStore(
		(state) => state.messageScheduleTimeMon
	);
	const messageScheduleTimeDay = useStore(
		(state) => state.messageScheduleTimeDay
	);
	const messageScheduleTimeDayWK = useStore(
		(state) => state.messageScheduleTimeDayWK
	);
	const messageScheduleTimeYr = useStore(
		(state) => state.messageScheduleTimeYr
	);
	const messageStream = useStore((state) => state.messageStream);
	const followMessage = useStore((state) => state.followMessage);
	const followMessageTime = useStore((state) => state.followMessageTime);

	const setFollowMessage = useStore((state) => state.setFollowMessage);
	const setMessageScheduleTimeDayWK = useStore(
		(state) => state.setMessageScheduleTimeDayWK
	);
	const setCustomAutoFollowMessageBool = useStore(
		(state) => state.setCustomAutoFollowMessageBool
	);
	const setCustomAutoFollowMessageText = useStore(
		(state) => state.setCustomAutoFollowMessageText
	);
	const setFollowMessageTime = useStore((state) => state.setFollowMessageTime);

	const setAutoMessage = useStore((state) => state.setAutoMessage);
	const setMessageStream = useStore((state) => state.setMessageStream);
	const customAutoMessageBool = useStore(
		(state) => state.customAutoMessageBool
	);
	const setCustomAutoMessageBool = useStore(
		(state) => state.setCustomAutoMessageBool
	);
	const customAutoMessageText = useStore(
		(state) => state.customAutoMessageText
	);
	const setCustomAutoMessageText = useStore(
		(state) => state.setCustomAutoMessageText
	);
	const setCustomAutoMessageTextEdited = useStore(
		(state) => state.setCustomAutoMessageTextEdited
	);
	const setCustomAutoFollowMessageTextEdited = useStore(
		(state) => state.setCustomAutoFollowMessageTextEdited
	);

	const [schedMessageScheduleTime, setSchedMessageScheduleTime] = useState('');
	const [
		schedMessageScheduleTimeMin,
		setSchedMessageScheduleTimeMin,
	] = useState('');
	const [
		schedMessageScheduleTimeDayWK,
		setSchedMessageScheduleTimeDayWK,
	] = useState('');
	const [schedFollowMessageTime, setSchedFollowMessageTime] = useState('');
	const [schedFollowMessageContent, setSchedFollowMessageContent] = useState(
		''
	);

	useEffect(() => {
		setCustomAutoMessageTextEdited(false);
		setCustomAutoFollowMessageTextEdited(false);
	}, []);

	useEffect(() => {
		getGroupSchedule();
	}, []);
	useEffect(() => {
		checkCustomMessage();
	}, [customAutoMessageBool]);
	// useEffect(() => {
	//   console.log({customAutoMessageBool})
	// },[customAutoMessageBool])

	const theGroupSchedule = `query getScheduledMessage {
    scheduledMessagesByChatGroup(sortDirection: DESC, chat_group_id: "${currentConversationGroup.id}") {
      items {
        chat_group_id
        content
        createdAt
        followMessageTime
        followup_message
        followup_schedule
        id
        messageScheduleTime
        messageScheduleTimeDay
        messageScheduleTimeDayWK
        messageScheduleTimeMin
        messageScheduleTimeMon
        messageScheduleTimeYr
        messageStream
        message_schedule
        updatedAt
        user_id
      }
    }
  }`;

	const getGroupSchedule = async () => {
		const thisGroupsSchedule = await API.graphql(
			graphqlOperation(theGroupSchedule)
		);
		console.log({ thisGroupsSchedule });
		setSchedMessageScheduleTime(
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.messageScheduleTime || ''
		);
		setSchedMessageScheduleTimeMin(
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.messageScheduleTimeMin || ''
		);
		let schedContent =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]?.content;
		radioButtonScheduled(
			schedContent?.replace(/\s+/g, '').toLowerCase().slice(0, 8),
			schedContent
		);
		setSchedMessageScheduleTimeDayWK(
			(schedMessageScheduleTimeDayWK) =>
				thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
					?.messageScheduleTimeDayWK || ''
		);
		setSchedFollowMessageTime(
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.followMessageTime || ''
		);
		setSchedFollowMessageContent(
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.followup_message || ''
		);

		let schedID =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]?.id;

		let schedCreatedAt =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.createdAt;

		let schedFollowup_schedule =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.followup_schedule;
		let schedMessageScheduleTimeDay =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.messageScheduleTimeDay;
		let schedMessageScheduleTimeMon =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.messageScheduleTimeMon;
		let schedMessageScheduleTimeYr =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.messageScheduleTimeYr;
		let schedMessageStream =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.messageScheduleTimeYr;
		let schedMessage_schedule =
			thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
				?.message_schedule;

		// console.log(
		//   thisGroupsSchedule.data.scheduledMessagesByChatGroup.items?.[0]
		//     ? "has schedule"
		//     : "doesn't have schedule"
		// );
	};

	const radioButtonScheduled = (value, value2) => {
		let data = { ...selected };
		Object.keys(data).forEach((item) => {
			item.includes(value) ? (data[item] = true) : (data[item] = false);
		});
		setSelected(data);
		if (
			!data.goodevening &&
			!data.goodmorning &&
			!data.howareyou &&
			!data.justchecking
		) {
			setCustomAutoMessageText(value2);
			console.log('set the first custom message text');
		} else {
			setCustomAutoMessageBool(false);
		}
	};

	const checkCustomMessage = () => {
		customAutoMessageBool
			? setSelected({
					goodmorning: false,
					howareyou: false,
					justchecking: false,
					goodevening: false,
			  })
			: console.log('checked for custom message:', { customAutoMessageBool });
	};

	// useEffect(() => {
	//   console.log({ currentConversationGroup });
	//   const unsubscribe = navigation.addListener("blur", () => {

	//   });

	//   return unsubscribe;
	// }, [navigation]);

	const radioButton = (value) => {
		let data = { ...selected };
		Object.keys(data).forEach((item) => {
			item === value ? (data[item] = true) : (data[item] = false);
		});
		setSelected(data);
		setCustomAutoMessageBool(false);
	};

	const scheduleNewMessage = async () => {
		let messageTimeOffset =  (t) => {
      		let mto =  Number(t) + tzOffset >= 24 ? Number(t) + tzOffset - 24 : Number(t) + tzOffset;
      		return mto;
    	};
		let daysLeftInMonth = () => {
			let d = new Date();
			return (
				new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate() - d.getDate()
			);
		};
		let messageDayOffset = (d) => {
			let daysLeft = daysLeftInMonth();
			if (Number(messageScheduleTime) + tzOffset < 24) {
				return d;
			} else if (Number(messageScheduleTime) + tzOffset >= 24 && daysLeft > 0) {
				return Number(d) + 1;
			} else {
				return 1;
			}
		};
		let messageSchedule = `cron(${messageScheduleTimeMin} ${messageTimeOffset(messageScheduleTime)} ${messageDayOffset(messageScheduleTimeDay)} ${messageScheduleTimeMon} ${messageScheduleTimeDayWK} ${messageScheduleTimeYr})`;
    	let followTime =Number(followMessageTime)+Number(messageTimeOffset(messageScheduleTime));
    	let followMessageSchedule = `cron(${messageScheduleTimeMin} ${followTime} ${messageDayOffset(messageScheduleTimeDay)} ${messageScheduleTimeMon} ${messageScheduleTimeDayWK} ${messageScheduleTimeYr})`;
		try {
			await fetch(
				`https://rsb0zjsuv1.execute-api.us-east-1.amazonaws.com/dev/messageScheduling?chatGroupId=${currentConversationGroup.id}&messageContent=${autoMessage}&userId=${currentUserId}&messageStream=${messageStream}&messageSchedule=${messageSchedule}&followup_message=${followMessage}&followup_schedule=${followMessageSchedule}`,
				{
					method: 'GET',
					redirect: 'follow',
				}
			).then((response) => {
				console.log('called the message api', JSON.stringify(response));
			});
		} catch (error) {
			console.log('error scheduling message:', error);
		}
	};

	const saveScheduledMessage = async () => {
		let savedMessageSchedule = `cron(${messageScheduleTimeMin} ${messageScheduleTime} ${messageScheduleTimeDay} ${messageScheduleTimeMon} ${messageScheduleTimeDayWK} ${messageScheduleTimeYr})`;
		let savedFollowTime =
			Number(followMessageTime) + Number(messageScheduleTime);
		let savedFollowMessageSchedule = `cron(${messageScheduleTimeMin} ${savedFollowTime} ${messageScheduleTimeDay} ${messageScheduleTimeMon} ${messageScheduleTimeDayWK} ${messageScheduleTimeYr})`;

		try {
			// let messageSchedule =`mutation createScheduledMessage {
			//    createScheduledMessage(input: {chat_group_id: "", content: "", followup_message: "", followup_schedule: "", message_schedule: "", user_id: "", followMessageTime: undefined, messageScheduleTime: "", messageScheduleTimeDay: "", messageScheduleTimeDayWK: "", messageScheduleTimeMin: "", messageScheduleTimeMon: "", messageScheduleTimeYr: "", messageStream: ""}) {
			//     id
			//   }
			// }`;
			await API.graphql({
				query: mutations.createScheduledMessage,
				variables: {
					input: {
						chat_group_id: currentConversationGroup.id,
						content: autoMessage,
						followup_message: followMessage,
						followup_schedule: savedFollowMessageSchedule,
						message_schedule: savedMessageSchedule,
						user_id: currentUserId,
						followMessageTime: followMessageTime,
						messageScheduleTime: messageScheduleTime,
						messageScheduleTimeDay: messageScheduleTimeDay,
						messageScheduleTimeDayWK: messageScheduleTimeDayWK,
						messageScheduleTimeMin: messageScheduleTimeMin,
						messageScheduleTimeMon: messageScheduleTimeMon,
						messageScheduleTimeYr: messageScheduleTimeYr,
						messageStream: messageStream,
					},
				},
			}).then((res) => {
				console.log('Scheduled Message Saved...', res);
			});
		} catch (e) {
			console.log('error saving scheduled message: ', e);
		}
	};

	const getDay = (d) => {
		let day;
		switch (d) {
			case 'SUN':
				day = 'Sunday';
				break;
			case 'MON':
				day = 'Monday';
				break;
			case 'TUE':
				day = 'Tuesday';
				break;
			case 'WED':
				day = 'Wednesday';
				break;
			case 'THU':
				day = 'Thursday';
				break;
			case 'FRI':
				day = 'Friday';
				break;
			case 'SAT':
				day = 'Saturday';
			case '*':
				day = 'Day';
		}
		return day;
	};

	useFocusEffect(
		React.useCallback(() => {
			const onBackPress = () => {
				console.log('onBackPress');
				resetStore();
				return false;
			};
			BackHandler.addEventListener('hardwareBackPress', onBackPress);
			return () =>
				BackHandler.removeEventListener('hardwareBackPress', onBackPress);
		}, [])
	);

	useEffect(
		() =>
			navigation.addListener('beforeRemove', (e) => {
				resetStore();
				return;
			}),
		[navigation]
	);

	const resetStore = () => {
		console.log('storereset');
		setFollowMessage('Hello???');
		setMessageScheduleTimeDayWK('?');
		setCustomAutoFollowMessageBool(false);
		setCustomAutoMessageText('');
		setCustomAutoFollowMessageText('');
		setFollowMessageTime(0);
	};

  // useEffect(() => {
  //   console.log({schedMessageScheduleTimeDayWK})
  // },[])

	return (
		<ScrollView style={[containerStyles.container, { backgroundColor: '#fff' }]}>
			{/* <ScrollView> */}
				<SubHeader text={'Auto-Generated Messages'} />

				<TouchableOpacity
					onPress={() => {
						radioButton('goodmorning');
						setAutoMessage('Good Morning');
						setMessageStream('1');
					}}>
					<AutoGeneratedMessageScreenItem
						radioIcon={
							selected.goodmorning ? 'radiobox-marked' : 'radiobox-blank'
						}
						itemName={'Good Morning!'}
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => {
						radioButton('howareyou');
						setAutoMessage('How are you doing today?');
						setMessageStream('2');
					}}>
					<AutoGeneratedMessageScreenItem
						radioIcon={
							selected.howareyou ? 'radiobox-marked' : 'radiobox-blank'
						}
						itemName={'How are you doing today?'}
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => {
						radioButton('justchecking');
						setAutoMessage('Just checking in!');
						setMessageStream('3');
					}}>
					<AutoGeneratedMessageScreenItem
						radioIcon={
							selected.justchecking ? 'radiobox-marked' : 'radiobox-blank'
						}
						itemName={'Just checking in!'}
					/>
				</TouchableOpacity>
				<TouchableOpacity
					onPress={() => {
						radioButton('goodevening');
						setAutoMessage('Good Evening');
						setMessageStream('4');
					}}>
					<AutoGeneratedMessageScreenItem
						radioIcon={
							selected.goodevening ? 'radiobox-marked' : 'radiobox-blank'
						}
						itemName={'Good Evening!'}
					/>
				</TouchableOpacity>

				<CreateCustomResponse />

				<SubHeader text={'Schedule Message Time'} />

				<TimeMeridiemSettings
					schedTime={schedMessageScheduleTime}
					schedTimeMin={schedMessageScheduleTimeMin}
				/>

				<TouchableOpacity
					onPress={() => {
						navigation.navigate('repeat', {
							schedDay: `${schedMessageScheduleTimeDayWK}`,
						});
					}}>
					<View style={styles.itemView}>
						<Ionicons
							name={'time-outline'}
							size={25}
							style={{ alignSelf: 'center', marginRight: 10 }}
						/>
						<View style={styles.itemNameAndIconView}>
							<Text
								style={[
									containerStyles.text,
									{ alignSelf: 'center', flex: 1 },
								]}>
								{'Repeat'}
							</Text>
							
							{!!schedMessageScheduleTimeDayWK && schedMessageScheduleTimeDayWK !== '?' && (
								<Text
									style={[
										containerStyles.text,
										{ alignSelf: 'center', flex: 1 },
									]}>
									{'Every ' + getDay(schedMessageScheduleTimeDayWK)}
								</Text>
							)}
							{messageScheduleTimeDayWK !== '?' && (
								<Text
									style={[
										containerStyles.text,
										{ alignSelf: 'center', flex: 1 },
									]}>
									{'Every ' + getDay(messageScheduleTimeDayWK)}
								</Text>
							)}
							<Ionicons name='chevron-forward-sharp' size={20} />
						</View>
					</View>
				</TouchableOpacity>

				<TouchableOpacity
					onPress={() => {
						navigation.navigate('followUpMessage', {
							schedFollow: `${schedFollowMessageTime}`,
							schedFollowContent: `${schedFollowMessageContent}`,
						});
					}}>
					<View style={styles.itemView}>
						<Ionicons
							name={'time-outline'}
							size={25}
							style={{ alignSelf: 'center', marginRight: 10 }}
						/>
						<View
							style={[styles.itemNameAndIconView, { alignItems: 'center' }]}>
							<Text style={[containerStyles.text, { flex: 1 }]}>
								{'Follow-up Message'}
							</Text>
							<View style={{ flex: 1, flexDirection: 'column' }}>
								{followMessageTime > 0 && (
									<Text style={[containerStyles.text, { flex: 1 }]}>
										{'After ' + followMessageTime + ' hour'}
									</Text>
								)}
								{Number(schedFollowMessageTime) > 0 &&
									followMessageTime < 1 && (
										<Text style={[containerStyles.text, { flex: 1 }]}>
											{'After ' + schedFollowMessageTime + ' hour'}
										</Text>
									)}
								{followMessage !== 'Hello???' && (
									<Text
										style={[containerStyles.text, { flex: 1 }]}
										numberOfLines={1}
										ellipsizeMode={'tail'}>
										{followMessage}
									</Text>
								)}
								{schedFollowMessageContent != '' && schedFollowMessageContent != 'Hello???' &&
									followMessage == 'Hello???' && (
										<Text
											style={[containerStyles.text, { flex: 1 }]}
											numberOfLines={1}
											ellipsizeMode={'tail'}>
											{schedFollowMessageContent}
										</Text>
									)}
							</View>
							<Ionicons name='chevron-forward-sharp' size={20} />
						</View>
					</View>
				</TouchableOpacity>
				<View style={styles.buttonView}>
					<Button
						rounded
						dark
						style={{
							marginVertical: 5,
							alignSelf: 'center',
							paddingHorizontal: 24,
						}}
						onPress={() => {
							scheduleNewMessage();
							saveScheduledMessage();
							navigation.goBack();
						}}>
						<Text
							style={{
								fontSize: 16,
								color: '#FFF',
								textTransform: 'uppercase',
							}}>
							Save
						</Text>
					</Button>
				</View>
				<View style={{ height: 300 }}></View>
			{/* </ScrollView> */}
		</ScrollView>
	);
};

const styles = StyleSheet.create({
	itemView: {
		display: 'flex',
		flexDirection: 'row',
		paddingVertical: 5,
	},
	itemNameAndIconView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomWidth: 1,
		borderBottomColor: '#D7D7D8',
		paddingVertical: 10,
	},
	buttonView: {
		flex: 1,
		marginVertical: 30,
	},
});

export default SchedulingAndAutoMessage;
