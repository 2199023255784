import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Pressable,
  Alert,
} from 'react-native';
import { Thumbnail } from 'native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStore } from '../store/index';
import GroupCircleContainerNew from '../components/GroupCircleContainerNew';
import CircleExitingModel from '../components/CircleExitingModal';

const GroupCircleNew = ({ group, screen }) => {
  const setCurrentConversationGroupId = useStore(
    (state) => state.setCurrentConversationGroupId
  );
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const setScreen = useStore((state) => state.setScreen);
  const [modalVisible, setModalVisible] = useState(false);
  const [chatGroupUserId, setChatGroupUserId] = useState('');
  const currentUserId = useStore((state) => state.currentUserId);

  const navigation = useNavigation();
  const handleLongPress = () => {
    if (
      currentUserId != group.chat_group.vip_user_id &&
      currentUserId != group.chat_group.created_by
    ) {
      let chatGroupUser = group.chat_group.chat_group_users.items.filter(
        (item) => item.user_id === currentUserId
      );
      console.log(chatGroupUser[0].id);
      setChatGroupUserId(chatGroupUser[0].id);
      setModalVisible(!modalVisible);
    }
  };

  return (
    <>
      <View
        style={{
          flexGrow: 0,
          flexBasis: '25%',
          marginVertical: 10,
        }}
      >
        <Pressable
          onLongPress={() => handleLongPress()}
          onPress={() => {
            setCurrentConversationGroupId(group.chat_group.id);
            setCurrentConversationGroup(group.chat_group);
            setScreen(screen);
            navigation.navigate('CirclesScreen', { screen: 'newConversation' });
          }}
        >
          <GroupCircleContainerNew group={group.chat_group} />
        </Pressable>
        <Text
          style={{
            textAlign: 'center',
            marginVertical: 5,
            textTransform: 'capitalize',
          }}
        >
          {group.chat_group.group_title}
        </Text>
        {modalVisible && (
          <CircleExitingModel
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            type="groupCircle"
            chatGroupUserId={chatGroupUserId}
          />
        )}
      </View>
    </>
  );
};

export default GroupCircleNew;
