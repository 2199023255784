/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      first_name
      last_name
      email
      phone_number
      imageUri
      status
      pause_notifications
      qotd
      bible
      french
      spanish
      active_account
      role
      chat_group_user {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      first_name
      last_name
      email
      phone_number
      imageUri
      status
      pause_notifications
      qotd
      bible
      french
      spanish
      active_account
      role
      chat_group_user {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      first_name
      last_name
      email
      phone_number
      imageUri
      status
      pause_notifications
      qotd
      bible
      french
      spanish
      active_account
      role
      chat_group_user {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatGroupUser = /* GraphQL */ `
  subscription OnCreateChatGroupUser {
    onCreateChatGroupUser {
      id
      user_id
      is_vip
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatGroupUser = /* GraphQL */ `
  subscription OnUpdateChatGroupUser {
    onUpdateChatGroupUser {
      id
      user_id
      is_vip
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatGroupUser = /* GraphQL */ `
  subscription OnDeleteChatGroupUser {
    onDeleteChatGroupUser {
      id
      user_id
      is_vip
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatGroup = /* GraphQL */ `
  subscription OnCreateChatGroup {
    onCreateChatGroup {
      id
      group_title
      pause_notifications
      created_by
      vip_user_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group_users {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      last_message_id
      last_message {
        id
        createdAt
        content
        user_id
        chat_group_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group {
          id
          group_title
          pause_notifications
          created_by
          vip_user_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group_users {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          messages {
            items {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            nextToken
          }
          last_message_id
          last_message {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatGroup = /* GraphQL */ `
  subscription OnUpdateChatGroup {
    onUpdateChatGroup {
      id
      group_title
      pause_notifications
      created_by
      vip_user_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group_users {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      last_message_id
      last_message {
        id
        createdAt
        content
        user_id
        chat_group_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group {
          id
          group_title
          pause_notifications
          created_by
          vip_user_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group_users {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          messages {
            items {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            nextToken
          }
          last_message_id
          last_message {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatGroup = /* GraphQL */ `
  subscription OnDeleteChatGroup {
    onDeleteChatGroup {
      id
      group_title
      pause_notifications
      created_by
      vip_user_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group_users {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      last_message_id
      last_message {
        id
        createdAt
        content
        user_id
        chat_group_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group {
          id
          group_title
          pause_notifications
          created_by
          vip_user_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group_users {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          messages {
            items {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            nextToken
          }
          last_message_id
          last_message {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      createdAt
      content
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      createdAt
      content
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      createdAt
      content
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateScheduledMessage = /* GraphQL */ `
  subscription OnCreateScheduledMessage {
    onCreateScheduledMessage {
      id
      createdAt
      content
      message_schedule
      messageScheduleTime
      messageScheduleTimeMin
      messageScheduleTimeMon
      messageScheduleTimeDay
      messageScheduleTimeDayWK
      messageScheduleTimeYr
      messageStream
      followup_message
      followup_schedule
      followMessageTime
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateScheduledMessage = /* GraphQL */ `
  subscription OnUpdateScheduledMessage {
    onUpdateScheduledMessage {
      id
      createdAt
      content
      message_schedule
      messageScheduleTime
      messageScheduleTimeMin
      messageScheduleTimeMon
      messageScheduleTimeDay
      messageScheduleTimeDayWK
      messageScheduleTimeYr
      messageStream
      followup_message
      followup_schedule
      followMessageTime
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteScheduledMessage = /* GraphQL */ `
  subscription OnDeleteScheduledMessage {
    onDeleteScheduledMessage {
      id
      createdAt
      content
      message_schedule
      messageScheduleTime
      messageScheduleTimeMin
      messageScheduleTimeMon
      messageScheduleTimeDay
      messageScheduleTimeDayWK
      messageScheduleTimeYr
      messageStream
      followup_message
      followup_schedule
      followMessageTime
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateDailyMessage = /* GraphQL */ `
  subscription OnCreateDailyMessage {
    onCreateDailyMessage {
      id
      createdAt
      scheduled_date
      content
      author
      author_id
      message_type
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateDailyMessage = /* GraphQL */ `
  subscription OnUpdateDailyMessage {
    onUpdateDailyMessage {
      id
      createdAt
      scheduled_date
      content
      author
      author_id
      message_type
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteDailyMessage = /* GraphQL */ `
  subscription OnDeleteDailyMessage {
    onDeleteDailyMessage {
      id
      createdAt
      scheduled_date
      content
      author
      author_id
      message_type
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateFaq = /* GraphQL */ `
  subscription OnCreateFaq {
    onCreateFAQ {
      id
      title
      content
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFaq = /* GraphQL */ `
  subscription OnUpdateFaq {
    onUpdateFAQ {
      id
      title
      content
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFaq = /* GraphQL */ `
  subscription OnDeleteFaq {
    onDeleteFAQ {
      id
      title
      content
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSupport = /* GraphQL */ `
  subscription OnCreateSupport {
    onCreateSupport {
      id
      name
      email
      message
      status
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSupport = /* GraphQL */ `
  subscription OnUpdateSupport {
    onUpdateSupport {
      id
      name
      email
      message
      status
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSupport = /* GraphQL */ `
  subscription OnDeleteSupport {
    onDeleteSupport {
      id
      name
      email
      message
      status
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
