import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { API, graphqlOperation } from 'aws-amplify';

import CirclesHeader from './CirclesHeader';
import PlusButton from './PlusButton';

import UserCircleNew from './UserCircleNew';

import GroupCircleNew from './GroupCircleNew';
import { useStore } from '../store';
import * as queries from '../../graphql/queries';
import { useNavigation } from '@react-navigation/native';
import users from '../Resources/DumyUsersData';

const Circles = ({ screen, currentUserGroups }) => {
  useEffect(() => {}, [currentUserGroups]);

  return (
    <View style={styles.circlesContainer}>
      <View style={styles.circlesInnerContainer}>
        <PlusButton screen={screen} />
        {currentUserGroups.map((item, i) => {
          if (screen === 'Home') {
            if (i < 7) {
              const groupSize = item.chat_group.chat_group_users.items.length;
              return groupSize > 2 ? (
                <GroupCircleNew
                  key={item.chat_group.id}
                  group={item}
                  screen={screen}
                />
              ) : (
                <UserCircleNew
                  key={item.chat_group.id}
                  user={item}
                  screen={screen}
                />
              );
            }
          } else {
            const groupSize = item.chat_group.chat_group_users.items.length;
            return groupSize > 2 ? (
              <GroupCircleNew
                key={item.chat_group.id}
                group={item}
                screen={screen}
              />
            ) : (
              <UserCircleNew
                key={item.chat_group.id}
                user={item}
                screen={screen}
              />
            );
          }
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  circlesContainer: {
    flex: 1,
    paddingHorizontal: 14,
    minWidth: '50vw',
    maxWidth: 1160,
    minHeight: 285,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  circlesInnerContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
});
export default Circles;
