import React, { useState, useEffect} from "react";
import { TouchableOpacity, View, Text, StyleSheet, Alert } from "react-native";
import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import containerStyles from "../AccountSettingComponents/containerStyle";
import { useStore } from "../../store";

const FollowUpMessageTime = ({ time, radioIcon }) => {
  return (
    <View style={styles.itemView}>
      <MaterialCommunityIcons
        name={"clock-outline"}
        size={25}
        style={{ alignSelf: "center", marginRight: 10 }}
      />
      <View style={styles.itemNameAndIconView}>
        <Text style={[containerStyles.text, { alignSelf: "center" }]}>
          {`After ${time} Hour`}{time > 1 && "s"}
        </Text>
        <MaterialCommunityIcons name={radioIcon} size={20} />
      </View>
    </View>
  );
};

const Time = ({schedFollow}) => {
  const [selectedHour, setSelectedHour] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
  });

  const followMessageTime = useStore((state) => state.followMessageTime);
  const setFollowMessageTime = useStore((state) => state.setFollowMessageTime);

  useEffect(() => {
    if (Number(schedFollow) > 0 && followMessageTime < 1) {
      radioButtonScheduled(schedFollow);
    }
    if (followMessageTime > 0) {
      radioButtonSelected(followMessageTime);
    }
  }, [followMessageTime]);
  const radioButtonScheduled = (value) => {
    let data = { ...selectedHour };
    let schedTime;
      switch (value) {
        case "1":
          schedTime = "one";
          break
        case "2":
          schedTime = "two";
          break
        case "3":
          schedTime = "three";
          break
        case "4":
          schedTime = "four";
          break
        case "5":
          schedTime = "five";
          break
      }          
      
    Object.keys(data).forEach((item) => {
      item === schedTime ? (data[item] = true) : (data[item] = false);
    });
    setSelectedHour(data);
  };
  const radioButtonSelected = (value) => {
    let data = { ...selectedHour };
    let followMessageTime;
      switch (value) {
        case 1:
          followMessageTime = "one";
          break
        case 2:
          followMessageTime = "two";
          break
        case 3:
          followMessageTime = "three";
          break
        case 4:
          followMessageTime = "four";
          break
        case 5:
          followMessageTime = "five";
          break
      }          
      
    Object.keys(data).forEach((item) => {
      item === followMessageTime ? (data[item] = true) : (data[item] = false);
    });
    setSelectedHour(data);
  };
  return (
    <>
      <TouchableOpacity
        onPress={() => {
          let data = { ...selectedHour };
          data.one = true;
          data.two = false;
          data.three = false;
          data.four = false;
          data.five = false;
		  setSelectedHour(data);
		  setFollowMessageTime(1);
        }}
      >
        <FollowUpMessageTime
          time={1}
          radioIcon={selectedHour.one ? "radiobox-marked" : "radiobox-blank"}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          let data = { ...selectedHour };
          data.one = false;
          data.two = true;
          data.three = false;
          data.four = false;
          data.five = false;
          setSelectedHour(data);
		  setFollowMessageTime(2);
        }}
      >
        <FollowUpMessageTime
          time={2}
          radioIcon={selectedHour.two ? "radiobox-marked" : "radiobox-blank"}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          let data = { ...selectedHour };
          data.one = false;
          data.two = false;
          data.three = true;
          data.four = false;
          data.five = false;
          setSelectedHour(data);
		  setFollowMessageTime(3);
        }}
      >
        <FollowUpMessageTime
          time={3}
          radioIcon={selectedHour.three ? "radiobox-marked" : "radiobox-blank"}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          let data = { ...selectedHour };
          data.one = false;
          data.two = false;
          data.three = false;
          data.four = true;
          data.five = false;
          setSelectedHour(data);
		  setFollowMessageTime(4);
        }}
      >
        <FollowUpMessageTime
          time={4}
          radioIcon={selectedHour.four ? "radiobox-marked" : "radiobox-blank"}
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          let data = { ...selectedHour };
          data.one = false;
          data.two = false;
          data.three = false;
          data.four = false;
          data.five = true;
          setSelectedHour(data);
		  setFollowMessageTime(5);
        }}
      >
        <FollowUpMessageTime
          time={5}
          radioIcon={selectedHour.five ? "radiobox-marked" : "radiobox-blank"}
        />
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  itemView: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 5,
  },
  itemNameAndIconView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#D7D7D8",
    paddingVertical: 10,
  },
});

export default Time;
