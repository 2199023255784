import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
	cardHeaderIcon: {
		marginRight: 10,
	},
	cardHeaderText: {
		fontFamily: 'OPEN-SANS-BOLD',
		marginRight: 10,
	},
	cardHeaderDate: { fontFamily: 'CRIMSON-TEXT' },
	cardHeaderShareIcon: { flex: 1, alignItems: 'flex-end' },
	cardBodyText: { fontFamily: 'CRIMSON-TEXT' },
	cardFooterView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		marginVertical: 10,
	},
	sitationNameLeftDash: {
		width: 50,
		height: 2,
		backgroundColor: '#d7d7d8',
		marginRight: 5,
		alignSelf: 'center',
	},
	sitationPersonName: { fontFamily: 'CRIMSON-TEXT-BOLD' },
	sitationNameRightDash: {
		width: 50,
		height: 2,
		backgroundColor: '#d7d7d8',
		marginLeft: 5,
		alignSelf: 'center',
	},
});

export default styles;
