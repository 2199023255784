import React from 'react';
import { View, Text, Alert, StyleSheet } from 'react-native';

import { MaterialCommunityIcons } from '@expo/vector-icons';

import containerStyles from '../AccountSettingComponents/containerStyle';

const AutoGeneratedMessageScreenItem = ({ radioIcon, itemName }) => {
	return (
		<View style={styles.itemView}>
			<MaterialCommunityIcons
				name={'rename-box'}
				size={25}
				style={{ alignSelf: 'center', marginRight: 10 }}
			/>
			<View style={styles.itemNameAndIconView}>
				<Text style={[containerStyles.text, { alignSelf: 'center' }]}>
					{itemName}
				</Text>
				<MaterialCommunityIcons
					name={radioIcon}
					size={20}
					style={{ alignSelf: 'center' }}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	itemView: {
		display: 'flex',
		flexDirection: 'row',
		paddingVertical: 5,
	},
	itemNameAndIconView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottomWidth: 1,
		borderBottomColor: '#D7D7D8',
		paddingVertical: 10,
	},
});

export default AutoGeneratedMessageScreenItem;
