import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import {
  List,
  ListItem,
  Left,
  Body,
  Right,
  Thumbnail,
  Text,
  Button,
  Item,
  Input,
} from 'native-base';
import { API, graphqlOperation } from 'aws-amplify';
import { useStore } from '../store';
import { Dialog, Portal } from 'react-native-paper';
import { EvilIcons, Ionicons } from '@expo/vector-icons';
import { useDebouncedEffect } from '../lib/hooks/useDebouncedEffect';

const UserListItem = ({ item }) => {
  const [userChecked, setUserChecked] = useState(false);
  const createGroupSelectedUsers = useStore(
    (state) => state.createGroupSelectedUsers
  );
  const setCreateGroupSelectedUsers = useStore(
    (state) => state.setCreateGroupSelectedUsers
  );

  useEffect(() => {
    createGroupSelectedUsers.forEach((user) => {
      if (user.id === item.id) {
        setUserChecked(true);
      }
    });
  }, [createGroupSelectedUsers.length > 0]);

  const addSelectedUser = (e) => {
    let user = e;
    setCreateGroupSelectedUsers([...createGroupSelectedUsers, user]);
  };

  const removeSelectedUser = (e) => {
    let user = e;
    setCreateGroupSelectedUsers(
      createGroupSelectedUsers.filter((e) => e.id !== user.id)
    );
  };

  return (
    <>
      <ListItem
        avatar
        onPress={() => {
          setUserChecked((checked) => !checked);
          {
            !userChecked ? addSelectedUser(item) : removeSelectedUser(item);
          }
        }}
      >
        <Left>
          <Thumbnail
            source={{
              uri: item.imageUri
                ? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.imageUri}`
                : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
            }}
            small
          />
        </Left>
        <Body>
          <Text style={{ marginLeft: 15 }}>
            {item.first_name} {item.last_name}
          </Text>
        </Body>
        <Right>
          <Ionicons
            name={
              !userChecked
                ? 'md-radio-button-off-outline'
                : 'md-radio-button-on-outline'
            }
            size={19}
          />
          {/* <CheckBox
						checked={userChecked}
						color='#1e1e1e'
						style={{ marginRight: 15 }}
						onPress={() => {
							setUserChecked((checked) => !checked);
							{
								!userChecked ? addSelectedUser(item) : removeSelectedUser(item);
							}
						}}
					/> */}
        </Right>
      </ListItem>
    </>
  );
};

const SelectedUserListItem = ({ user }) => {
  return (
    <>
      <ListItem avatar>
        <Body style={{ alignItems: 'center' }}>
          <Thumbnail
            source={{
              uri: user.imageUri
                ? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${user.imageUri}`
                : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
            }}
            medium
          />
          <Text>{user.first_name}</Text>
        </Body>
      </ListItem>
    </>
  );
};

/**
 * @param {string} - search term
 * @param {string} - currentUserId
 * @returns {string} - graphql query
 */
const makeUserQuery = (searchText, currentUserId) => {
  return `
		query listUsers {
			listUsers(filter: {
					user_search: { contains: "${searchText}" },
				and: { id: 
					{ ne: "${currentUserId}"}
					}
				}) {
				items {
					id
					imageUri
					first_name
					last_name
				}
			}
		}`;
};

const UserList = ({ searchText, handleSearchText, from }) => {
  const [userList, setUserList] = useState([]);
  const [searchUserList, setSearchUserList] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password] = useState(Math.random().toString(36).slice(-8));
  const currentUserId = useStore((state) => state.currentUserId);

  const createGroupSelectedUsers = useStore(
    (state) => state.createGroupSelectedUsers
  );

  const setCreateGroupSelectedUsers = useStore(
    (state) => state.setCreateGroupSelectedUsers
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );

  useEffect(() => {
    getUsers();
  }, []);

  useDebouncedEffect(() => searchUser(), 500, [searchText]);

  useEffect(() => {
    setCreateGroupSelectedUsers([]);
  }, []);

  const searchUser = async () => {
    const clean_text = searchText.toLowerCase().replaceAll(/\W/g, '');

    if (clean_text !== '') {
      try {
        const result = await API.graphql(
          graphqlOperation(makeUserQuery(clean_text, currentUserId))
        );

        setSearchUserList(result.data.listUsers.items);
      } catch (error) {
        console.error('Error grabing users data from search field', error);
      }
    }
  };

  const getUsers = async () => {
    try {
      let allUsers = await API.graphql(
        graphqlOperation(`query listChatGroupUsers {
					listChatGroupUsers(filter: {user_id: {eq: "${currentUserId}"}}) {
						items {
							chat_group {
								chat_group_users {
									items {
										user {
											id
											first_name
											imageUri
											last_name
											}
										}
									}
								}
							}
						}
					}`)
      );

      let temp = [];
      let uniquieUserList = [];
      allUsers.data.listChatGroupUsers.items.forEach((chatGroup) => {
        chatGroup.chat_group.chat_group_users.items.forEach((item) => {
          if (item.user.id !== currentUserId) {
            temp.push(item.user);
          }
        });
      });

      temp.forEach(function (item) {
        let i = uniquieUserList.findIndex((x) => x.id == item.id);
        if (i <= -1) {
          uniquieUserList.push({ ...item });
        }
      });
      if (from === 'PlusButton') {
        setUserList(uniquieUserList);
      } else if (from === 'GroupMembers') {
        let chatGroupUsers = [];
        currentConversationGroup.chat_group_users.items.forEach((item) => {
          chatGroupUsers.push(item.user.id);
        });
        let usersNotInGroup = uniquieUserList.filter(
          (item) => !chatGroupUsers.includes(item.id)
        );
        setUserList(usersNotInGroup);
      }
    } catch (error) {
      console.error('List users query....', error);
    }
  };

  const createNewUser = async (firstName, lastName, email) => {
    setIsLoading(!isLoading);
    let data = {
      firstName,
      lastName,
      email,
      password: password,
    };
    try {
      await fetch(
        `https://j2ja8jk2e1.execute-api.us-east-1.amazonaws.com/dev/newuser?firstName=${firstName}&lastName=${lastName}&email=${email}&password=${password}/`,
        {
          method: 'POST',
          body: JSON.stringify(data),
        }
      ).then((res) => {
        API.graphql(
          graphqlOperation(`query listUsers {
					listUsers(filter: {email: {eq: "${email}"}}) {
    					items {
      						id
    						}
  						}
					}`)
        ).then((response) => {
          let user = {
            id: response.data.listUsers.items[0].id,
            imageUri: '',
            first_name: firstName,
            last_name: lastName,
          };
          setCreateGroupSelectedUsers([...createGroupSelectedUsers, user]);
          setModalVisible(!modalVisible);
          setIsLoading(!isLoading);
          handleSearchText();
        });
      });
    } catch (error) {
      console.error('error signing up:', error);
    }
  };

  return (
    <>
      {createGroupSelectedUsers.length >= 1 ? (
        <List
          horizontal={true}
          itemDivider={false}
          dataArray={createGroupSelectedUsers}
          keyExtractor={(item) => item.id}
          renderRow={(user, i) => <SelectedUserListItem user={user} />}
        />
      ) : (
        <></>
      )}
      {searchText ? (
        searchUserList.length !== 0 ? (
          <List
            dataArray={searchUserList}
            keyExtractor={(item) => item.id}
            renderRow={(user, i) => <UserListItem item={user} />}
          />
        ) : (
          <View
            style={{
              flex: 1,
              alignItems: 'center',
            }}
          >
            <Text style={{ marginVertical: 10 }}>No results found</Text>
            <Button
              rounded
              dark
              onPress={() => {
                setModalVisible(true);
              }}
              style={{ alignSelf: 'center' }}
            >
              <Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>
                Send Invitation
              </Text>
            </Button>
          </View>
        )
      ) : (
        <List
          style={{ marginVertical: 15 }}
          dataArray={userList}
          keyExtractor={(item) => item.id}
          renderRow={(user, i) => <UserListItem item={user} />}
        />
      )}
      <Portal>
        <Dialog
          visible={modalVisible}
          style={{
            backgroundColor: '#fff',
            width: 350,
            height: 600,
            alignSelf: 'center',
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Dialog.Title style={{ color: '#1e1e1e' }}>
              Send An Invitation
            </Dialog.Title>
            <EvilIcons
              name="close"
              size={25}
              color="#000"
              onPress={() => setModalVisible(!modalVisible)}
              style={{ padding: 15 }}
            />
          </View>

          {!isLoading ? (
            <>
              <Dialog.Content>
                <Item regular style={styles.formInput}>
                  <Input
                    placeholder="First Name"
                    onChangeText={(text) => setFirstName(text)}
                  />
                </Item>
                <Item regular style={styles.formInput}>
                  <Input
                    placeholder="Last Name"
                    onChangeText={(text) => setLastName(text)}
                  />
                </Item>
                <Item regular style={styles.formInput}>
                  <Input
                    placeholder="Email"
                    onChangeText={(text) => setEmail(text)}
                  />
                </Item>
              </Dialog.Content>
              <Dialog.Actions>
                <View style={styles.modalButtonsView}>
                  <TouchableOpacity
                    onPress={() => setModalVisible(!modalVisible)}
                  >
                    <Text>Cancel</Text>
                  </TouchableOpacity>
                  <Button
                    rounded
                    dark
                    onPress={() => {
                      createNewUser(firstName, lastName, email);
                    }}
                  >
                    <Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>Send</Text>
                  </Button>
                </View>
              </Dialog.Actions>
            </>
          ) : (
            <Dialog.Content>
              <View>
                <Text>Loading.....</Text>
              </View>
            </Dialog.Content>
          )}
        </Dialog>
      </Portal>
    </>
  );
};

export default UserList;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontFamily: 'OPEN-SANS-BOLD',
  },
  modalButtonsView: {
    flexDirection: 'row',
    marginVertical: 15,
    alignItems: 'center',
    width: 300,
    justifyContent: 'space-around',
  },
  formInput: {
    marginVertical: 10,
    shadowColor: '#000',
    borderRadius: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4,
  },
});
