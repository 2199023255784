import { StyleSheet, Dimensions } from 'react-native';
const styles = StyleSheet.create({
	container: {
		paddingTop: 70,
		// flex: 1,
		height: Dimensions.get('window').height,
		// width: Dimensions.get('window').width,
		// paddingHorizontal: 20,
		minWidth: '50vw',
		maxWidth: 1160,
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#FFF',
	},
	input: {
		marginVertical: 10,
		borderRadius: 5.0,
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 12,
		},
		shadowOpacity: 0.58,
		shadowRadius: 16.0,

		elevation: 0,
	},
	button: {
		marginVertical: 10,
		padding: 7,
		alignSelf: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,

		elevation: 5,
	},
	buttonText: {
		fontFamily: 'OPEN-SANS-BOLD',
	},
	text: {
		fontFamily: 'OPEN-SANS',
		fontWeight: '400',
		fontSize: 13,
		textAlign: 'center',
	},
	cardFooterView: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 10,
	},
	sitationNameLeftDash: {
		width: 25,
		height: 2,
		backgroundColor: '#000',
		marginRight: 5,
		alignSelf: 'center',
	},
	sitationNameRightDash: {
		width: 25,
		height: 2,
		backgroundColor: '#000',
		marginLeft: 5,
		alignSelf: 'center',
	},
	sitationPersonName: { fontFamily: 'OPEN-SANS-SEMIBOLD' },
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22,
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	modalButtonsView: {
		flexDirection: 'row',
		marginVertical: 15,
		alignItems: 'center',
		width: 300,
		justifyContent: 'space-around',
	},
});

export default styles;
