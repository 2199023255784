import React, { useEffect, useState } from 'react';
import { Alert, View } from 'react-native';
import {
	Container,
	List,
	ListItem,
	Left,
	Body,
	Right,
	Thumbnail,
	Text,
	Spinner,
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useStore } from '../../store/index';
import SubHeader from '../SubHeader';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import useForceUpdate from 'use-force-update';
import styles from '../AccountSettingComponents/containerStyle';

const GroupMembers = () => {
	const currentConversationGroup = useStore(
		(state) => state.currentConversationGroup
	);
	const setCurrentConversationGroup = useStore(
		(state) => state.setCurrentConversationGroup
	);
	const [isLoading, setIsLoading] = useState(true);

	const handleOpressStar = async (id) => {
		console.log('called');
		setCurrentConversationGroup(
			{ ...currentConversationGroup },
			(currentConversationGroup.vip_user_id = id)
		);
		try {
			let res = await API.graphql({
				query: mutations.updateChatGroup,
				variables: {
					input: {
						id: currentConversationGroup.id,
						vip_user_id: id,
					},
				},
			});
			setIsLoading(true);
			setCurrentConversationGroup(
				{ ...currentConversationGroup },
				(currentConversationGroup.vip_user_id =
					res.data.updateChatGroup.vip_user_id)
			);
			// console.log('Changed Vip user of the group.....', res);
		} catch (error) {
			console.log('Could not change the vip user of the group......', error);
		}
	};

	return (
		<>
			{isLoading ? (
				<Container
					style={[
						styles.container,
						{
							maxWidth: 1160,
							marginLeft: 'auto',
							marginRight: 'auto',
							minWidth: '50vw',
						},
					]}>
					{currentConversationGroup.vip_user_id && (
						<>
							<SubHeader text={'VIP Member'} />
							{currentConversationGroup.chat_group_users.items.map((item) => {
								if (item.user.id === currentConversationGroup.vip_user_id) {
									return (
										<ListItem
											avatar
											key={item.user.id}
											onPress={() => {
												handleOpressStar(item.user.id),
													setIsLoading(!isLoading);
											}}>
											<Left>
												<Thumbnail
													small
													source={{
														uri: item.user.imageUri
															? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.user.imageUri}`
															: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
													}}
												/>
											</Left>
											<Body style={{ paddingLeft: 28 }}>
												<Text>{`${item.user.first_name} ${item.user.last_name}`}</Text>
											</Body>
											<Right>
												<MaterialCommunityIcons name={'star'} size={19} />
											</Right>
										</ListItem>
									);
								}
							})}
						</>
					)}
					<SubHeader text={'Circle Members'} />
					<List>
						{currentConversationGroup.chat_group_users.items.map((item) => {
							if (item.user.id != currentConversationGroup.vip_user_id) {
								return (
									<ListItem
										avatar
										key={item.user.id}
										onPress={() => {
											handleOpressStar(item.user.id), setIsLoading(!isLoading);
										}}>
										<Left>
											<Thumbnail
												small
												source={{
													uri: item.user.imageUri
														? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.user.imageUri}`
														: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
												}}
											/>
										</Left>
										<Body style={{ paddingLeft: 28 }}>
											<Text>{`${item.user.first_name} ${item.user.last_name}`}</Text>
										</Body>
										<Right>
											<MaterialCommunityIcons name={'star-outline'} size={19} />
										</Right>
									</ListItem>
								);
							}
						})}
					</List>
				</Container>
			) : (
				// <Text>Loading........</Text>
				<View
					style={{
						flex: 1,
						backgroundColor: '#fff',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Spinner color='#000' />
				</View>
			)}
		</>
	);
};

export default GroupMembers;
