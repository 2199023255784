import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Modal, Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Button, Text } from 'native-base';
import { API, graphqlOperation } from 'aws-amplify';
import { useStore } from '../store/index';
import { Dialog, Portal, Paragraph } from 'react-native-paper';

const CircleExitModalRedirect = ({
  modalVisible,
  setModalVisible,
  type,
  chatGroupUserId,
  chatGroupId,
  chatGroupUsersIds,
}) => {
  const navigation = useNavigation();
  const [error, setError] = useState('');
  const currentUserId = useStore((state) => state.currentUserId);
  const screen = useStore((state) => state.screen);
  const globalCurrentUserGroups = useStore(
    (state) => state.globalCurrentUserGroups
  );
  const setGlobalCurrentUserGroups = useStore(
    (state) => state.setGlobalCurrentUserGroups
  );

  const handleYesButton = async () => {
    try {
      if (type === 'groupCircle') {
        let query = await API.graphql({
          query: `mutation deleteChatGroupUser{
			        deleteChatGroupUser(input: {id: "${chatGroupUserId}"}){
			            chat_group_id
			            user {
			                last_name
			            }
			        }
			    }`,
        });
      } else {
        console.log({ chatGroupId, chatGroupUsersIds });
        let result = await Promise.all(
          chatGroupUsersIds.map((id) =>
            API.graphql({
              query: `mutation deleteChatGroupUser{
				            deleteChatGroupUser(input: {id:"${id}"}){
				                chat_group_id
				                user{
				                    last_name
				                }
				            }
				        }`,
            })
          )
        );
        console.log({ result });
        let result2 = await API.graphql({
          query: `mutation deleteChatGroup{
				        deleteChatGroup(input: {id: "${chatGroupId}"}){
				            group_title
				        }
				    }`,
        });
        console.log({ result2 });
      }
      getGroups();
      setModalVisible(!modalVisible);
      navigation.navigate('Circles');
      navigation.reset({
        index: 0,
        routes: [{ name: 'circleScreen' }],
      });
    } catch (error) {
      setError(error.message);
      console.log('user did not exit from circle', error);
    }
  };

  const getGroups = async () => {
    try {
      await API.graphql(
        graphqlOperation(`query listChatGroupUsers {
	  				listChatGroupUsers(filter: {user_id: {eq: "${currentUserId}"}},limit:1000) {
						items {
							chat_group {
								group_title
								id
								created_by
								vip_user_id
								pause_notifications
								chat_group_users {
									items {
										id
										user_id
										user {
											id
											first_name
											imageUri
											last_name
											active_account
										}
									}
								}
							}
						}
					}
				}`)
      ).then((res) => {
        setGlobalCurrentUserGroups(res.data.listChatGroupUsers.items);
      });
    } catch (error) {
      console.log('error updating global groups', error);
    }
  };
  return (
    <View>
      <Portal>
        <Dialog
          visible={modalVisible}
          style={{ width: 300, alignSelf: 'center' }}
          onDismiss={() => setModalVisible(!modalVisible)}
        >
          <Dialog.Title>Remove yourself from the circle</Dialog.Title>
          {error.length > 0 && <Text>{error}</Text>}
          <Dialog.Content>
            <Paragraph>
              Are you sure you want to remove yourself from the circle?
            </Paragraph>
          </Dialog.Content>
          <Dialog.Actions style={styles.modalButtonsView}>
            <Pressable onPress={() => setModalVisible(!modalVisible)}>
              <Text>Cancel</Text>
            </Pressable>
            <Button rounded dark onPress={() => handleYesButton()}>
              <Text style={[{ fontFamily: 'OPEN-SANS-BOLD', color: '#fff' }]}>
                Yes
              </Text>
            </Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

export default CircleExitModalRedirect;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontFamily: 'OPEN-SANS-BOLD',
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  modalButtonsView: {
    flexDirection: 'row',
    marginVertical: 15,
    alignItems: 'center',
    width: 300,
    justifyContent: 'space-around',
  },
});
