import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Header, Left, Body, Right, Title } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import {
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from '@expo/vector-icons';
import { useStore } from '../store/index';
import CircleExitModalRedirect from './CircleExitModalRedirect';

const ConversationHeaderNew = () => {
  const navigation = useNavigation();
  const currentUserId = useStore((state) => state.currentUserId);
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const screen = useStore((state) => state.screen);
  const [chatGroupUserId, setChatGroupUserId] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const handleExitCircle = () => {
    if (
      currentUserId != currentConversationGroup.vip_user_id &&
      currentUserId != currentConversationGroup.created_by
    ) {
      if (currentConversationGroup.chat_group_users.items.length > 2) {
        let chatGroupUser =
          currentConversationGroup.chat_group_users.items.filter(
            (item) => item.user_id === currentUserId
          );
        setChatGroupUserId(chatGroupUser[0].id);
      }
      setModalVisible(!modalVisible);
    }
  };

  return (
    <Header androidStatusBarColor="#fff" style={styles.header}>
      <Left>
        <TouchableOpacity
          onPress={() => {
            if (screen === 'Circles') {
              navigation.reset({
                index: 0,
                routes: [{ name: 'circleScreen' }],
              });
            } else if (screen === 'Home') {
              navigation.navigate('HomeScreen');
              navigation.reset({
                index: 0,
                routes: [{ name: 'circleScreen' }],
              });
            }
          }}
        >
          <FontAwesome5 name="chevron-left" size={30} />
        </TouchableOpacity>
      </Left>
      <Body>
        <Title style={styles.body}>
          {currentConversationGroup.group_title}
        </Title>
      </Body>
      <Right>
        {currentUserId === currentConversationGroup.created_by ? (
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('groupSettings', {
                familyName: currentConversationGroup.group_title,
                group: currentConversationGroup,
              });
            }}
          >
            <FontAwesome name="gear" size={30} />
          </TouchableOpacity>
        ) : (
          currentUserId != currentConversationGroup.vip_user_id && (
            <TouchableOpacity
              onPress={() => handleExitCircle()}
              style={{ paddingHorizontal: 15 }}
            >
              <MaterialCommunityIcons name="account-remove" size={30} />
            </TouchableOpacity>
          )
        )}
      </Right>
      {modalVisible && (
        <CircleExitModalRedirect
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          type={
            currentConversationGroup.chat_group_users.items.length > 2
              ? 'groupCircle'
              : 'userCircle'
          }
          chatGroupUserId={
            currentConversationGroup.chat_group_users.items.length > 2
              ? chatGroupUserId
              : null
          }
          chatGroupId={
            currentConversationGroup.chat_group_users.items.length <= 2
              ? currentConversationGroup.id
              : null
          }
          chatGroupUsersIds={
            currentConversationGroup.chat_group_users.items.length <= 2
              ? [
                  currentConversationGroup.chat_group_users.items[0].id,
                  currentConversationGroup.chat_group_users.items[1].id,
                ]
              : null
          }
        />
      )}
    </Header>
  );
};

const styles = StyleSheet.create({
  header: { backgroundColor: '#fff' },
  body: { color: '#000', textTransform: 'capitalize' },
});
export default ConversationHeaderNew;
