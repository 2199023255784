import React from 'react';
import { View, Text, TouchableOpacity, Alert } from 'react-native';
import { Button } from 'native-base';

import { createStackNavigator } from '@react-navigation/stack';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Circles from '../../components/Circles';
import CircleScreen from '../../screens/CirclesScreen/index';
import CirclesCreation from '../../components/CirclesCreation';
// import GroupCircleConversation from '../../screens/GroupCircleConversation/index';
import GroupSettings from '../../screens/GroupSettings/index';
import HomeScreen from '../../screens/HomeScreen/index';
import SchedulingAndAutoMessages from '../../components/GroupSettingComponents/SchedulingAndAutoMessages';
import GroupMembers from '../../components/GroupSettingComponents/GroupMembers';
import GroupName from '../../components/GroupSettingComponents/GroupName';
import GroupNotificationSettings from '../../components/GroupSettingComponents/GroupNotificationSettings';
import CustomMessage from '../../components/GroupSettingComponents/CustomMessage';
import CustomFollowUp from '../../components/GroupSettingComponents/CustomFollowUp';
import Repeat from '../../components/GroupSettingComponents/Repeat';
import FollowUpMessage from '../../components/GroupSettingComponents/FollowUpMessage';
import NewIndex from '../../screens/GroupCircleConversation/newIndex';
import CircleCreationBuffer from '../../components/CircleCreationBuffer';
import { useNavigation } from '@react-navigation/native';
import AddNewUsersToGroup from '../../components/GroupSettingComponents/AddNewUsersToGroup';

const circlesCreationNavigation = () => {
	const circlesStack = createStackNavigator();
	const navigation = useNavigation();
	return (
		<circlesStack.Navigator
			screenOptions={{
				headerShown: false,
				headerBackTitleVisible: false,
			}}>
			<circlesStack.Screen name='circleScreen' component={CircleScreen} />
			<circlesStack.Screen name='circles' component={Circles} />
			{/* <circlesStack.Screen name='homeScreen' component={HomeScreen} /> */}
			<circlesStack.Screen name='createGroup' component={CirclesCreation} />
			<circlesStack.Screen
				name='newConversation'
				component={NewIndex}
				options={{
					gestureEnabled: false,
				}}
			/>
			<circlesStack.Screen name='groupSettings' component={GroupSettings} />
			<circlesStack.Screen
				name='schudleAndAutoMessages'
				component={SchedulingAndAutoMessages}
				options={{
					headerShown: true,
					title: 'Schedule & Auto Generated Messages',
				}}
			/>
			<circlesStack.Screen
				name='groupMembers'
				component={GroupMembers}
				options={{
					headerShown: true,
					title: 'Circle Members',
					headerRight: () => (
						<Button
							transparent
							style={{ paddingRight: 20 }}
							onPress={() => {
								// navigation.navigate('addNewUsersToGroup');
								navigation.navigate('createGroup', { from: 'GroupMembers' });
							}}>
							<MaterialCommunityIcons name='plus' size={30} color='#1e1e1e' />
						</Button>
					),
				}}
			/>
			<circlesStack.Screen
				name='addNewUsersToGroup'
				component={AddNewUsersToGroup}
				options={{ headerShown: false, title: 'Add Members' }}
			/>
			<circlesStack.Screen
				name='groupName'
				component={GroupName}
				options={{ headerShown: true, title: 'Circle Name' }}
			/>
			<circlesStack.Screen
				name='groupNotificationSettings'
				component={GroupNotificationSettings}
				options={{ headerShown: true, title: 'Circle Notification Settings' }}
			/>
			<circlesStack.Screen
				name='customMessage'
				component={CustomMessage}
				options={{ headerShown: true, title: 'Custom Message' }}
			/>
			<circlesStack.Screen
				name='customFollowUp'
				component={CustomFollowUp}
				options={{ headerShown: true, title: 'Custom Message' }}
			/>
			<circlesStack.Screen
				name='repeat'
				component={Repeat}
				options={{ headerShown: true, title: 'Repeat' }}
			/>
			<circlesStack.Screen
				name='followUpMessage'
				component={FollowUpMessage}
				options={{ headerShown: true, title: 'Follow-up Message' }}
			/>
		</circlesStack.Navigator>
	);
};

export default circlesCreationNavigation;
