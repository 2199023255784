import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const SubHeader = ({ text }) => {
  return (
    <View>
      <Text style={[styles.text]}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    fontWeight: '700',
    fontSize: 20,
    fontFamily: 'OPEN-SANS-BOLD',
  },
});

export default SubHeader;
