import React from 'react';
import { Image, Text, View } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import Signup from '../../screens/SignupScreen';
import LoginComponent from '../../screens/LoginScreen/index';
import BootmTabNavigator from '../BottomTabNavigator/BootmTabNavigator';
import PrivacyAndTermsOfUse from '../../components/AccountSettingComponents/PrivacyAndTermsOfUse';
import * as Linking from 'expo-linking';

const Stack = createStackNavigator();
const prefix = Linking.makeUrl('/');
const linking = {
	prefix: [prefix],
	config: {
		screen: {
			SignInHeader: {},
			Privacy: {},
		},
	},
};
const LoginScreen = () => {
	return (
		<NavigationContainer
			linking={linking}
			screenOptions={{ headerShown: false }}
			initialRouteName='SignInHeader'>
			<Stack.Navigator
			screenOptions={{ headerShown: false }}>
				<Stack.Screen
					name='SignInHeader'
					component={LoginComponent}
					options={{ title: '' }}
				/>
				<Stack.Screen
					name='Privacy'
					component={PrivacyAndTermsOfUse}
					options={{
						headerBackTitleVisible: true,
					}}
				/>
				{/* <Stack.Screen
					name="BootmTabNavigator"
					component={BootmTabNavigator}
					options={{
						headerShown: false,
						title: '',
					}}
				/> */}
			</Stack.Navigator>
		</NavigationContainer>
	);
};

export default LoginScreen;
