import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import { Thumbnail } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { useStore } from '../store/index';
import CircleExitingModel from '../components/CircleExitingModal';

const UserCircleNew = ({ user, screen }) => {
  const navigation = useNavigation();
  const currentUserId = useStore((state) => state.currentUserId);
  const setCurrentConversationGroupId = useStore(
    (state) => state.setCurrentConversationGroupId
  );
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const [modalVisible, setModalVisible] = useState(false);
  const setScreen = useStore((state) => state.setScreen);
  let imageUri = null;
  let inactive = false;
  let groupTitle = null;
  user.chat_group.chat_group_users.items.forEach((item) => {
    if (item.user.id != currentUserId && item.user.imageUri !== null) {
      imageUri = `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.user.imageUri}`;
    }
    if (item.user.id != currentUserId && item.user.active_account === 0) {
      inactive = true;
    }
    if (item.user.id != currentUserId) {
      groupTitle = user?.chat_group?.group_title ? user?.chat_group?.group_title : item.user.first_name;
    }
  });
  const handleLongPress = () => {
    if (
      currentUserId != user.chat_group.vip_user_id &&
      currentUserId != user.chat_group.created_by
    ) {
      setModalVisible(!modalVisible);
    }
  };

  return (
    <View
      style={{
        flexGrow: 0,
        flexBasis: '25%',
        marginVertical: 10,
      }}
    >
      <Pressable
        onLongPress={() => handleLongPress()}
        onPress={() => {
          setCurrentConversationGroupId(user.chat_group.id);
          setCurrentConversationGroup(user.chat_group);
          setScreen(screen);
          navigation.navigate('CirclesScreen', { screen: 'newConversation' });
        }}
      >
        {inactive ? (
          <View style={styles.pendingView}>
            <Text style={styles.pendingText}>Pending</Text>
          </View>
        ) : (
          <View style={styles.userImage}>
            <Thumbnail
              large
              source={{
                uri: imageUri
                  ? imageUri
                  : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
              }}
            />
          </View>
        )}
      </Pressable>

      <Text style={styles.userName}>{groupTitle}</Text>
      {modalVisible && (
        <CircleExitingModel
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          type="userCircle"
          chatGroupId={user.chat_group.id}
          chatGroupUsersIds={[
            user.chat_group.chat_group_users.items[0].id,
            user.chat_group.chat_group_users.items[1].id,
          ]}
        />
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  userName: {
    marginVertical: 5,
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  pendingText: {
    fontFamily: 'CRIMSON-TEXT',
    color: '#FFF',
  },
  pendingView: {
    width: 80,
    height: 80,
    borderRadius: 100,
    backgroundColor: '#1e1e1e',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  userImage: {
    alignSelf: 'center',
    borderRadius: 100,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
      },
      android: {
        backgroundColor: '#fff',
        elevation: 5,
      },
    }),
  },
});

export default UserCircleNew;
