import React, { useEffect } from 'react';
import {
	NavigationContainer,
	LinkingOptions,
	useLinkTo,
} from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import {
	Authenticator,
	withAuthenticator,
	withOAuth,
} from 'aws-amplify-react-native';

import AccountScreenNavigator from '../AccountScreenNavigator/AccountScreenNavigator';
import HomeScreen from '../../screens/HomeScreen/index';
import CirclesScreen from '../../screens/CirclesScreen/index';
import TodayScreen from '../../screens/TodayScreen/index';
import CircleCreationNavigator from '../CircleCreationNavigator/CircleCreationNavigator';
import HomeScreenNavigator from '../HomeScreenNavigator/HomeScreenNavigator';
import Circles from '../../components/Circles';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Linking from 'expo-linking';

import Login from '../../screens/LoginScreen/index';

const Tab = createBottomTabNavigator();
const prefix = Linking.makeUrl('/');
const linking = {
	prefixes: [prefix],
	config: {
		screen: {
			HomeScreen: {},
			CirclesScreen: {},
			TodayScreen: {},
			AccountScreen: {
				path: 'account',
				initialRouteName: 'AccountScreen',
				screens: {
					AccountScreen: 'accountscreen',
					Name: 'name',
					PhoneNumber: 'phonenumber',
					Email: 'email',
					MessageOfTheDay: 'messageoftheday',
					SignedInWith: 'signedinwith',
					NotificationSettings: 'notificationssettings',
					FAQ: 'faq',
					Support: 'support',
					PrivacyAndTermsOfUse: {
						path: 'privacyandtermsofuse',
					},
					AboutUs: 'aboutus',
				},
			},
		},
	},
};
const BottomTabNavigator = () => {
	useEffect(() => {
		getItemValue();
	}, []);

	const getItemValue = async () => {
		try {
			let value = await AsyncStorage.getItem('@LoggedIn');
			console.log('value at bottam', value);
		} catch (error) {
			console.log('Error at btn', error);
		}
	};
	return (
		<NavigationContainer linking={linking}>
			<Tab.Navigator
				initialRouteName='HomeScreen'
				tabBarOptions={{
					activeTintColor: '#C10644',
					inactiveTintColor: '#1E1E1E',
				}}
				backBehavior='order'
				lazy='false'>
				<Tab.Screen
					name='HomeScreen'
					component={HomeScreen}
					options={{
						tabBarLabel: 'Home',
						tabBarIcon: ({ color }) => (
							<MaterialCommunityIcons name='home' color={color} size={30} />
						),
					}}
				/>

				<Tab.Screen
					name='CirclesScreen'
					component={CircleCreationNavigator}
					options={{
						tabBarLabel: 'Circles',
						tabBarIcon: ({ color }) => (
							<MaterialCommunityIcons
								name='record-circle'
								color={color}
								size={30}
							/>
						),
					}}
				/>

				<Tab.Screen
					name='TodayScreen'
					component={TodayScreen}
					options={{
						tabBarLabel: 'Today',
						tabBarIcon: ({ color }) => (
							<Ionicons name='md-sunny' color={color} size={30} />
						),
					}}
				/>

				<Tab.Screen
					name='AccountScreen'
					component={AccountScreenNavigator}
					options={{
						tabBarLabel: 'Account',
						tabBarIcon: ({ color }) => (
							<MaterialCommunityIcons
								name='account-circle'
								color={color}
								size={30}
							/>
						),
					}}
				/>
			</Tab.Navigator>
		</NavigationContainer>
	);
};

export default withAuthenticator(BottomTabNavigator, false, [<Login />]);
