import React, { useEffect, useState } from 'react';
import { View, Image, Text } from 'react-native';
import useGreeting from '@trndd/time-greeting';
import { createStackNavigator } from '@react-navigation/stack';
import { API, graphqlOperation } from 'aws-amplify';

import { useStore } from '../../store/index';
import AccountScreenComponent from '../../screens/AccountScreen';
import Name from '../../components/AccountSettingComponents/Name';
import PhoneNumber from '../../components/AccountSettingComponents/PhoneNumber';
import Email from '../../components/AccountSettingComponents/Email';
import MessageOfTheDay from '../../components/AccountSettingComponents/MessageOfTheDay';
import SignedInWith from '../../components/AccountSettingComponents/SignedInWith';
import NotificationSettings from '../../components/AccountSettingComponents/NotificationSettings';
import FAQ from '../../components/AccountSettingComponents/FAQ';
import Support from '../../components/AccountSettingComponents/Support';
import PrivacyAndTermsOfUse from '../../components/AccountSettingComponents/PrivacyAndTermsOfUse';
import AboutUs from '../../components/AccountSettingComponents/AboutUs';

const Stack = createStackNavigator();

const AccountScreen = () => {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen name='AccountScreen' component={AccountScreenComponent} />
			<Stack.Screen name='Name' component={Name} options={{ title: 'Name' }} />
			<Stack.Screen
				name='PhoneNumber'
				component={PhoneNumber}
				options={{ title: 'Phone Number' }}
			/>
			<Stack.Screen
				name='Email'
				component={Email}
				options={{ title: 'Email' }}
			/>
			<Stack.Screen
				name='MessageOfTheDay'
				component={MessageOfTheDay}
				options={{ title: 'Message of The Day' }}
			/>
			<Stack.Screen
				name='SignedInWith'
				component={SignedInWith}
				options={{ title: 'Account Sign In' }}
			/>
			<Stack.Screen
				name='NotificationSettings'
				component={NotificationSettings}
				options={{ title: 'Notifications' }}
			/>
			<Stack.Screen name='FAQ' component={FAQ} options={{ title: 'FAQ' }} />
			<Stack.Screen
				name='Support'
				component={Support}
				options={{ title: 'Support' }}
			/>
			<Stack.Screen
				name='PrivacyAndTermsOfUse'
				component={PrivacyAndTermsOfUse}
				options={{ title: 'Privacy And Terms Of Use' }}
			/>
			<Stack.Screen
				name='AboutUs'
				component={AboutUs}
				options={{ title: 'About US' }}
			/>
		</Stack.Navigator>
	);
};

export default AccountScreen;
