// @flow

export default () => {
	const thumbnailTheme = {
		'.square': {
			borderRadius: 0,
			'.small': {
				width: 25,
				height: 25,
				borderRadius: 0,
			},
			'.large': {
				width: 80,
				height: 80,
				borderRadius: 0,
			},
		},
		'.small': {
			width: 25,
			height: 25,
			borderRadius: 18,
			'.square': {
				borderRadius: 0,
			},
		},
		'.large': {
			width: 80,
			height: 80,
			borderRadius: 40,
			'.square': {
				borderRadius: 0,
			},
		},
		width: 56,
		height: 56,
		borderRadius: 28,
	};

	return thumbnailTheme;
};
