import React, { useEffect, useState } from 'react';
import { Header, Left, Right } from 'native-base';
import { View, Image, Text } from 'react-native';
import useGreeting from '@trndd/time-greeting';
import { useStore } from '../store';
import { useNavigation } from '@react-navigation/native';
const NameAndGreetings = ({ userName }) => {
	const message = useGreeting({
		date: Date.now(),
	});
	return (
		<>
			<Text
				style={{
					fontWeight: '700',
					fontSize: 15,
					paddingRight: 4,
					textTransform: 'capitalize',
				}}>
				{`${message.greeting},`}
			</Text>
			<Text
				style={{
					fontWeight: '700',
					fontSize: 15,
					paddingRight: 4,
					textTransform: 'capitalize',
				}}>
				{userName}
			</Text>
		</>
	);
};
const AppHeader = () => {
	const navigation = useNavigation();
	const currentUser = useStore((state) => state.currentUser);
	const [userName, setUserName] = useState('');
	useEffect(() => {
		setUserName(currentUser.first_name);
	}, []);

	useEffect(() => {
		navigation.addListener('focus', () => setUserName(currentUser.first_name));
	});
	// console.log(userName.length);
	return (
		<Header androidStatusBarColor='#fff' style={{ backgroundColor: '#fff' }}>
			<Left>
				<View style={{ paddingLeft: 4 }}>
					<Image
						source={require('../../assets/logo.png')}
						style={{ width: 106, height: 26 }}
					/>
				</View>
			</Left>

			<Right>
				{/* <NameAndGreetings userName={userName} /> */}
				{/* {userName.length <= 10 ? (
					<NameAndGreetings userName={userName} />
				) : ( */}
				<View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
					<NameAndGreetings userName={currentUser.first_name} />
				</View>
				{/* )} */}
			</Right>
		</Header>
	);
};

export default AppHeader;
