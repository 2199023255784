
import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, StyleSheet, ScrollView } from "react-native";
import { Container } from "native-base";
import SubHeader from "../SubHeader";
import containerStyles from "../AccountSettingComponents/containerStyle";
import CreateCustomFollowUp from "../GroupSettingComponents/CreateCustomFollowUp";
import AutoGeneratedMessageScreenItem from "../GroupSettingComponents/AutoGeneratedMessageScreenItem";
import Time from "./FollowUpMessageTime";
import { useStore } from "../../store";
const FollowUpMessage = ({ route, navigation }) => {
  const { schedFollow, schedFollowContent } = route.params;
  const [selected, setSelected] = useState({
    goodmorning: false,
    checkinginagain: false,
    areyouok: false,
    pleaserespond: false,
    howareyou: false,
  });
  const followMessage = useStore((state) => state.followMessage);
  const setFollowMessage = useStore((state) => state.setFollowMessage);
  const customAutoFollowMessageBool = useStore(
    (state) => state.customAutoFollowMessageBool
  );
  const setCustomAutoFollowMessageBool = useStore(
    (state) => state.setCustomAutoFollowMessageBool
  );
  const customAutoFollowMessageText = useStore(
    (state) => state.customAutoFollowMessageText
  );
  const setCustomAutoFollowMessageText = useStore(
    (state) => state.setCustomAutoFollowMessageText
  );
  useEffect(() => {
    if (schedFollowContent != "" && followMessage == "Hello???") {
      radioButtonScheduled(
        schedFollowContent?.replace(/\s+/g, "").toLowerCase().slice(0, 6),
        schedFollowContent
      );
    }
    // console.log('follow content: ', schedFollowContent)
  }, []);
  useEffect(() => {
    if (followMessage != "Hello???") {
      radioButtonScheduled(
        followMessage?.replace(/\s+/g, "").toLowerCase().slice(0, 6),
        followMessage
      );
    }
  }, [followMessage]);
  useEffect(() => {
    checkCustomMessage();
  }, [customAutoFollowMessageBool]);
  useEffect(() => {
    console.log({ customAutoFollowMessageBool });
  }, [customAutoFollowMessageBool]);
  const radioButton = (value) => {
    let data = { ...selected };
    Object.keys(data).map((item) =>
      item === value ? (data[item] = true) : (data[item] = false)
    );
    setSelected(data);
    setCustomAutoFollowMessageBool(false);
  };
  const radioButtonScheduled = (value, value2) => {
    let data = { ...selected };
    Object.keys(data).forEach((item) => {
      item.startsWith(value) ? (data[item] = true) : (data[item] = false);
    });
    setSelected(data);
    if (
      !data.areyouok &&
      !data.checkinginagain &&
      !data.goodmorning &&
      !data.howareyou &&
      !data.pleaserespond
    ) {
      setCustomAutoFollowMessageText(value2);
      console.log("set the custom message text");
    } else {
      setCustomAutoFollowMessageBool(false);
    }
  };
  const checkCustomMessage = () => {
    customAutoFollowMessageBool
      ? setSelected({
          goodmorning: false,
          checkinginagain: false,
          areyouok: false,
          pleaserespond: false,
          howareyou: false,
        })
      : console.log("checked for custom follow message:", {
          customAutoFollowMessageBool,
        });
  };
  return (
    <Container style={containerStyles.container}>
      <ScrollView>
        <SubHeader text={"Follow-up Message"} />
        <Time schedFollow={schedFollow} />
        <View style={{ marginVertical: 15 }}>
          <SubHeader text={"Auto-Generated Follow-up Message"} />
          <TouchableOpacity
            onPress={() => {
              radioButton("goodmorning");
              setFollowMessage("Good morning");
            }}
          >
            <AutoGeneratedMessageScreenItem
              radioIcon={
                selected.goodmorning ? "radiobox-marked" : "radiobox-blank"
              }
              itemName={"Good Morning!"}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              radioButton("checkinginagain");
              setFollowMessage("Checking in again.");
            }}
          >
            <AutoGeneratedMessageScreenItem
              radioIcon={
                selected.checkinginagain ? "radiobox-marked" : "radiobox-blank"
              }
              itemName={"Checking in again."}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              radioButton("areyouok");
              setFollowMessage("Are you okay?");
            }}
          >
            <AutoGeneratedMessageScreenItem
              radioIcon={
                selected.areyouok ? "radiobox-marked" : "radiobox-blank"
              }
              itemName={"Are you Okay?"}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              radioButton("pleaserespond");
              setFollowMessage("Please respond.");
            }}
          >
            <AutoGeneratedMessageScreenItem
              radioIcon={
                selected.pleaserespond ? "radiobox-marked" : "radiobox-blank"
              }
              itemName={"Please respond."}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              radioButton("howareyou");
              setFollowMessage("How are you doing?");
            }}
          >
            <AutoGeneratedMessageScreenItem
              radioIcon={
                selected.howareyou ? "radiobox-marked" : "radiobox-blank"
              }
              itemName={"How are you doing?"}
            />
          </TouchableOpacity>
          <CreateCustomFollowUp />
        </View>
      </ScrollView>
    </Container>
  );
};
const styles = StyleSheet.create({
  itemView: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 5,
  },
  itemNameAndIconView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#D7D7D8",
    paddingVertical: 10,
  },
});
export default FollowUpMessage;