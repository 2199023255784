import React from 'react';
import { Button, Text } from 'native-base';
import { View, StyleSheet } from 'react-native';

const AccountSettingsButton = ({ text }) => {
	return (
		<View style={styles.buttonView}>
			<Button rounded dark>
				<Text style={{ fontFamily: 'OPEN-SANS-SEMIBOLD' }}>{text}</Text>
			</Button>
		</View>
	);
};

const styles = StyleSheet.create({
	buttonView: {
		flex: 1,
		marginVertical: 30,
	},
});

export default AccountSettingsButton;
