import React from 'react';
import { Text, StyleSheet } from 'react-native';

const LoginAndSignupHeader = ({ text }) => {
	return <Text style={styles.header}>{text}</Text>;
};

const styles = StyleSheet.create({
	header: {
		marginTop: 50,
		fontWeight: '700',
		fontSize: 20,
		marginBottom: 10,
		marginLeft: 10,
	},
});

export default LoginAndSignupHeader;
