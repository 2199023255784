import React, { useState, useEffect } from 'react';
import { View, Modal, StyleSheet } from 'react-native';
import {
	Container,
	Header,
	Content,
	List,
	ListItem,
	Left,
	Body,
	Right,
	Thumbnail,
	Text,
	CheckBox,
	Button,
	Input,
	Item,
} from 'native-base';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import { useStore } from '../store';
import useForceUpdate from 'use-force-update';
import {
	TouchableOpacity,
	TouchableWithoutFeedback,
} from 'react-native-gesture-handler';
import { Dialog, Portal } from 'react-native-paper';

const UserListItem = ({ user }) => {
	const [userChecked, setUserChecked] = useState(false);
	const createGroupSelectedUsers = useStore(
		(state) => state.createGroupSelectedUsers
	);
	const setCreateGroupSelectedUsers = useStore(
		(state) => state.setCreateGroupSelectedUsers
	);

	const addSelectedUser = (e) => {
		let user = e;
		setCreateGroupSelectedUsers([...createGroupSelectedUsers, user]);
	};
	const removeSelectedUser = (e) => {
		let user = e;
		setCreateGroupSelectedUsers(
			createGroupSelectedUsers.filter((e) => e !== user)
		);
	};

	return (
		<>
			<ListItem avatar>
				<Left>
					<Thumbnail
						source={{
							uri: user.imageUri
								? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${user.imageUri}`
								: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
						}}
						small
					/>
				</Left>
				<Body>
					<Text>
						{user.first_name} {user.last_name}
					</Text>
				</Body>
				<Right>
					<CheckBox
						checked={userChecked}
						color='#1e1e1e'
						style={{ marginRight: 15 }}
						onPress={() => {
							setUserChecked((checked) => !checked);
							{
								!userChecked ? addSelectedUser(user) : removeSelectedUser(user);
							}
						}}
					/>
				</Right>
			</ListItem>
		</>
	);
};

const SelectedUserListItem = ({ user }) => {
	return (
		<>
			<ListItem avatar>
				<Body style={{ alignItems: 'center' }}>
					<Thumbnail
						source={{
							uri: user.imageUri
								? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${user.imageUri}`
								: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
						}}
						medium
					/>
					<Text>{user.first_name}</Text>
				</Body>
			</ListItem>
		</>
	);
};

const SearchList = ({ searchTerm }) => {
	const [isLoading, setIsLoading] = useState(true);

	let searchTermFirstName = '';
	let searchTermLastName = '';
	if (/\s/g.test(searchTerm)) {
		searchTermFirstName = searchTerm.split(/[ ]+/)[0];
		searchTermLastName = searchTerm.split(/[ ]+/)[1];
	} else {
		searchTermFirstName = searchTerm.split(/[ ]+/)[0];
	}

	const [userList, setUserList] = useState([]);
	const currentUserId = useStore((state) => state.currentUserId);
	const createGroupSelectedUsers = useStore(
		(state) => state.createGroupSelectedUsers
	);
	const setCreateGroupSelectedUsers = useStore(
		(state) => state.setCreateGroupSelectedUsers
	);
	const [modalVisible, setModalVisible] = useState(false);
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		setCreateGroupSelectedUsers([]);
	}, []);

	useEffect(() => {
		forceUpdate();
	}, [isLoading]);

	useEffect(() => {
		if (createGroupSelectedUsers.length > 0) {
			console.log(createGroupSelectedUsers[0].first_name);
		}
	}, [createGroupSelectedUsers]);

	const getUsers = async () => {
		try {
			let allUsers = await API.graphql(
				graphqlOperation(`query listUsers {
                listUsers(filter: {first_name: {contains: "${searchTermFirstName}"}, or: {last_name: {contains: "${searchTermLastName}"}}, and: {id: {ne: "${currentUserId}"}}}) {
                    items {
                        id
                        imageUri
                        first_name
                        last_name
                        }
                    }
                }`)
			);
			console.log({ allUsers });
			setUserList(allUsers.data.listUsers.items);
			setIsLoading(!isLoading);
		} catch (error) {
			console.log('List users query....', error);
		}
	};

	return (
		<>
			{createGroupSelectedUsers.length >= 1 ? (
				<List
					horizontal={true}
					itemDivider={false}
					dataArray={createGroupSelectedUsers}
					keyExtractor={(item) => item.id}
					renderRow={(user, i) => <SelectedUserListItem user={user} />}
				/>
			) : (
				<></>
			)}
			{isLoading ? (
				<Text>Loading.....</Text>
			) : userList.length == 0 ? (
				<View
					style={{
						flex: 1,
						alignItems: 'center',
					}}>
					<Text style={{ marginVertical: 10 }}>No results found</Text>
					<Button
						rounded
						dark
						onPress={() => {
							setModalVisible(true);
						}}
						style={{ alignSelf: 'center' }}>
						<Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>
							Send Invitation
						</Text>
					</Button>
				</View>
			) : (
				<List
					dataArray={userList}
					keyExtractor={(item) => item.id}
					renderRow={(user, i) => <UserListItem user={user} />}
				/>
			)}
			<Portal>
				<Dialog
					visible={modalVisible}
					style={{
						backgroundColor: '#fff',
						width: '25vw',
						height: '15vh',
						alignSelf: 'center',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Dialog.Title>Send An Invitation</Dialog.Title>
					<Dialog.Content>
						<Item>
							<Input placeholder='Invite Via Email' />
						</Item>
					</Dialog.Content>
					<Dialog.Actions>
						<TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
							<Text>Cancel</Text>
						</TouchableOpacity>
						<Button rounded dark onPress={() => setModalVisible(!modalVisible)}>
							<Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}> Send</Text>
						</Button>
					</Dialog.Actions>
				</Dialog>
			</Portal>
			{/* <Container>
				<Modal
					animationType='slide'
					transparent={true}
					visible={modalVisible}
					onRequestClose={() => {
						Alert.alert('Modal has been closed.');
					}}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Send An Invitation</Text>
							<Item>
								<Input placeholder='Invite Via Email' />
							</Item>
							<View style={styles.modalButtonsView}>
								<TouchableOpacity
									onPress={() => setModalVisible(!modalVisible)}>
									<Text>Cancel</Text>
								</TouchableOpacity>
								<Button
									rounded
									dark
									onPress={() => setModalVisible(!modalVisible)}>
									<Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}> Send</Text>
								</Button>
							</View>
						</View>
					</View>
				</Modal>
			</Container> */}
		</>
	);
};
export default SearchList;

const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22,
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center',
		fontFamily: 'CRIMSON-TEXT',
	},
	modalButtonsView: {
		flexDirection: 'row',
		marginVertical: 15,
		alignItems: 'center',
		width: 300,
		justifyContent: 'space-around',
	},
});
