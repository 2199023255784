import React, { useEffect, useState } from "react";
import { TouchableOpacity, View, StyleSheet, Text, Alert,ScrollView } from "react-native";
import { Container } from "native-base";
import { MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import moment from "moment";

import { useStore } from "../../store";
import containerStyles from "../AccountSettingComponents/containerStyle";
import SubHeader from "../SubHeader";

const DayItem = ({ day, radioIcon }) => {
  return (
    <View style={styles.itemView}>
      <MaterialCommunityIcons
        name={"calendar-blank"}
        size={25}
        style={{ alignSelf: "center", marginRight: 10 }}
      />
      <View style={styles.itemNameAndIconView}>
        <Text style={[containerStyles.text, { alignSelf: "center" }]}>
          {`Every ${day}`}
        </Text>
        <MaterialCommunityIcons name={radioIcon} size={20} />
      </View>
    </View>
  );
};

const Repeat = ({ route, navigation }) => {
  const { schedDay } = route.params;
  const [repeatSelected, setRepeatSelected] = useState({
    Day: false,
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });
  const setMessageScheduleTimeMon = useStore(
    (state) => state.setMessageScheduleTimeMon
  );
  const setMessageScheduleTimeDay = useStore(
    (state) => state.setMessageScheduleTimeDay
  );
  const setMessageScheduleTimeDayWK = useStore(
    (state) => state.setMessageScheduleTimeDayWK
  );
  const setMessageScheduleTimeYr = useStore(
    (state) => state.setMessageScheduleTimeYr
  );
  const messageScheduleTimeDayWK = useStore(
    (state) => state.messageScheduleTimeDayWK
  );

  useEffect(() => {
    if (schedDay != "") {
      radioButtonScheduled(schedDay);
    }
    if (schedDay == "" && messageScheduleTimeDayWK != "?") {
      radioButtonScheduled(messageScheduleTimeDayWK);
    }
  },[])

  const radioButtonScheduled = (value) => {
    let data = { ...repeatSelected };
    Object.keys(data).forEach((item) => {
      let lowerItem = item.toUpperCase();
      lowerItem.includes(value.toUpperCase()) ? (data[item] = true) : (data[item] = false);
      if (value.toUpperCase() == '*' && item == 'Day') {data[item] = true } 
    });
    setRepeatSelected(data);
  };

  useEffect(() => {
    setMessageScheduleTimeYr("*");
    setMessageScheduleTimeMon("*");
    if (repeatSelected.Day) {
      setMessageScheduleTimeDay("?");
      setMessageScheduleTimeDayWK("*");
    }
  }, []);

  const radioButton = (value) => {
    let data = { ...repeatSelected };
    Object.keys(data).forEach((d) =>
      d === value ? (data[d] = true) : (data[d] = false)
    );

    setRepeatSelected(data);
  };

  return (
    <Container style={containerStyles.container}>
	<ScrollView>
      <SubHeader text={"Repeat"} />
      <TouchableOpacity
        onPress={() => {
          radioButton("Day");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("*");
        }}
      >
        <DayItem
          day={"Day"}
          radioIcon={repeatSelected.Day ? "radiobox-marked" : "radiobox-blank"}
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          radioButton("Sunday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("SUN");
        }}
      >
        <DayItem
          day={"Sunday"}
          radioIcon={
            repeatSelected.Sunday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          radioButton("Monday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("MON");
        }}
      >
        <DayItem
          day={"Monday"}
          radioIcon={
            repeatSelected.Monday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          radioButton("Tuesday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("TUE");
        }}
      >
        <DayItem
          day={"Tuesday"}
          radioIcon={
            repeatSelected.Tuesday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          radioButton("Wednesday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("WED");
        }}
      >
        <DayItem
          day={"Wednesday"}
          radioIcon={
            repeatSelected.Wednesday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          radioButton("Thursday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("THU");
        }}
      >
        <DayItem
          day={"Thursday"}
          radioIcon={
            repeatSelected.Thursday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          radioButton("Friday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("FRI");
        }}
      >
        <DayItem
          day={"Friday"}
          radioIcon={
            repeatSelected.Friday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => {
          radioButton("Saturday");
          setMessageScheduleTimeDay("?");
          setMessageScheduleTimeDayWK("SAT");
        }}
      >
        <DayItem
          day={"Saturday"}
          radioIcon={
            repeatSelected.Saturday ? "radiobox-marked" : "radiobox-blank"
          }
        />
      </TouchableOpacity>
	  </ScrollView>
    </Container>
	
  );
};

const styles = StyleSheet.create({
  itemView: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 5,
  },
  itemNameAndIconView: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#D7D7D8",
    paddingVertical: 10,
  },
});
export default Repeat;
