import React from 'react';
import { View, Text } from 'react-native';
import { Container } from 'native-base';

import SubHeader from '../SubHeader';
import containerStyles from './containerStyle';
import AccountSettingsHeader from './AccountSettingsHeader';

const AboutUs = () => {
  return (
    <>
      <AccountSettingsHeader screenName={'About Us'} />
      <Container style={containerStyles.container}>
        <SubHeader text={'About Us'} />
        <Text style={containerStyles.text}>
          HowYouBe is a free application that was developed to help people
          connect and monitor the wellbeing of family, friends, neighbors, and
          all loved ones.
        </Text>
        <Text style={containerStyles.text}>
          It was inspired by Clara and George.
        </Text>
        <Text style={containerStyles.text}>
          Clara was living alone when she passed away. Unfortunately, she didn't
          have anyone checking in on her. After a few weeks, a concerned
          neighbor called the police.
        </Text>
        <Text style={containerStyles.text}>
          George fell in his home and broke his leg, but could not reach a phone
          to call for help. Instead, he pulled himself to the front door and
          waited for someone. After two days, someone came to his aid.
        </Text>
        <Text style={containerStyles.text}>
          HowYouBe is designed to help us stay connected to the Claras and
          Georges in our lives. To keep them safe and to let them know they are
          cared for. Whether we're stuck in our houses during a snowstorm or a
          global pandemic, or just living our day-to-day lives, everyone likes
          to hear, "How you be?"
        </Text>
      </Container>
    </>
  );
};

export default AboutUs;
