import React, { useEffect, useState } from 'react';
import { Alert, View } from 'react-native';
import {
  Container,
  Content,
  Form,
  Item,
  Input,
  Label,
  Button,
  Text,
} from 'native-base';
import containerStyles from './containerStyle';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import AccountSettingsHeader from './AccountSettingsHeader';
import AccountSettingsButton from '../Button';
import SubHeader from '../SubHeader';
import { useStore } from '../../store';
import { useNavigation } from '@react-navigation/native';

const Name = () => {
  const navigation = useNavigation();
  const currentUser = useStore((state) => state.currentUser);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const currentUserId = useStore((state) => state.currentUserId);
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    setFirstName(currentUser.first_name);
  }, []);

  const updateName = async () => {
    setCurrentUser({ ...currentUser }, (currentUser.first_name = firstName));
    // console.log('After changing global state of currentUser', currentUser);
    try {
      let res = await API.graphql({
        query: mutations.updateUser,
        variables: {
          input: {
            id: currentUserId,
            first_name: firstName,
          },
        },
      });
      setCurrentUser(
        { ...currentUser },
        (currentUser.firstName = res.data.updateUser.first_name)
      );
      navigation.navigate('AccountScreen');
      // console.log('after changing the first_name into db.....', res);
    } catch (error) {
      console.log('Error while writing data to db group Name....', error);
    }
  };

  return (
    <>
      <AccountSettingsHeader screenName={'Name'} />
      <Container style={containerStyles.container}>
        <SubHeader text={'Enter Your Name'} />
        <Text style={containerStyles.text}>
          Please enter your name as you would like it to be displayed to others
          in your Circles.
        </Text>
        <Content>
          <Form>
            <Item
              style={{ borderBottomWidth: 1, borderBottomColor: '#1e1e1e' }}
            >
              <Input
                placeholder="Name"
                value={firstName}
                onChangeText={(text) => setFirstName(text)}
              />
            </Item>
            <View style={{ alignSelf: 'center', marginVertical: 15 }}>
              <Button rounded dark onPress={() => updateName()}>
                <Text>Save</Text>
              </Button>
              {/* <AccountSettingsButton text={"Save Changes"} /> */}
            </View>
          </Form>
        </Content>
      </Container>
    </>
  );
};

export default Name;
