import React, { useEffect, useState } from 'react';
import { Text } from 'react-native';
import { Container, List, ListItem } from 'native-base';
import { API, graphqlOperation } from 'aws-amplify';
// import { listFaQs } from "../../../graphql/queries";
import SubHeader from '../SubHeader';
import containerStyles from './containerStyle';
import AccountSettingsHeader from './AccountSettingsHeader';

const FAQ = () => {
	const [faqList, setFAQList] = useState();

	const getFaQs = async () => {
		try {
			const listFAQS = `query listFAQS {
		listFAQs {
		  items {
			id
			title
			content
		  }
		}
	  }`;
			let allFAQs = await API.graphql(graphqlOperation(listFAQS));
			console.log({ allFAQs });
			setFAQList(allFAQs.data.listFAQs.items);
		} catch (error) {
			console.log('List users query....', error);
		}
	};

	useEffect(() => {
		getFaQs();
	}, []);

	return (
		<>
			<AccountSettingsHeader screenName={'FAQ'} />
			<Container style={containerStyles.container}>
				<List
					style={{ flex: 1 }}
					horizontal={false}
					itemDivider={false}
					dataArray={faqList}
					keyExtractor={(item) => item.id}
					renderRow={(item, i) => (
						<>
							<Text
								style={{
									fontWeight: '700',
									fontSize: 20,
									fontFamily: 'OPEN-SANS-BOLD',
								}}>
								{item.title}
							</Text>
							<Text style={[containerStyles.text, { marginVertical: 10 }]}>
								{item.content}
							</Text>
						</>
					)}
				/>
			</Container>
		</>
	);
};

export default FAQ;
