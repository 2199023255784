import React, { useState, useEffect } from 'react';
import { View, Alert, StyleSheet, Modal, TouchableOpacity } from 'react-native';
import {
	Container,
	Header,
	Content,
	List,
	ListItem,
	Left,
	Body,
	Right,
	Thumbnail,
	Text,
	CheckBox,
	Button,
	Item,
	Input,
} from 'native-base';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';
import { useStore } from '../store';
import { Dialog } from 'react-native-paper';

const UserListItem = ({ item }) => {
	const [userChecked, setUserChecked] = useState(false);
	const createGroupSelectedUsers = useStore(
		(state) => state.createGroupSelectedUsers
	);
	const setCreateGroupSelectedUsers = useStore(
		(state) => state.setCreateGroupSelectedUsers
	);

	const addSelectedUser = (e) => {
		let user = e;
		setCreateGroupSelectedUsers([...createGroupSelectedUsers, user]);
	};
	const removeSelectedUser = (e) => {
		let user = e;
		setCreateGroupSelectedUsers(
			createGroupSelectedUsers.filter((e) => e !== user)
		);
	};

	return (
		<>
			<ListItem avatar>
				<Left>
					<Thumbnail
						source={{
							uri: item.imageUri
								? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${item.imageUri}`
								: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
						}}
						small
					/>
				</Left>
				<Body>
					<Text>
						{item.first_name} {item.last_name}
					</Text>
				</Body>
				<Right>
					<CheckBox
						checked={userChecked}
						color='#1e1e1e'
						style={{ marginRight: 15 }}
						onPress={() => {
							setUserChecked((checked) => !checked);
							{
								!userChecked ? addSelectedUser(user) : removeSelectedUser(user);
							}
						}}
					/>
				</Right>
			</ListItem>
		</>
	);
};

const SelectedUserListItem = ({ user }) => {
	return (
		<>
			<ListItem avatar>
				<Body style={{ alignItems: 'center' }}>
					<Thumbnail
						source={{
							uri: user.imageUri
								? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${user.imageUri}`
								: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
						}}
						medium
					/>
					<Text>{user.first_name}</Text>
				</Body>
			</ListItem>
		</>
	);
};

const UserListForNewUsers = ({ group, searchText }) => {
	const [userList, setUserList] = useState([]);
	const [searchUserList, setSearchUserList] = useState([]);

	const currentUserId = useStore((state) => state.currentUserId);
	const createGroupSelectedUsers = useStore(
		(state) => state.createGroupSelectedUsers
	);
	const setCreateGroupSelectedUsers = useStore(
		(state) => state.setCreateGroupSelectedUsers
	);
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		searchUser();
	}, [searchText]);

	useEffect(() => {
		setCreateGroupSelectedUsers([]);
	}, []);
	useEffect(() => {
		// console.log(createGroupSelectedUsers);
		if (createGroupSelectedUsers.length > 0) {
			console.log(createGroupSelectedUsers[0].first_name);
		}
	}, [createGroupSelectedUsers]);

	const searchUser = () => {
		if (searchText !== '') {
			try {
				API.graphql(
					graphqlOperation(`query listUsers{
				 listUsers(filter: {first_name: {contains: "${searchText}"}, and: {id: {ne: "${currentUserId}"}}}) {
                    items {
                        id
                        imageUri
                        first_name
                        last_name
                        }
                    }
			}`)
				).then((res) => {
					console.log({ res });
					setSearchUserList(res.data.listUsers.items);
				});
			} catch (error) {
				console.log('Error grabing users data from search field', error);
			}
		}
	};

	const getUsers = async () => {
		// const allUsers = await API.graphql({ query: queries.listUsers });
		try {
			// 	let allUsers = await API.graphql(
			// 		graphqlOperation(`query listUsers {
			//   listUsers(filter: {id: {ne: "${currentUserId}"}}) {
			//     items {
			//       id
			//       first_name
			//       last_name
			//       imageUri
			//     }
			//   }
			// }`)
			// 	);

			let allUsers = await API.graphql(
				graphqlOperation(`query listChatGroupUsers {
					listChatGroupUsers(filter: {user_id: {eq: "${currentUserId}"}}) {
						items {
							chat_group {
								chat_group_users {
									items {
										user {
											id
											first_name
											imageUri
											last_name
											}
										}
									}
								}
							}
						}
					}`)
			);
			let temp = [];
			let uniquieUserList = [];
			allUsers.data.listChatGroupUsers.items.forEach((chatGroup) => {
				console.log('Called 1 foreach');
				chatGroup.chat_group.chat_group_users.items.forEach((item) => {
					console.log('Called 2 foreach');
					if (item.user.id !== currentUserId) {
						temp.push(item.user);
					}
				});
			});
			console.log({ temp });
			temp.forEach(function (item) {
				let i = uniquieUserList.findIndex((x) => x.id == item.id);
				if (i <= -1) {
					uniquieUserList.push({ ...item });
				}
			});
			let chatGroupUsers = [];
			group.chat_group_users.items.forEach((item) => {
				chatGroupUsers.push(item.user.id);
			});
			let usersNotInGroup = uniquieUserList.filter(
				(item) => !chatGroupUsers.includes(item.id)
			);
			// console.log({ usersNotInGroup });
			setUserList(usersNotInGroup);
		} catch (error) {
			console.log('List users query....', error);
		}
		// return allUsers;
	};

	return (
		<>
			{createGroupSelectedUsers.length >= 1 ? (
				<List
					horizontal={true}
					itemDivider={false}
					dataArray={createGroupSelectedUsers}
					keyExtractor={(item) => item.id}
					renderRow={(user, i) => <SelectedUserListItem user={user} />}
				/>
			) : (
				<></>
			)}

			{searchText ? (
				searchUserList.length === 0 ? (
					<View
						style={{
							flex: 1,
							alignItems: 'center',
						}}>
						<Text style={{ marginVertical: 10 }}>No results found</Text>
						<Button
							rounded
							dark
							onPress={() => {
								setModalVisible(true);
							}}
							style={{ alignSelf: 'center' }}>
							<Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}>
								Send Invitation
							</Text>
						</Button>
					</View>
				) : (
					searchUserList.map((item, i) => {
						return <UserListItem item={item} />;
					})
				)
			) : (
				<List
					dataArray={userList}
					keyExtractor={(item) => item.id}
					renderRow={(user, i) => <UserListItem item={user} />}
				/>
			)}
			<Portal>
				<Dialog
					visible={modalVisible}
					style={{
						backgroundColor: '#fff',
						width: '25vw',
						height: '75vh',
						alignSelf: 'center',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Dialog.Title>Send An Invitation</Dialog.Title>
					<Dialog.Content>
						<Item>
							<Input placeholder='First Name' />
						</Item>
						<Item>
							<Input placeholder='Last Name' />
						</Item>
						<Item>
							<Input placeholder='Invite Via Email' />
						</Item>
					</Dialog.Content>
					<Dialog.Actions>
						<View style={styles.modalButtonsView}>
							<TouchableOpacity onPress={() => setModalVisible(!modalVisible)}>
								<Text>Cancel</Text>
							</TouchableOpacity>
							<Button
								rounded
								dark
								onPress={() => setModalVisible(!modalVisible)}>
								<Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}> Send</Text>
							</Button>
						</View>
					</Dialog.Actions>
				</Dialog>
			</Portal>
			{/* <Container>
				<Modal
					animationType='slide'
					transparent={true}
					visible={modalVisible}
					onRequestClose={() => {
						Alert.alert('Modal has been closed.');
					}}>
					<View style={styles.centeredView}>
						<View style={styles.modalView}>
							<Text style={styles.modalText}>Send An Invitation</Text>
							<Item>
								<Input placeholder='First Name' />
							</Item>
							<Item>
								<Input placeholder='Last Name' />
							</Item>
							<Item>
								<Input placeholder='Invite Via Email' />
							</Item>
							<View style={styles.modalButtonsView}>
								<TouchableOpacity
									onPress={() => setModalVisible(!modalVisible)}>
									<Text>Cancel</Text>
								</TouchableOpacity>
								<Button
									rounded
									dark
									onPress={() => setModalVisible(!modalVisible)}>
									<Text style={{ fontFamily: 'OPEN-SANS-BOLD' }}> Send</Text>
								</Button>
							</View>
						</View>
					</View>
				</Modal>
			</Container> */}
		</>
	);
};

export default UserListForNewUsers;
const styles = StyleSheet.create({
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22,
	},
	modalView: {
		margin: 20,
		backgroundColor: 'white',
		borderRadius: 20,
		padding: 35,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	modalText: {
		marginBottom: 15,
		textAlign: 'center',
		fontFamily: 'CRIMSON-TEXT',
	},
	modalButtonsView: {
		flexDirection: 'row',
		marginVertical: 15,
		alignItems: 'center',
		width: 300,
		justifyContent: 'space-around',
	},
});
