import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Thumbnail } from 'native-base';
import { useStore } from '../../store/index';

const UserAvatar = () => {
  let inactive = false;

  const currentUserId = useStore((state) => state.currentUserId);
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  let imageUri = null;
  currentConversationGroup.chat_group_users.items.forEach((item) => {
    if (item.user.id != currentUserId) {
      if (item.user.active_account === 0) {
        inactive = true;
      }
			imageUri = item.user.imageUri;
    }
  });
  return (
    <>
      {inactive ? (
        <View style={styles.pendingView}>
          <Text style={styles.pendingText}>Pending</Text>
        </View>
      ) : (
        <View style={styles.userImageView}>
          <Thumbnail
            large
            source={{
              uri: imageUri
                ? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${imageUri}`
                : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
            }}
          />
        </View>
      )}
    </>
  );
};

export default UserAvatar;
const styles = StyleSheet.create({
  userImageView: { alignSelf: 'center' },
  pendingText: {
    fontFamily: 'CRIMSON-TEXT',
    color: '#FFF',
  },
  pendingView: {
    width: 80,
    height: 80,
    borderRadius: 100,
    backgroundColor: '#1e1e1e',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
});
