import React, { useState, useEffect } from 'react';
import { View, Text, Switch } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Container, Item } from 'native-base';
import constainerStyles from '../AccountSettingComponents/containerStyle';
import SubHeader from '../SubHeader';
import { useStore } from '../../store/index';
import * as mutations from '../../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';

const GroupNotificationSettings = () => {
	const currentConversationGroupId = useStore(
		(state) => state.currentConversationGroupId
	);
	const [isLoading, setIsLoading] = useState(true);
	const [isEnabled, setIsEnabled] = useState(false);
	useEffect(() => {
		groupInfo();
	}, []);
	const groupInfoQuery = `query getChatGroup{
		getChatGroup(id:"${currentConversationGroupId}"){
			group_title
    		pause_notifications
		}
	}`;

	const groupInfo = async () => {
		try {
			await API.graphql(graphqlOperation(groupInfoQuery)).then((res) => {
				setIsEnabled(
					res.data.getChatGroup.pause_notifications === 0 ? false : true
				);
				setIsLoading(false);
			});
		} catch (error) {
			console.log('Error geting groupinfo', error);
		}
	};
	const toggleSwitch = async () => {
		setIsEnabled(!isEnabled);
		try {
			let res = await API.graphql({
				query: mutations.updateChatGroup,
				variables: {
					input: {
						id: currentConversationGroupId,
						pause_notifications: isEnabled ? 0 : 1,
					},
				},
			});
		} catch (error) {
			console.log('error while changing group notification settings', error);
		}
	};
	return (
		<>
			{isLoading ? (
				<Text>Loading......</Text>
			) : (
				<Container style={[constainerStyles.container]}>
					<SubHeader text={'Push Notifications'} />
					<View style={{ flexDirection: 'row', paddingVertical: 10 }}>
						<MaterialCommunityIcons name='bell-sleep' size={30} />
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginLeft: 10,
								borderBottomWidth: 1,
								borderColor: '#d7d7d8',
								paddingBottom: 15,
							}}>
							<Text>Pause All</Text>
							<Switch
								trackColor={{ false: '#767577', true: '#C10644' }}
								thumbColor={isEnabled ? '#1e1e1e' : '#f4f3f4'}
								ios_backgroundColor='#3e3e3e'
								onValueChange={toggleSwitch}
								value={isEnabled}
							/>
						</View>
					</View>
				</Container>
			)}
		</>
	);
};

export default GroupNotificationSettings;
