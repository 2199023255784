import React, { useEffect, useState } from 'react';
import {
  View,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  TouchableOpacity,
  Alert,
  Platform,
  Image,
} from 'react-native';
import { Button, Text, Spinner } from 'native-base';
import { Auth } from 'aws-amplify';
import * as ImagePicker from 'expo-image-picker';
import { useStore } from '../../store/index';
import SubHeader from '../../components/SubHeader';
import AccountSettingButton from '../../components/Button';
import AccountScreenItem from '../../components/AccountScreenItem';
import { Thumbnail } from 'native-base';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import Logout from '../../components/AccountSettingComponents/Logout';
import AppHeader from '../../components/AppHeader';
import { updateUser } from '../../../graphql/mutations';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { VersionNumber } from '../LoginScreen/index';

const AccountScreenComponent = ({ navigation }) => {
  const currentUserId = useStore((state) => state.currentUserId);
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const currentUser = useStore((state) => state.currentUser);
  const [firstName, setFirstName] = useState('');
  const [imageUri, setImageUri] = useState('');
  const [loggedInWith, setLoggedInWith] = useState('');
  const [startSpinner, setStartSpinner] = useState(false);
  console.log({ currentUserId });
  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  useEffect(() => {
    getUser();
    loadData();
  }, []);

  useEffect(() => {
    navigation.addListener('focus', () => getUser());
  }, []);

  const loadData = async () => {
    try {
      const value = await AsyncStorage.getItem('@loggedInWith');
      console.log('Data successfully loaded', value);
      if (value !== null) {
        setLoggedInWith(value);

        return value;
      }
    } catch (e) {
      console.log('Failed to load the data from the storage');
    }
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const {
          status,
        } = await ImagePicker.requestCameraRollPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.1,
    });

    console.log(result);

    if (!result.cancelled) {
      uploadToStorage(result.uri);
    }
  };

  const uploadToStorage = async (pathToImageFile) => {
    try {
      setStartSpinner(true);
      const response = await fetch(pathToImageFile);

      const blob = await response.blob();

      Storage.put(`${firstName}${uuidv4()}.jpeg`, blob, {
        contentType: 'image/jpeg',
      }).then((result) => {
        console.log('storage from result', result);
        setImageUri(result.key);
        updateProfilePic(result);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateProfilePic = async (picData) => {
    try {
      let res = await API.graphql({
        query: updateUser,
        variables: {
          input: {
            id: currentUserId,
            imageUri: picData.key,
          },
        },
      });
      setStartSpinner(false);
      console.log('Update user image', res);
    } catch (error) {
      console.log('Could not upload user image', error);
    }
  };

  let getUserInfo = `query MyQuery {
  		getUser(id:"${currentUserId}") {
    	first_name
    	imageUri
  		}
	}`;

  const getUser = async () => {
    try {
      await API.graphql(graphqlOperation(getUserInfo)).then((res) => {
        // console.log('api response from getUser.....', res);
        setFirstName(res.data.getUser.first_name);
        setImageUri(res.data.getUser.imageUri);
      });
    } catch (error) {
      console.log('error from fetching user info.....', error);
    }
  };

  return (
    <>
      <AppHeader />
      <ScrollView style={styles.container}>
        <View style={styles.thumbnailView}>
          {!startSpinner ? (
            <TouchableOpacity onPress={pickImage}>
              <View style={{ marginBottom: 10 }}>
                <Image
                  source={{
                    uri: imageUri
                      ? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${imageUri}`
                      : 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
                  }}
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 100,
                    opacity: 0.85,
                    position: 'absolute',
                    // height: '100%',
                  }}
                />
                <View style={styles.bgImageOverlay}>
                  <MaterialCommunityIcons
                    name="camera"
                    size={25}
                    color="#fff"
                    style={{ alignSelf: 'center' }}
                  />
                </View>
              </View>
              {/* <View style={{}}>
								<Thumbnail
									large
									source={{
										uri: imageUri
											? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${imageUri}`
											: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
									}}
								/>
								<View style={{ alignSelf: 'flex-end', position: 'absolute' }}>
									<MaterialCommunityIcons
										name='pencil'
										size={30}
										color='#FBB03B'
									/>
								</View>
							</View> */}
            </TouchableOpacity>
          ) : (
            <Spinner color="#000" />
          )}

          <SubHeader text={firstName} />
        </View>
        <View style={styles.personalInformationView}>
          <SubHeader text={'Personal Information'} />
          <AccountScreenItem
            itemIcon={'account-circle'}
            itemName={'Name'}
            navigation={navigation}
            screenName={'Name'}
          />
          <AccountScreenItem
            itemIcon={'phone'}
            itemName={'Phone'}
            navigation={navigation}
            screenName={'PhoneNumber'}
          />
          <AccountScreenItem
            itemIcon={'email'}
            itemName={'Email'}
            navigation={navigation}
            screenName={'Email'}
          />
        </View>
        <View style={styles.settingAndPriacyView}>
          <SubHeader text={'Settings and Privacy'} />
          <AccountScreenItem
            itemIcon={'message-text'}
            itemName={'Message of the Day Settings'}
            navigation={navigation}
            screenName={'MessageOfTheDay'}
          />
          <AccountScreenItem
            itemIcon={'login-variant'}
            itemName={`Signed in with ${loggedInWith}`}
            navigation={navigation}
            screenName={'SignedInWith'}
          />
          <AccountScreenItem
            itemIcon={'bell'}
            itemName={'Notification Settings'}
            navigation={navigation}
            screenName={'NotificationSettings'}
          />
          <AccountScreenItem
            itemIcon={'information'}
            itemName={'FAQ'}
            navigation={navigation}
            screenName={'FAQ'}
          />
          <AccountScreenItem
            itemIcon={'help-circle'}
            itemName={'Support'}
            navigation={navigation}
            screenName={'Support'}
          />
          <AccountScreenItem
            itemIcon={'lock'}
            itemName={'Privacy and Terms of Use'}
            navigation={navigation}
            screenName={'PrivacyAndTermsOfUse'}
          />
          <AccountScreenItem
            itemIcon={'information'}
            itemName={'About Us'}
            navigation={navigation}
            screenName={'AboutUs'}
          />
          <AccountScreenItem
            itemIcon={'google-circles-group'}
            itemName={<VersionNumber />}
            navigation={navigation}
            screenName={'AccountScreen'}
          />
        </View>

        <Logout />
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    maxWidth: 1160,
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: '50vw',
    paddingHorizontal: 10,
  },
  thumbnailView: {
    flex: 1,
    alignItems: 'center',
    marginVertical: 15,
  },
  personalInformationView: {
    marginVertical: 15,
  },
  settingAndPriacyView: {
    marginVertical: 15,
  },
  buttonView: {
    flex: 1,
    marginVertical: 30,
  },
  bgImageOverlay: {
    width: 80,
    height: 80,
    // marginTop: 40,
    borderRadius: 100,
    backgroundColor: '#00000050',
    justifyContent: 'flex-end',
    paddingBottom: 5,
  },
});
export default AccountScreenComponent;
