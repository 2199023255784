import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { Container } from 'native-base';
import { API, graphqlOperation } from 'aws-amplify';
import styles from './styles';
import Advertisment from '../../components/Advertisment';
import Header from '../../components/AppHeader';
import { useStore } from '../../store/index';
import Circles from '../../components/Circles';
import { useFocusEffect } from '@react-navigation/native';

const CirclesScreen = () => {
  const currentUserId = useStore((state) => state.currentUserId);
  const [currentUserGroups, setCurrentUserGroups] = useState([]);
  const globalCurrentUserGroups = useStore(
    (state) => state.globalCurrentUserGroups
  );

  const listChatGroups = `query listChatGroupUsers {
		listChatGroupUsers(filter: {user_id: {eq: "${currentUserId}"}},limit:1000) {
			items {
				chat_group {
					group_title
					id
					created_by
					vip_user_id
					pause_notifications
					chat_group_users {
						items {
							id
							user_id
							user {
								id
								first_name
								imageUri
								last_name
								active_account
							}
						}
					}
				}
			}
		}
		}`;
  useFocusEffect(
    React.useCallback(() => {
      getGroups();
    }, [globalCurrentUserGroups])
  );
  const getGroups = async () => {
    try {
      await API.graphql(graphqlOperation(listChatGroups)).then((res) => {
        setCurrentUserGroups(res.data.listChatGroupUsers.items);
      });
    } catch (error) {
      console.log('err fetching groups...', error);
    }
  };
  return (
    <Container>
      <Header />
      <ScrollView style={styles.container}>
        {/* <View style={{ flex: 1 }}>
          <Advertisment />
        </View> */}
        <View style={{ flex: 9 }}>
          <Circles screen={'Circles'} currentUserGroups={currentUserGroups} />
        </View>
      </ScrollView>
    </Container>
  );
};

export default CirclesScreen;
