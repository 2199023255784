/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      first_name
      last_name
      email
      phone_number
      imageUri
      status
      pause_notifications
      qotd
      bible
      french
      spanish
      active_account
      role
      chat_group_user {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      first_name
      last_name
      email
      phone_number
      imageUri
      status
      pause_notifications
      qotd
      bible
      french
      spanish
      active_account
      role
      chat_group_user {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      first_name
      last_name
      email
      phone_number
      imageUri
      status
      pause_notifications
      qotd
      bible
      french
      spanish
      active_account
      role
      chat_group_user {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatGroupUser = /* GraphQL */ `
  mutation CreateChatGroupUser(
    $input: CreateChatGroupUserInput!
    $condition: ModelChatGroupUserConditionInput
  ) {
    createChatGroupUser(input: $input, condition: $condition) {
      id
      user_id
      is_vip
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatGroupUser = /* GraphQL */ `
  mutation UpdateChatGroupUser(
    $input: UpdateChatGroupUserInput!
    $condition: ModelChatGroupUserConditionInput
  ) {
    updateChatGroupUser(input: $input, condition: $condition) {
      id
      user_id
      is_vip
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatGroupUser = /* GraphQL */ `
  mutation DeleteChatGroupUser(
    $input: DeleteChatGroupUserInput!
    $condition: ModelChatGroupUserConditionInput
  ) {
    deleteChatGroupUser(input: $input, condition: $condition) {
      id
      user_id
      is_vip
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createChatGroup = /* GraphQL */ `
  mutation CreateChatGroup(
    $input: CreateChatGroupInput!
    $condition: ModelChatGroupConditionInput
  ) {
    createChatGroup(input: $input, condition: $condition) {
      id
      group_title
      pause_notifications
      created_by
      vip_user_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group_users {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      last_message_id
      last_message {
        id
        createdAt
        content
        user_id
        chat_group_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group {
          id
          group_title
          pause_notifications
          created_by
          vip_user_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group_users {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          messages {
            items {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            nextToken
          }
          last_message_id
          last_message {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatGroup = /* GraphQL */ `
  mutation UpdateChatGroup(
    $input: UpdateChatGroupInput!
    $condition: ModelChatGroupConditionInput
  ) {
    updateChatGroup(input: $input, condition: $condition) {
      id
      group_title
      pause_notifications
      created_by
      vip_user_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group_users {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      last_message_id
      last_message {
        id
        createdAt
        content
        user_id
        chat_group_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group {
          id
          group_title
          pause_notifications
          created_by
          vip_user_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group_users {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          messages {
            items {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            nextToken
          }
          last_message_id
          last_message {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatGroup = /* GraphQL */ `
  mutation DeleteChatGroup(
    $input: DeleteChatGroupInput!
    $condition: ModelChatGroupConditionInput
  ) {
    deleteChatGroup(input: $input, condition: $condition) {
      id
      group_title
      pause_notifications
      created_by
      vip_user_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group_users {
        items {
          id
          user_id
          is_vip
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        nextToken
      }
      last_message_id
      last_message {
        id
        createdAt
        content
        user_id
        chat_group_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group {
          id
          group_title
          pause_notifications
          created_by
          vip_user_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group_users {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          messages {
            items {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            nextToken
          }
          last_message_id
          last_message {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          createdAt
          updatedAt
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createScheduledMessage = /* GraphQL */ `
  mutation CreateScheduledMessage(
    $input: CreateScheduledMessageInput!
    $condition: ModelScheduledMessageConditionInput
  ) {
    createScheduledMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      message_schedule
      messageScheduleTime
      messageScheduleTimeMin
      messageScheduleTimeMon
      messageScheduleTimeDay
      messageScheduleTimeDayWK
      messageScheduleTimeYr
      messageStream
      followup_message
      followup_schedule
      followMessageTime
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateScheduledMessage = /* GraphQL */ `
  mutation UpdateScheduledMessage(
    $input: UpdateScheduledMessageInput!
    $condition: ModelScheduledMessageConditionInput
  ) {
    updateScheduledMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      message_schedule
      messageScheduleTime
      messageScheduleTimeMin
      messageScheduleTimeMon
      messageScheduleTimeDay
      messageScheduleTimeDayWK
      messageScheduleTimeYr
      messageStream
      followup_message
      followup_schedule
      followMessageTime
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteScheduledMessage = /* GraphQL */ `
  mutation DeleteScheduledMessage(
    $input: DeleteScheduledMessageInput!
    $condition: ModelScheduledMessageConditionInput
  ) {
    deleteScheduledMessage(input: $input, condition: $condition) {
      id
      createdAt
      content
      message_schedule
      messageScheduleTime
      messageScheduleTimeMin
      messageScheduleTimeMon
      messageScheduleTimeDay
      messageScheduleTimeDayWK
      messageScheduleTimeYr
      messageStream
      followup_message
      followup_schedule
      followMessageTime
      user_id
      chat_group_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      chat_group {
        id
        group_title
        pause_notifications
        created_by
        vip_user_id
        user {
          id
          first_name
          last_name
          email
          phone_number
          imageUri
          status
          pause_notifications
          qotd
          bible
          french
          spanish
          active_account
          role
          chat_group_user {
            items {
              id
              user_id
              is_vip
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        chat_group_users {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        messages {
          items {
            id
            createdAt
            content
            user_id
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            updatedAt
          }
          nextToken
        }
        last_message_id
        last_message {
          id
          createdAt
          content
          user_id
          chat_group_id
          user {
            id
            first_name
            last_name
            email
            phone_number
            imageUri
            status
            pause_notifications
            qotd
            bible
            french
            spanish
            active_account
            role
            chat_group_user {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          chat_group {
            id
            group_title
            pause_notifications
            created_by
            vip_user_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group_users {
              items {
                id
                user_id
                is_vip
                chat_group_id
                createdAt
                updatedAt
              }
              nextToken
            }
            messages {
              items {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              nextToken
            }
            last_message_id
            last_message {
              id
              createdAt
              content
              user_id
              chat_group_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group {
                id
                group_title
                pause_notifications
                created_by
                vip_user_id
                last_message_id
                createdAt
                updatedAt
              }
              updatedAt
            }
            createdAt
            updatedAt
          }
          updatedAt
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createDailyMessage = /* GraphQL */ `
  mutation CreateDailyMessage(
    $input: CreateDailyMessageInput!
    $condition: ModelDailyMessageConditionInput
  ) {
    createDailyMessage(input: $input, condition: $condition) {
      id
      createdAt
      scheduled_date
      content
      author
      author_id
      message_type
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateDailyMessage = /* GraphQL */ `
  mutation UpdateDailyMessage(
    $input: UpdateDailyMessageInput!
    $condition: ModelDailyMessageConditionInput
  ) {
    updateDailyMessage(input: $input, condition: $condition) {
      id
      createdAt
      scheduled_date
      content
      author
      author_id
      message_type
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteDailyMessage = /* GraphQL */ `
  mutation DeleteDailyMessage(
    $input: DeleteDailyMessageInput!
    $condition: ModelDailyMessageConditionInput
  ) {
    deleteDailyMessage(input: $input, condition: $condition) {
      id
      createdAt
      scheduled_date
      content
      author
      author_id
      message_type
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    createFAQ(input: $input, condition: $condition) {
      id
      title
      content
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    updateFAQ(input: $input, condition: $condition) {
      id
      title
      content
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFAQInput!
    $condition: ModelFAQConditionInput
  ) {
    deleteFAQ(input: $input, condition: $condition) {
      id
      title
      content
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSupport = /* GraphQL */ `
  mutation CreateSupport(
    $input: CreateSupportInput!
    $condition: ModelSupportConditionInput
  ) {
    createSupport(input: $input, condition: $condition) {
      id
      name
      email
      message
      status
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSupport = /* GraphQL */ `
  mutation UpdateSupport(
    $input: UpdateSupportInput!
    $condition: ModelSupportConditionInput
  ) {
    updateSupport(input: $input, condition: $condition) {
      id
      name
      email
      message
      status
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSupport = /* GraphQL */ `
  mutation DeleteSupport(
    $input: DeleteSupportInput!
    $condition: ModelSupportConditionInput
  ) {
    deleteSupport(input: $input, condition: $condition) {
      id
      name
      email
      message
      status
      author_id
      user {
        id
        first_name
        last_name
        email
        phone_number
        imageUri
        status
        pause_notifications
        qotd
        bible
        french
        spanish
        active_account
        role
        chat_group_user {
          items {
            id
            user_id
            is_vip
            chat_group_id
            user {
              id
              first_name
              last_name
              email
              phone_number
              imageUri
              status
              pause_notifications
              qotd
              bible
              french
              spanish
              active_account
              role
              chat_group_user {
                nextToken
              }
              createdAt
              updatedAt
            }
            chat_group {
              id
              group_title
              pause_notifications
              created_by
              vip_user_id
              user {
                id
                first_name
                last_name
                email
                phone_number
                imageUri
                status
                pause_notifications
                qotd
                bible
                french
                spanish
                active_account
                role
                createdAt
                updatedAt
              }
              chat_group_users {
                nextToken
              }
              messages {
                nextToken
              }
              last_message_id
              last_message {
                id
                createdAt
                content
                user_id
                chat_group_id
                updatedAt
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
