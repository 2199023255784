import React, { useState } from 'react';
import {
  View,
  ScrollView,
  Image,
  Text,
  SafeAreaView,
  ActivityIndicator,
} from 'react-native';
import { Container } from 'native-base';
import { styles } from './styles';
import { useStore } from '../../store';
import Advertisment from '../../components/Advertisment';
import Circles from '../../components/Circles';
import CirclesHeader from '../../components/CirclesHeader';
import MessageOfTheDay from '../../components/MessageOfTheDay';
import Header from '../../components/AppHeader';
import { API, graphqlOperation } from 'aws-amplify';
import { useFocusEffect } from '@react-navigation/native';

export default function HomeScreen({ navigation }) {
  const currentUser = useStore((state) => state.currentUser);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const currentUserId = useStore((state) => state.currentUserId);
  const [currentUserGroups, setCurrentUserGroups] = useState([]);
  const globalCurrentUserGroups = useStore(
    (state) => state.globalCurrentUserGroups
  );
  const [isLoading, setIsLoading] = useState(true);
  // const expoToken = useStore((state) => state.expoToken);
  const listChatGroups = `query listChatGroupUsers {
		listChatGroupUsers(filter: {user_id: {eq: "${currentUserId}"}},limit:1000) {
			items {
				chat_group {
					group_title
					id
					created_by
					vip_user_id
					pause_notifications
					chat_group_users {
						items {
							id
							user_id
							user {
								id
								first_name
								imageUri
								last_name
								active_account
							}
						}
					}
				}
			}
		}
		}`;
  useFocusEffect(
    React.useCallback(() => {
      getGroups();
    }, [globalCurrentUserGroups, currentUserId])
  );

  const getGroups = async () => {
    try {
      await API.graphql(graphqlOperation(listChatGroups)).then((res) => {
        setCurrentUserGroups(res.data.listChatGroupUsers.items);
      });
      setIsLoading(false);
    } catch (error) {
      console.log('err fetching groups...', error);
    }
  };

  return (
    <Container>
      {isLoading ? (
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator color="#000" size="large" />
        </View>
      ) : (
        <>
          <Header />
          <ScrollView
            style={styles.container}
            contentContainerStyle={{ flexGrow: 1 }}
          >
            {/* <View style={styles.flexContainer1}>
              <Advertisment />
            </View> */}
            <View style={styles.flexContainer2}>
              <CirclesHeader />
              <Circles screen={'Home'} currentUserGroups={currentUserGroups} />
            </View>
            <View style={styles.flexContainer3}>
              <MessageOfTheDay />
            </View>
          </ScrollView>
          {/* <Text style={{ height: 0, width: 1 }}>{expoToken}</Text> */}
        </>
      )}
    </Container>
  );
}
