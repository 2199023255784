import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
	container: {
		height: '100%',
		backgroundColor: '#fff',
	},
});

export default styles;
