import React, { useState, useEffect } from 'react';
import { TouchableWithoutFeedback, Keyboard } from 'react-native';
import { Container, Form, Item, Input, Text, View, Label } from 'native-base';
import { useNavigation } from '@react-navigation/native';

import containerStyles from '../AccountSettingComponents/containerStyle';
import SubHeader from '../SubHeader';
import { useStore } from '../../store';

const CustomFollowUp = () => {
	const navigation = useNavigation();
	const followMessage = useStore((state) => state.followMessage);
	const setFollowMessage = useStore((state) => state.setFollowMessage);
	const customAutoFollowMessageBool = useStore(
		(state) => state.customAutoFollowMessageBool
	);
	const setCustomAutoFollowMessageBool = useStore(
		(state) => state.setCustomAutoFollowMessageBool
	);
	const customAutoFollowMessageText = useStore(
		(state) => state.customAutoFollowMessageText
	);
	const setCustomAutoFollowMessageText = useStore(
		(state) => state.setCustomAutoFollowMessageText
	);
	const customAutoFollowMessageTextEdited = useStore(
		(state) => state.customAutoFollowMessageTextEdited
	);
	const setCustomAutoFollowMessageTextEdited = useStore(
		(state) => state.setCustomAutoFollowMessageTextEdited
	);
	const [customMessage2, setCustomMessage2] = useState(
		!customAutoFollowMessageTextEdited
			? customAutoFollowMessageText
			: followMessage
	);
	const [newCustomMessage2, setNewCustomMessage2] = useState('');
	// 	setFollowMessage("How are you doing today?");

	useEffect(() => {
		newCustomMessage2 == ''
			? setCustomAutoFollowMessageBool(false)
			: setCustomAutoFollowMessageBool(true);
	}, [newCustomMessage2]);
	return (
		<TouchableWithoutFeedback onPress={Keyboard.dismiss}>
			<Container style={containerStyles.container}>
				<SubHeader text={'Custom Follow-up Message'} />
				<Form>
					<Item style={{ borderBottomColor: '#1e1e1e' }}>
						<Input
							placeholder='Custom Response goes here.'
							value={customMessage2}
							style={{ fontFamily: 'CRIMSON-TEXT' }}
							maxLength={250}
							onChangeText={(text) => (
								setCustomMessage2(text),
								setNewCustomMessage2(text),
								setCustomAutoFollowMessageTextEdited(true)
							)}
							onBlur={() => setFollowMessage(customMessage2)}></Input>
					</Item>
					<View
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							fontFamily: 'CRIMSON-TEXT',
						}}>
						<Text style={{ fontFamily: 'CRIMSON-TEXT' }}>
							{customMessage2?.length | 0}/250
						</Text>
						<Text style={{ fontFamily: 'CRIMSON-TEXT' }}>Character Limit</Text>
					</View>
				</Form>
			</Container>
		</TouchableWithoutFeedback>
	);
};

export default CustomFollowUp;
