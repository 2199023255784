import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Thumbnail } from 'native-base';

const GroupCircleContainerNew = ({ group }) => {
	let activeAccountCount = 0;
	return (
		<>
			{group.chat_group_users.items.forEach((member, i) => {
				if (member.user.active_account === 1) {
					activeAccountCount++;
				}
			})}
			{activeAccountCount <= 2 ? (
				<View style={styles.pendingGroup}>
					<Text style={{ fontFamily: 'CRIMSON-TEXT', color: '#FFF' }}>
						Pending
					</Text>
				</View>
			) : (
				<View style={styles.groupContainer}>
					{group.chat_group_users.items.map((member, j) => {
						if (j < 3) {
							return (
								<View key={member.user.id}>
									<Thumbnail
										source={{
											uri: member.user.imageUri
												? `https://howyoube-mobile22815-dev.s3.amazonaws.com/public/${member.user.imageUri}`
												: 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png',
										}}
										style={{ height: 35, width: 35, borderRadius: 100 }}
									/>
								</View>
							);
						}
					})}
					<>
						{group.chat_group_users.items.length > 3 && (
							<Text>+{group.chat_group_users.items.length - 3}</Text>
						)}
					</>
				</View>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	groupContainer: {
		width: 88,
		height: 88,
		borderRadius: 100,
		flex: 1,
		flexDirection: 'row',
		flexWrap: 'wrap',
		borderWidth: 1,
		borderColor: '#FBB03B',
		justifyContent: 'space-evenly',
		alignContent: 'stretch',
		alignItems: 'center',
		alignSelf: 'center',
		padding: 8,
	},
	pendingGroup: {
		width: 88,
		height: 88,
		borderRadius: 100,
		backgroundColor: '#1e1e1e',
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
	},
});

export default GroupCircleContainerNew;
