import React, { useState, useEffect } from 'react';
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	Alert,
	TextInput,
} from 'react-native';
import moment from 'moment';
import { useStore } from '../../store';

const TimeMeridiemSettings = ({ schedTime, schedTimeMin }) => {
	useEffect(() => {
		updateSchedTime();
		Number(schedTime) > 12 ? setSelected(false) : setSelected(true);
	}, [schedTime, schedTimeMin]);
	const schedHr =
		Number(schedTime) > 12
			? (Number(schedTime) - 12).toLocaleString(undefined, {
					minimumIntegerDigits: 2,
			  })
			: schedTime.toString();

	const updateSchedTime = () => {
		setHour(schedHr || '07');
		setMinute(schedTimeMin.toString() || '00');
	};
	const [isSelected, setSelected] = useState(true);
	// const [value, onChangeText] = useState('10:45');
	const [hour, setHour] = useState('07');
	const [minute, setMinute] = useState('00');
	const setMessageScheduleTime = useStore(
		(state) => state.setMessageScheduleTime
	);
	const setMessageScheduleTimeMin = useStore(
		(state) => state.setMessageScheduleTimeMin
	);
	const setMessageScheduleTimeMon = useStore(
		(state) => state.setMessageScheduleTimeMon
	);
	const setMessageScheduleTimeDay = useStore(
		(state) => state.setMessageScheduleTimeDay
	);
	const setMessageScheduleTimeYr = useStore(
		(state) => state.setMessageScheduleTimeYr
	);

	React.useEffect(() => {
		if (isSelected) {
			let hr = moment(hour, 'hh').format('HH');
			let min = moment(minute, 'mm').format('mm');
			setMessageScheduleTime(hr);
			setMessageScheduleTimeMin(min);
		} else {
			let hr = moment(hour, 'hh').add(12, 'hours').format('HH');
			let min = moment(minute, 'mm').add(12, 'hours').format('mm');
			setMessageScheduleTime(hr);
			setMessageScheduleTimeMin(min);
		}
		let month = moment().format('M');
		let day = moment().format('D');
		let year = moment().format('YYYY');
		setMessageScheduleTimeMon(month);
		setMessageScheduleTimeDay(day);
		setMessageScheduleTimeYr(year);
	}, [hour, minute, isSelected]);

	return (
		<View style={styles.container}>
			<Text style={styles.header}>Time</Text>
			<View style={{ flexDirection: 'row', alignSelf: 'flex-end' }}>
				<View style={styles.textInputContainer}>
					<TextInput
						onChangeText={(text) => setHour(text)}
						value={hour}
						style={styles.textInput}
						maxLength={2}
						keyboardType={'numeric'}
					/>
					<Text
						style={{
							fontWeight: '700',
							fontSize: 20,
							fontFamily: 'OPEN-SANS-BOLD',
							marginRight: 2,
						}}>
						:
					</Text>
					<TextInput
						onChangeText={(text) => setMinute(text)}
						value={minute}
						maxLength={2}
						keyboardType={'numeric'}
						style={styles.textInput}
					/>
				</View>

				<TouchableOpacity
					onPress={() => {
						isSelected ? setSelected(false) : setSelected(true);
					}}>
					<View style={styles.ampmcontainer}>
						<View
							style={[styles.am, isSelected && { backgroundColor: '#C10644' }]}>
							<Text
								style={[
									styles.text,
									isSelected ? { color: '#fff' } : { color: '#000' },
								]}>
								AM
							</Text>
						</View>
						<View
							style={[
								styles.pm,
								!isSelected && { backgroundColor: '#C10644' },
							]}>
							<Text
								style={[
									styles.text,
									!isSelected ? { color: '#fff' } : { color: '#000' },
								]}>
								PM
							</Text>
						</View>
					</View>
				</TouchableOpacity>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	header: {
		fontWeight: '600',
		fontSize: 15,
		fontFamily: 'OPEN-SANS-BOLD',
		alignSelf: 'center',
	},
	container: {
		marginVertical: 20,
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	ampmcontainer: {
		borderWidth: 1,
		height: 35,
		width: 80,
		borderRadius: 5,
		flexDirection: 'row',
	},
	am: {
		alignItems: 'center',
		justifyContent: 'center',
		width: 40,
		borderRadius: 5,
	},
	pm: {
		width: 40,
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: 5,
	},
	text: {
		fontWeight: '700',
		fontSize: 20,
		fontFamily: 'OPEN-SANS-BOLD',
	},
	textInput: {
		fontWeight: '700',
		fontSize: 20,
		fontFamily: 'OPEN-SANS-BOLD',
		width: 25,
		// paddingHorizontal:2
		// borderWidth: 1,
	},
	textInputContainer: {
		borderWidth: 1,
		borderRadius: 5,
		flexDirection: 'row',
		width: 80,
		marginHorizontal: 10,
		paddingHorizontal: 10,
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
});
export default TimeMeridiemSettings;
