import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Header, Left, Body, Right, Title } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome, FontAwesome5 } from '@expo/vector-icons';
import { useStore } from '../store/index';

const ConversationHeadr = () => {
	const navigation = useNavigation();
	const currentUserId = useStore((state) => state.currentUserId);
	const currentConversationGroup = useStore(
		(state) => state.currentConversationGroup
	);

	return (
		<Header androidStatusBarColor='#fff' style={styles.header}>
			<Left>
				<TouchableOpacity
					onPress={() => {
						navigation.goBack();
					}}>
					<FontAwesome5 name='chevron-left' size={30} />
				</TouchableOpacity>
			</Left>
			<Body>
				<Title style={styles.body}>
					{currentConversationGroup.group_title}
				</Title>
			</Body>
			<Right></Right>
		</Header>
	);
};

const styles = StyleSheet.create({
	header: { backgroundColor: '#fff' },
	body: { color: '#000' },
});
export default ConversationHeadr;
