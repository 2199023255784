import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Linking,
} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Dialog, Portal, Button } from 'react-native-paper';
import * as WebBrowser from 'expo-web-browser';
import Clipboard from '@react-native-clipboard/clipboard';

const ShareButton = ({ color, content }) => {
  const [visible, setVisible] = useState(false);

  const showDialog = () => setVisible(true);

  const hideDialog = () => setVisible(false);
  const [copiedText, setCopiedText] = useState('');

  const link = 'howyoube.app.link';
  const copyToClipboard = () => {
    Clipboard.setString(`${link} Message is ${content}`);
  };
  return (
    <>
      <TouchableOpacity onPress={showDialog}>
        <MaterialCommunityIcons name="share-variant" color={color} size={30} />
        <Portal>
          <Dialog
            visible={visible}
            onDismiss={hideDialog}
            style={{
              minWidth: '25vw',
              backgroundColor: '#fff',
              alignSelf: 'center',
            }}
          >
            <Dialog.Title style={{ color: '#1e1e1e' }}>Share</Dialog.Title>
            <Dialog.Content
              style={{ flexDirection: 'row', justifyContent: 'space-around' }}
            >
              <TouchableOpacity>
                <MaterialCommunityIcons
                  name="facebook"
                  size={24}
                  color="#3b5998"
                  onPress={async () => {
                    WebBrowser.openBrowserAsync(
                      `https://www.facebook.com/sharer/sharer.php?u=${link}`
                    );
                  }}
                />
              </TouchableOpacity>
              {/* <TouchableOpacity>
                <MaterialCommunityIcons
                  name="instagram"
                  size={24}
                  color="#e1306c"
                />
              </TouchableOpacity> */}
              <TouchableOpacity>
                <MaterialCommunityIcons
                  name="linkedin"
                  size={24}
                  color="#0077b5"
                  onPress={async () => {
                    WebBrowser.openBrowserAsync(
                      `https://www.linkedin.com/shareArticle?url=${link}&title=${content}`
                    );
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity>
                <MaterialCommunityIcons
                  name="whatsapp"
                  size={24}
                  color="#075e54"
                  onPress={async () => {
                    WebBrowser.openBrowserAsync(
                      `https://api.whatsapp.com/send?text=${content} ${link}`
                    );
                  }}
                />
              </TouchableOpacity>
              <TouchableOpacity>
                <MaterialCommunityIcons
                  name="content-copy"
                  size={24}
                  color="black"
                  onPress={copyToClipboard}
                />
              </TouchableOpacity>
              <TouchableOpacity>
                <MaterialCommunityIcons
                  name="email-outline"
                  size={24}
                  color="black"
                  onPress={async () => {
                    Linking.openURL(
                      `mailto:somethingemail@gmail.com?subject=How You Be Message&body=${content} `
                    );
                  }}
                />
              </TouchableOpacity>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onPress={hideDialog}>
                <Text style={{ color: '#1e1e1e' }}>Done</Text>
              </Button>
            </Dialog.Actions>
          </Dialog>
        </Portal>
      </TouchableOpacity>
    </>
  );
};

export default ShareButton;
