import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
// import GroupConverstionAndHeader from '../../components/GroupConversatinAndHeader';
import SubHeader from '../../components/SubHeader';
import GroupSettingItem from '../../components/GroupSettingsItem';
import containerStyles from '../../components/AccountSettingComponents/containerStyle';
import ConversationHeader from '../../components/ConversationHeader';
import { useStore } from '../../store/index';

import styles from './styles';
const GroupSettings = ({ route, navigation }) => {
	const { familyName, group } = route.params;
	const currentConversationGroup = useStore(
		(state) => state.currentConversationGroup
	);
	const setCurrentConversationGroup = useStore(
		(state) => state.setCurrentConversationGroup
	);
	console.log('gorupid=', group.id);
	setCurrentConversationGroup(group);
	console.log('Mounting group id......', currentConversationGroup);
	return (
		<View style={styles.container}>
			<ConversationHeader />
			{/* <GroupConverstionAndHeader familyName={familyName} group={group} /> */}
			<View
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}></View>
			<View style={[containerStyles.container, { marginVertical: 25 }]}>
				<Text
					style={{
						fontFamily: 'OPEN-SANS-BOLD',
						fontWeight: '700',
						fontSize: 16,
					}}>
					Actions
				</Text>
				<GroupSettingItem
					itemIcon={'clock-outline'}
					itemName={'Schedule & Auto-Generated Messages'}
					screenName={'schudleAndAutoMessages'}
					navigation={navigation}
				/>
				<GroupSettingItem
					itemIcon={'account-group'}
					itemName={'Circle Members'}
					screenName={'groupMembers'}
					navigation={navigation}
				/>
				<GroupSettingItem
					itemIcon={'rename-box'}
					itemName={'Circle Name'}
					screenName={'groupName'}
					navigation={navigation}
				/>
				<GroupSettingItem
					itemIcon={'bell'}
					itemName={'Circle Notification Settings'}
					screenName={'groupNotificationSettings'}
					navigation={navigation}
				/>
			</View>
		</View>
	);
};

export default GroupSettings;
