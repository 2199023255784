import React, { useState } from 'react';
import { View, Text, StyleSheet, Switch } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import { Container, Item } from 'native-base';
import AccountSettingsHeader from './AccountSettingsHeader';
import SubHeader from '../SubHeader';
import constainerStyles from './containerStyle';
import { useStore } from '../../store/index';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';

const NotificationSettings = () => {
	const currentUser = useStore((state) => state.currentUser);
	const setCurrentUser = useStore((state) => state.setCurrentUser);
	const currentUserId = useStore((state) => state.currentUserId);
	const [isEnabled, setIsEnabled] = useState(
		currentUser.pause_notifications === 0 ? false : true
	);
	const toggleSwitch = async () => {
		setIsEnabled(!isEnabled);
		setCurrentUser(
			{ ...currentUser },
			(currentUser.pause_notifications = isEnabled ? 0 : 1)
		);
		try {
			let res = await API.graphql({
				query: mutations.updateUser,
				variables: {
					input: {
						id: currentUserId,
						pause_notifications: isEnabled ? 0 : 1,
					},
				},
			});
			setCurrentUser(
				{ ...currentUser },
				(currentUser.pause_notifications =
					res.data.updateUser.pause_notifications)
			);
		} catch (error) {
			console.log('Error while updateing notification settings,', error);
		}
	};
	return (
		<>
			<AccountSettingsHeader screenName={'Notification Settings'} />
			<Container style={[constainerStyles.container]}>
				<SubHeader text={'Push Notifications'} />
				<View style={{ flexDirection: 'row', paddingVertical: 10 }}>
					<MaterialCommunityIcons name='bell-sleep' size={30} />
					<View
						style={{
							flex: 1,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginLeft: 10,
							borderBottomWidth: 1,
							borderColor: '#d7d7d8',
							paddingBottom: 15,
						}}>
						<Text>Pause All</Text>
						<Switch
							trackColor={{ false: '#767577', true: '#C10644' }}
							thumbColor={isEnabled ? '#1e1e1e' : '#f4f3f4'}
							ios_backgroundColor='#3e3e3e'
							onValueChange={toggleSwitch}
							value={isEnabled}
						/>
					</View>
				</View>
			</Container>
		</>
	);
};

const styles = StyleSheet.create({
	itemContainer: {
		flex: 1,
		flexDirection: 'row',
		marginVertical: 10,
	},
});
export default NotificationSettings;
