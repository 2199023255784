import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    maxWidth: 1160,
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#fff",
  },
  circlesContainer: {
    marginVertical: 10,
    paddingHorizontal: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
});

export default styles;
