import React, { useState } from 'react';
import { View, Alert, ScrollView, StyleSheet } from 'react-native';
import { Container, Header, Item, Input, Text, Button } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation } from 'aws-amplify';
import SearchList from '../components/SearchList';
import AppHeader from './AppHeader';
import containerStyles from './AccountSettingComponents/containerStyle';
import UserList from './UserList';
import { useStore } from '../store';
import * as mutations from '../../graphql/mutations';
import { Dialog, Portal } from 'react-native-paper';
// import { useRoute } from '@react-navigation/native';

const CirclesCreation = () => {
  const route = useRoute();
  const { from, screen } = route.params;
  const [searched, setSearched] = useState(false);
  const [searchText, setSearchText] = useState('');
  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const currentUser = useStore((state) => state.currentUser);
  const setCurrentConversationGroupId = useStore(
    (state) => state.setCurrentConversationGroupId
  );
  const setCurrentConversationGroup = useStore(
    (state) => state.setCurrentConversationGroup
  );
  const createGroupSelectedUsers = useStore(
    (state) => state.createGroupSelectedUsers
  );
  const setCreateGroupSelectedUsers = useStore(
    (state) => state.setCreateGroupSelectedUsers
  );
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const setScreen = useStore((state) => state.setScreen);
  // const setCurrentConversationGroup = useStore(
  // 	(state) => state.setCurrentConversationGroup
  // );
  const currentUserId = useStore((state) => state.currentUserId);
  const groupDetails = {
    // created_by: currentUser.data.getUser.id,
    created_by: currentUserId,
    group_title: createGroupSelectedUsers.length
      ? createGroupSelectedUsers[0].first_name
        ? createGroupSelectedUsers[0].first_name
        : currentUser.first_name
      : currentUser.first_name,
    last_message_id: 'zzz-123',
    pause_notifications: 0,
  };

  const createNewGroup = async () => {
    try {
      await API.graphql({
        query: mutations.createChatGroup,
        variables: { input: groupDetails },
      }).then((res) => {
        createNewGroupMember(
          res.data.createChatGroup.id,
          res.data.createChatGroup
        );
      });
    } catch (err) {
      console.log('err creating group...', err);
    }
  };

  const createNewGroupMember = async (groupId, group) => {
    let newGroup = {};
    for (const user of createGroupSelectedUsers) {
      try {
        await API.graphql({
          query: mutations.createChatGroupUser,
          variables: { input: { chat_group_id: groupId, user_id: user.id, accepted_invite: false } },
        }).then(async (res) => {
          let linkPropertiesAccepted = {
            chatGroupUser: res.data.id,
            chatGroupId: groupId,
            accepted: true,
          };
          let linkPropertiesDeclined = {
            chatGroupUser: res.data.id,
            chatGroupId: groupId,
            accepted: false,
          };
          let acceptedUrl =
            "https://howyoube.app.link/circleInvitation" +
            "/chatGroupUser=" +
            res?.data?.createChatGroupUser?.id +
            "/chatGroupId=" +
            groupId +
            "/accepted=true";
          let deniedUrl =
            "https://howyoube.app.link/circleInvitation" +
            "/chatGroupUser=" +
            res?.data?.createChatGroupUser?.id +
            "/chatGroupId=" +
            groupId +
            "/accepted=false";
          let data = {
            userid: user.id,
            chatgroupid: groupId,
            groupname: group?.group_title || "",
            chatgroupuserid: res?.data?.createChatGroupUser?.id || "",
            acceptedurl: encodeURIComponent(acceptedUrl),
            deniedurl: encodeURIComponent(deniedUrl),
          };
          try {
            await fetch(
              `https://j2ja8jk2e1.execute-api.us-east-1.amazonaws.com/dev/newchatgroupuser?userid=${user.id}&chatgroupid=${groupId}&groupname=${data.groupname}&chatgroupuserid=${res.data.id}&acceptedurl=${acceptedUrl}&deniedurl=${deniedUrl}/`,
              {
                method: "POST",
                body: JSON.stringify(data),
              }
            );
          } catch (error) {
            console.log("Could not call newchatgroupuser route", error);
          }
          // console.log('chat user Saved...', res);
        });
      } catch (err) {
        console.log(
          "circles creation: createGroupSelectedUsers: error creating chat user:",
          err
        );
      }
    }

    try {
      await API.graphql({
        query: mutations.createChatGroupUser,
        variables: {
          input: {
            chat_group_id: groupId,
            // user_id: currentUser.data.getUser.id,
            user_id: currentUserId,
            accepted_invite: false,
          },
        },
      }).then((res) => {
        // console.log('chat user Saved...', res);
      });
    } catch (err) {
      console.log(
        "circles creation: createNewGroupMember: error creating chat user:",
        err
      );
    }
    try {
      await API.graphql(
        // graphqlOperation(`query listChatGroupUsers {
	    	// 	listChatGroupUsers(filter: {chat_group_id: {eq: "${groupId}"}}) {
	      //   		items {
	      //       		chat_group {
	      //       			vip_user_id
	      //       			group_title
	      //       			id
	      //       			created_by
	      //       			chat_group_users {
	      //           			items {
	      //               			user {
	      //                   			id
	      //                   			first_name
	      //                   			last_name
	      //                   			imageUri
				// 						active_account
	      //                   			}
	      //               			}
	      //           			}
	      //       			}
	      //   			}
	    	// 		}
				// }`)
        graphqlOperation(`query getChatGroup {
          getChatGroup(id: "${groupId}") {
            vip_user_id
            group_title
            id
            created_by
            auto_message_time
            non_response_alert_time
            non_response_alert_timer
            pause_notifications
            vip_responded
            vip_response_time
            chat_group_users {
              items {
                user {
                  id
                  first_name
                  last_name
                  imageUri
                  active_account
                }
              }
            }
          }
        }`)
      ).then((res) => {
        setCurrentConversationGroup(
          res.data.getChatGroup
          // res.data.listChatGroupUsers.items[0].chat_group
        );
        setCurrentConversationGroupId(groupId)
        setModalVisible(false);
        setScreen(screen);
        setCreateGroupSelectedUsers([]);
        navigation.reset({
          index: 0,
          routes: [{ name: 'newConversation' }],
        });
      });
    } catch (error) {
      console.log('Circle Creation api error', error);
    }
  };
  const handleSearchText = () => {
    setSearchText('');
  };
  const handleOnPress = (e) => {
    e.preventDefault();
    setModalVisible(true);
    createNewGroup();
  };
  const getChatGroupQuery = `query listChatGroups {
	  listChatGroups(filter: {id: {eq: "${currentConversationGroup.id}"}}) {
    items {
      group_title
      id
      created_by
      vip_user_id
      chat_group_users {
        items {
          user {
            id
            first_name
            last_name
            imageUri
          }
        }
      }
    }
  }
    }`;
  const addNewGroupMember = async () => {
    for (const user of createGroupSelectedUsers) {
      try {
        await API.graphql({
          query: mutations.createChatGroupUser,
          variables: {
            input: {
              chat_group_id: currentConversationGroup.id,
              user_id: user.id,
              accepted_invite: false,
            },
          },
        }).then(async (res) => {
          let linkPropertiesAccepted = {
            chatGroupUser: res.data.id,
            chatGroupId: currentConversationGroup.id,
            accepted: true,
          };
          let linkPropertiesDeclined = {
            chatGroupUser: res.data.id,
            chatGroupId: currentConversationGroup.id,
            accepted: false,
          };
          let acceptedUrl =
            "https://howyoube.app.link/circleInvitation" +
            "/chatGroupUser=" +
            res?.data?.createChatGroupUser?.id +
            "/chatGroupId=" +
            currentConversationGroup.id +
            "/accepted=true";
          let deniedUrl =
            "https://howyoube.app.link/circleInvitation" +
            "/chatGroupUser=" +
            res?.data?.createChatGroupUser?.id +
            "/chatGroupId=" +
            currentConversationGroup.id +
            "/accepted=false";
          let data = {
            userid: user.id,
            chatgroupid: currentConversationGroup.id,
            groupname: currentConversationGroup?.group_title || "",
            chatgroupuserid: res?.data?.createChatGroupUser?.id || "",
            acceptedurl: encodeURIComponent(acceptedUrl),
            deniedurl: encodeURIComponent(deniedUrl),
          };
          console.log({ res });
          try {
            console.log("was here");

            await fetch(
              `https://j2ja8jk2e1.execute-api.us-east-1.amazonaws.com/dev/newchatgroupuser?userid=${user.id}&chatgroupid=${currentConversationGroup.id}&groupname=${currentConversationGroup.group_title}&chatgroupuserid=${res.data.id}&acceptedurl=${acceptedUrl}&deniedurl=${deniedUrl}/`,
              {
                method: "POST",
                body: JSON.stringify(data),
              }
            );
          } catch (error) {
            console.log("Could not call newchatgroupuser route", error);
          }
          console.log("chat user Saved...");
        });
      } catch (err) {
        console.log(
          "circles creation: addNewGroupMember: error creating chat user:",
          err
        );
      }
    }
    try {
      API.graphql(graphqlOperation(getChatGroupQuery)).then((res) => {
        setCurrentConversationGroup({
          ...res.data.listChatGroups.items[0],
        });
      });
    } catch (error) {
      console.log(
        'Error inside adding new members file while calling api',
        error
      );
    }
    navigation.reset({
      index: 0,
      routes: [{ name: 'newConversation' }],
    });
  };

  return (
    <Container>
      <AppHeader />
      <ScrollView
        style={{
          minWidth: '50vw',
          maxWidth: 1160,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          {from === 'GroupMembers' ? (
            <>
              <Button
                transparent
                light
                onPress={() => {
                  navigation.goBack();
                }}
              >
                <Text>Cancel</Text>
              </Button>
              <Button
                transparent
                dark
                style={{ backgroundColor: 'transparent' }}
                disabled={createGroupSelectedUsers.length ? false : true}
                onPress={addNewGroupMember}
              >
                <Text>Done</Text>
              </Button>
            </>
          ) : (
            <>
              <Button
                transparent
                light
                onPress={() => {
                  console.log({ screen });
                  if (screen === 'Circles') {
                    navigation.goBack();
                  } else if (screen === 'Home') {
                    navigation.navigate('HomeScreen');
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'circleScreen' }],
                    });
                  }
                }}
              >
                <Text>Cancel</Text>
              </Button>
              <Button
                transparent
                dark
                style={{ backgroundColor: 'transparent' }}
                disabled={createGroupSelectedUsers.length ? false : true}
                onPress={handleOnPress}
              >
                <Text>Done</Text>
              </Button>
            </>
          )}
        </View>
        <Header
          searchBar
          androidStatusBarColor={'#fff'}
          style={{ backgroundColor: '#fff', borderBottomWidth: 0 }}
        >
          <Item
            style={{
              borderRadius: 10,
              padding: 5,
              height: 40,
            }}
          >
            <MaterialCommunityIcons name="magnify" size={25} color="#8e8e93" />
            <Input
              autoCorrect={false}
              placeholder="Search/Invite"
              onChangeText={(text) => setSearchText(text)}
            />
          </Item>
        </Header>
        <ScrollView>
          <UserList
            searchText={searchText}
            handleSearchText={handleSearchText}
            from={from}
          />
        </ScrollView>
      </ScrollView>
      <Portal>
        <Dialog
          visible={modalVisible}
          style={{
            backgroundColor: '#fff',
            width: '25vw',
            height: '15vh',
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text>Creating Circle....</Text>
        </Dialog>
      </Portal>
    </Container>
  );
};

export default CirclesCreation;

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
  },
});
