import React, { useState, useEffect } from 'react';
import { ImageBackground, StyleSheet, View, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import ShareButton from '../components/ShareButton';
import moment from 'moment';
import useForceUpdate from 'use-force-update';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { useStore } from '../store/index';
import { getUser } from '../../graphql/queries';
const backgroundImage = {
	uri:
		'https://images.unsplash.com/photo-1533208705114-4f6b97d68ab1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjQzMzEwfQ&auto=format&fit=crop&w=1052&q=80',
};
const MessageOfTheDay = () => {
	const currentUser = useStore((state) => state.currentUser);
	let messageTypeOfUser = [];
	// const currentUserId = useStore((state) => state.currentUserId);
	const [todayMessage, setTodayMessage] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const forceUpdate = useForceUpdate();
	let currentUserId = '';
	// useEffect(() => {
	// 	if (currentUser.qotd === 1) {
	// 		messageTypeOfUser.push('Message_Of_The_Day');
	// 	}
	// 	if (currentUser.bible === 1) {
	// 		messageTypeOfUser.push('Bible_Verse_Of_The_Day');
	// 	}
	// 	if (currentUser.french === 1) {
	// 		messageTypeOfUser.push('French_Word_Of_The_Day');
	// 	}
	// 	if (currentUser.spanish === 1) {
	// 		messageTypeOfUser.push('Spanish_Word_Of_The_Day');
	// 	}
	// }, []);
	useEffect(() => {
		getUser();
	}, []);

	// useEffect(() => {
	// 	listDailyMessageFunction();
	// }, []);

	useEffect(() => {
		forceUpdate();
	}, [isLoading]);

	const currentTime = moment.utc();

	const listDailyMessagesQuerry = `query listDailyMessages {
	listDailyMessages(filter: {scheduled_date: {le: "${currentTime}"}}) {
    	items {
			  content
			  author
      		author_id
      		message_type
      		scheduled_date
      		user {
				first_name
				last_name
    			}
    		}
  		}
	}`;

	const getUserMessageTypes = `query getUserMessageTypes{
		getUser(id:"${currentUserId}"){
			qotd
			spanish
			french
			bible
		}
	}`;

	const getUser = async () => {
		try {
			Auth.currentAuthenticatedUser().then((res) => {
				console.log(res.attributes.sub);
				currentUserId = res.attributes.sub;
				getUserTypes(currentUserId);
			});
		} catch (error) {
			console.log('Error ', error);
		}
	};

	const getUserTypes = async (currentUserId) => {
		try {
			let res = await API.graphql(
				graphqlOperation(`query getUserMessageTypes{
		getUser(id:"${currentUserId}"){
			qotd
			spanish
			french
			bible
		}
	}`)
			);
			if (res.data.getUser.qotd === 1) {
				messageTypeOfUser.push('Message_Of_The_Day');
			}
			if (res.data.getUser.bible === 1) {
				messageTypeOfUser.push('Bible_Verse_Of_The_Day');
			}
			if (res.data.getUser.french === 1) {
				messageTypeOfUser.push('French_Word_Of_The_Day');
			}
			if (res.data.getUser.spanish === 1) {
				messageTypeOfUser.push('Spanish_Word_Of_The_Day');
			}
			listDailyMessageFunction();
		} catch (error) {
			console.log('Error could not get user types', error);
		}
	};

	const listDailyMessageFunction = async () => {
		try {
			let res = await API.graphql(graphqlOperation(listDailyMessagesQuerry));
			let messageList = await res.data.listDailyMessages.items.sort(
				(a, b) => b.scheduled_date - a.scheduled_date
			);
			let filteredMessages = messageList.filter(function (item) {
				return messageTypeOfUser.includes(item.message_type);
			});
			setTodayMessage(filteredMessages.slice(0, 1).shift());
			console.log({ todayMessage });
			setIsLoading(false);
		} catch (error) {
			console.log('error while getting daily messages,.....', error);
		}
	};
	return (
		<>
			{isLoading ? (
				<Text>Loading.......</Text>
			) : (
				<View>
					<ImageBackground source={backgroundImage} style={styles.bgImage}>
						<View style={styles.bgImageOverlay}>
							<View style={{ flexDirection: 'row' }}>
								<Ionicons
									name='md-sunny'
									color={'#FFF'}
									size={30}
									style={{ marginRight: 10 }}
								/>
								<Text style={styles.messageOfTheDayHeader}>
									{todayMessage?.message_type.replace(/[_]/g, ' ').replace('Of The','of the')}
								</Text>
							</View>
							<View style={{ padding: 30 }}>
								<Text style={styles.message}>{todayMessage?.content}</Text>
								{todayMessage?.author && (
									<View style={styles.sitationRow}>
										<View
											style={[styles.sitationDash, { marginRight: 5 }]}></View>
										<Text style={styles.sitationText}>
											{todayMessage?.author}
										</Text>
										<View
											style={[styles.sitationDash, { marginLeft: 5 }]}></View>
									</View>
								)}
							</View>
							<View style={{ alignItems: 'center' }}>
								<ShareButton color={'#fff'} content={todayMessage?.content} />
							</View>
						</View>
					</ImageBackground>
				</View>
			)}
		</>
	);
};

const styles = StyleSheet.create({
	bgImage: {
		opacity: 0.85,
		height: '100%',
	},
	bgImageOverlay: {
		flex: 1,
		justifyContent: 'space-between',
		padding: 15,
		backgroundColor: '#00000050',
	},
	messageOfTheDayHeader: {
		fontWeight: '700',
		fontSize: 20,
		color: '#FFF',
		justifyContent: 'center',
		alignSelf: 'center',
	},
	message: {
		color: '#fff',
		fontFamily: 'CRIMSON-TEXT',
		fontSize: 16,
	},
	sitationRow: {
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		marginVertical: 10,
	},
	sitationDash: {
		width: 50,
		height: 2,
		backgroundColor: '#d7d7d8',
		alignSelf: 'center',
	},
	sitationText: {
		fontFamily: 'CRIMSON-TEXT-BOLD',
		fontSize: 18,
		color: '#fff',
	},
});

export default MessageOfTheDay;
