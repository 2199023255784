import 'react-native-gesture-handler';
import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';
import * as Permissions from 'expo-permissions';
import React, { useEffect, useState, useRef } from 'react';
import { Platform, Text } from 'react-native';
import { Container, StyleProvider } from 'native-base';
import Amplify, { Auth, API, graphqlOperation, Hub } from 'aws-amplify';
import {
  Authenticator,
  withAuthenticator,
  withOAuth,
} from 'aws-amplify-react-native';

import getTheme from './native-base-theme/components';
import commonColor from './native-base-theme/variables/commonColor';
import * as Font from 'expo-font';

import { useStore } from './src/store';
import { getUser } from './graphql/queries';
import { createUser, updateUser } from './graphql/mutations';
import Routes from './src/Navigators/Routes';
import awsmobile from './aws-exports';
import BottomTabNavigator from './src/Navigators/BottomTabNavigator/BootmTabNavigator';
import {
  onCreateMessage,
  onCreateChatGroup,
  onCreateChatGroupUser,
} from './graphql/subscriptions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import LoginScreen from './src/Navigators/LoginStackNavigator/LoginStackNavigator';
import { Provider as PaperProvider } from 'react-native-paper';
import Login from './src/screens/LoginScreen/index';
import Signup from './src/screens/SignupScreen/index';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

Amplify.configure({
  ...awsmobile,
  Analytics: {
    disabled: true,
  },
});

function App(props) {
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  const {
    oAuthUser,
    oAuthError,
    hostedUISignIn,
    facebookSignIn,
    googleSignIn,
    signOut,
  } = props;
  const [user, setUser] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [authUsername, setAuthUsername] = useState();
  const currentUser = useStore((state) => state.currentUser);
  const currentUserId = useStore((state) => state.currentUserId);
  const setCurrentUser = useStore((state) => state.setCurrentUser);
  const setCurrentUserId = useStore((state) => state.setCurrentUserId);
  const isUserSignedIn = useStore((state) => state.isUserSignedIn);
  const setIsUserSignedIn = useStore((state) => state.isUserSignedIn);
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const expoToken = useStore((state) => state.expoToken);
  const setExpoToken = useStore((state) => state.setExpoToken);
  // let tok = '';
  let userId = null;
  useEffect(() => {
    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener(
      (notification) => {
        setNotification(notification);
      }
    );
    responseListener.current = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        console.log(response);
      }
    );
    return () => {
      Notifications.removeNotificationSubscription(notificationListener);
      Notifications.removeNotificationSubscription(responseListener);
    };
  }, []);

  useEffect(() => {
    loadFonts();
  }, []);

  // useEffect(() => {
  // 	userUpdate(oAuthUser);
  // }, [oAuthUser]);

  const listener = (data) => {
    if (data.payload.event === 'signIn') {
      userUpdate(oAuthUser);
    }
  };
  Hub.listen('auth', listener);

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateMessage)
    ).subscribe({
      next: (data) => {
        const newMessage = data.value.data;
        API.graphql(
          graphqlOperation(`query getChatGroup{
							getChatGroup(id:"${newMessage.onCreateMessage.chat_group.id}"){
								chat_group_users{
									items{
										user{
											id
											first_name
											}
										}
									}
								}
							}`)
        )
          .then((res) => {
            // console.log('reposne from promisse,,', res);
            let userIds = [];
            res.data.getChatGroup.chat_group_users.items.map((item) => {
              userIds.push(item.user.id);
            });
            console.log(newMessage.onCreateMessage.user.id);
            if (
              userIds.includes(userId) &&
              userId !== newMessage.onCreateMessage.user.id
            ) {
              console.log('Hello there');
              sendPushNotification(newMessage);
            }
            console.log({ userIds });
          })
          .catch((error) => {
            console.log('Error with promise', error);
          });
      },
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(onCreateChatGroupUser)
    ).subscribe({
      next: (data) => {
        const newChatGroup = data.value.data;
        if (
          newChatGroup.onCreateChatGroupUser.user_id === userId &&
          newChatGroup.onCreateChatGroupUser.user_id !==
            newChatGroup.onCreateChatGroupUser.chat_group.created_by
        ) {
          // console.log('Enterted filter....');
          sendPushNotification(newChatGroup);
        }
      },
    });
    return () => subscription.unsubscribe();
  }, []);

  const checkUser = async () => {
    let user = await Auth.currentAuthenticatedUser();
    console.log('username...', JSON.stringify(user));
  };

  const loadFonts = async () => {
    await Font.loadAsync({
      'CRIMSON-TEXT': require('./assets/fonts/CrimsonText-Regular.ttf'),
      'CRIMSON-TEXT-BOLD': require('./assets/fonts/CrimsonText-Bold.ttf'),
      'OPEN-SANS': require('./assets/fonts/OpenSans-Regular.ttf'),
      'OPEN-SANS-SEMIBOLD': require('./assets/fonts/OpenSans-SemiBold.ttf'),
      'OPEN-SANS-BOLD': require('./assets/fonts/OpenSans-Bold.ttf'),
      Roboto: require('native-base/Fonts/Roboto.ttf'),
      Roboto_medium: require('native-base/Fonts/Roboto_medium.ttf'),
    });
    setIsReady(true);
  };

  const userUpdate = async (oAuthUser) => {
    console.log('O auth user updated!!!!');

    const userInfo = oAuthUser
      ? oAuthUser
      : await Auth.currentAuthenticatedUser();
    // console.log("userinfo...", userInfo.attributes);
    // console.log(userInfo);
    if (userInfo) {
      // console.log('userinfo...', userInfo);
      setUserLoggedIn(true);
      const userData = await API.graphql(
        graphqlOperation(getUser, {
          id: userInfo.attributes.sub,
        })
      );
      if (!userData.data.getUser) {
        const user = {
          id: userInfo.attributes.sub,
          first_name: userInfo.attributes['custom:first_name']
            ? userInfo.attributes['custom:first_name']
            : 'First',
          last_name: userInfo.attributes['custom:last_name']
            ? userInfo.attributes['custom:last_name']
            : 'Last',
          email: userInfo.attributes.email,
          imageUri: userInfo.attributes.picture,
          phone_number: userInfo.attributes.phone_number,
          qotd: 1,
          bible: 1,
          french: 1,
          spanish: 1,
          active_account: 1,
          pause_notifications: 0,
          user_search: `${userInfo.attributes[
            'custom:first_name'
          ].toLowerCase()}_${userInfo.attributes[
            'custom:last_name'
          ].toLowerCase()}_${userInfo.attributes.email.toLowerCase()}`,
        };
        await addUserToDB(user).then(
          (res) => console.log('added to db...', res),
          setCurrentUser(user),
          setCurrentUserId(userInfo.attributes.sub)
        );
        await AsyncStorage.setItem('@LoggedIn', 'signedin').catch((error) => {
          console.log('Eroor....', error);
        });
        setUserLoggedIn(true);
      } else {
        const user = {
          id: userData.data.getUser.id,
          first_name: userData.data.getUser.first_name,
          last_name: userData.data.getUser.last_name,
          phone_number: userData.data.getUser.phone_number,
          email: userData.data.getUser.email,
          qotd: userData.data.getUser.qotd,
          bible: userData.data.getUser.bible,
          french: userData.data.getUser.french,
          spanish: userData.data.getUser.spanish,
          active_account: !userData.data.getUser.active_account
            ? 1
            : userData.data.getUser.active_account,
          pause_notifications: userData.data.getUser.pause_notifications,
          user_search: userData.data.getUser.user_search,
        };
        setCurrentUser(user);
        // setCurrentUser(userData);
        userId = userData.data.getUser.id;
        setCurrentUserId(userData.data.getUser.id);
        setUserLoggedIn(true);
        console.log('Store values', currentUser, currentUserId);
        await AsyncStorage.setItem('@LoggedIn', 'signedin').catch((error) => {
          console.log('Eroor....', error);
        });
      }
    } else {
      setUserLoggedIn(false);
    }
  };

  const addUserToDB = async (user) => {
    await API.graphql(graphqlOperation(createUser, { input: user }));
  };

  const sendPushNotification = async (object) => {
    console.log('Staring push notifi function........');
    // console.log('argument inside sendpushnotication,...... ', object);
    let title = '';
    let body = '';
    if (object.hasOwnProperty('onCreateChatGroupUser')) {
      title = `You have been added to a new group`;
      body = `Group Name: ${object.onCreateChatGroupUser.chat_group.group_title}`;
    } else if (object.hasOwnProperty('onCreateMessage')) {
      title = object.onCreateMessage.chat_group.group_title;
      body = object.onCreateMessage.content;
    }
    await Notifications.scheduleNotificationAsync({
      content: {
        title: title,
        body: body,
        data: { data: 'goes here' },
      },
      trigger: { seconds: 2 },
    });
  };

  return (
    <PaperProvider>
      <StyleProvider style={getTheme(commonColor)}>
        <Container>
          <Routes />
          {/* <BottomTabNavigator /> */}
          {/* <Login /> */}
        </Container>
      </StyleProvider>
    </PaperProvider>
  );
  // return (
  // 	<StyleProvider style={getTheme(commonColor)}>
  // 		<Container>
  // 			<Login />
  // 		</Container>
  // 	</StyleProvider>
  // );
}

const signUpConfig = {
  header: 'My Customized Sign Up',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'First Name',
      key: 'custom:first_name',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Last Name',
      key: 'custom:last_name',
      required: true,
      displayOrder: 2,
      type: 'string',
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'string',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 4,
      type: 'password',
    },
  ],
};

// export default withAuthenticator(App, { hideDefault: true });
// export default withAuthenticator(App, {
// 	signUpConfig,
// 	usernameAttributes: 'email',
// });
// export default withAuthenticator(App, false, [<Login />]);
// export default App;
// export default withOAuth(App, false, [<Login />]);
// export default withOAuth(App);
export default App;
