import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import cannedMessages from '../../Resources/CannedMessagesArray';
import { API } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import { useStore } from '../../store/index';

const TemplateMessages = () => {
  const currentUserId = useStore((state) => state.currentUserId);
  const currentConversationGroup = useStore(
    (state) => state.currentConversationGroup
  );
  const setVipTempleteMessageInput = useStore(
    (state) => state.setVipTempleteMessageInput
  );

  const handleOnpressTemplateMessage = async (message) => {
    try {
      let res = await API.graphql({
        query: mutations.createMessage,
        variables: {
          input: {
            chat_group_id: currentConversationGroup.id,
            content: message,
            user_id: currentUserId,
          },
        },
      });
      let res2 = await API.graphql({
        query: mutations.updateChatGroup,
        variables: {
          input: {
            id: currentConversationGroup.id,
            last_message_id: res.data.createMessage.id,
          },
        },
      });
      // console.log('after writing into db.....', res);
      // console.log('after updating the chat group last message', res2);
      // console.log('created date:', res.data.createMessage.createdAt);
      // const messageCreated = res.data.createMessage.createdAt;
    } catch (error) {
      console.log('Error while writing data to db....', error);
    }
  };
  return (
    <View style={styles.messageView}>
      {currentConversationGroup.vip_user_id === currentUserId
        ? cannedMessages.VipMessageArray.map((message, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => setVipTempleteMessageInput(message)}
              >
                <View style={styles.textView}>
                  <Text style={styles.text}>
                    {message.length < 15
                      ? `${message}`
                      : `${message.substring(0, 15)}...`}
                  </Text>
                </View>
              </TouchableOpacity>
            );
          })
        : cannedMessages.MessageArray.map((message, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => handleOnpressTemplateMessage(message)}
              >
                <View style={styles.textView}>
                  <Text style={styles.text}>{message}</Text>
                </View>
              </TouchableOpacity>
            );
          })}
    </View>
  );
};

const styles = StyleSheet.create({
  messageView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    borderTopWidth: 1,
    borderTopColor: '#EBEBEB',
    marginTop: 5,
  },

  textView: {
    backgroundColor: '#EBEBEB',
    marginVertical: 10,
    padding: 5,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: '#EBEBEB',
    marginHorizontal: 5,
  },
  text: {
    fontFamily: 'CRIMSON-TEXT',
    fontSize: 18,
  },
});

export default TemplateMessages;
